import TableGrid from './TableGrid.js';
import Loading from './Loading.js';
import AdminProductTagEditModal from './AdminProductTagEditModal.js';

export default {
  name: `AdminProductTags`,
  components: {
    Loading,
    TableGrid,
    AdminProductTagEditModal
  },
  data () {
    const gridColumns = [
      {key: '', value: ''},
      {key: 'tag', value: 'Tag'},
      {key: '', value: ''},
      {key: '', value: ''},
    ];

    return {
      loading: false,
      gridColumns,
      gridData: [],
      productTagToEdit: null,
    }
  },
  computed: {
  },
  watch: {
    itemsToShow () {
      this.pivotData();
    }
  },
  methods: {
    handleNew () {
      this.productTagToEdit = {
        id: 'new'
      };
    },
    handleEdit (item) {
      this.productTagToEdit = item;
    },
    handleEditSuccess () {
      this.productTagToEdit = null;
      this.fetchProductTags();
    },
    fetchProductTags () {
      this.loading = true;
      this.$http.get('/product_items/tags/')
      .then((response) => {
        if (!response.data.error) {
          this.pivotData(response.data.items);
        }
        else {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    pivotData (items) {
      const gridData = [];

      items.forEach(item => {
          gridData.push(item);
      });

      this.gridData = gridData;
    }
  },
  filters: {
  },
  mounted () {
    this.fetchProductTags();
  },
  template: `
    <div class="admin-product-tags-component component-fixed">
      <div class="component-header">
        <ul class="nav">
          <li>
            <router-link :to='{name: "home"}' class="item">Home</router-link>
          </li>
          <li>
            <span class="item">Product Tags</span>
          </li>
        </ul>
        <ul class="component-options">
          <li><router-link :to='{name: "shop"}'>Shop</router-link></li>
          <li><router-link :to='{name: "purchaseOrders"}'>Purchase Orders</router-link></li>
          <li><router-link :to='{name: "vendors"}'>Vendors</router-link></li>
          <li><a href="#" v-on:click.prevent="handleNew">Add Tag</a></li>
        </ul>
      </div>
      <loading v-bind="{loading: loading}"></loading>
      <admin-product-tag-edit-modal v-bind="{item: productTagToEdit, onUpdate: handleEditSuccess}" v-if="productTagToEdit"></admin-product-tag-edit-modal>
      <table-grid
        class="table-block"
        :data="gridData"
        defaultSortOrder="1"
        :columns="gridColumns"
        v-if="gridData.length">
          <template slot="rows" slot-scope="row">
          <td></td>
            <td>
              <router-link :to='{name: "shopProductsByTags", params: {tags: row.tag}}'>{{row.tag}}</router-link>
            </td>
            <td>
              <ul class="options-buttons">
                <li><a href="#" v-on:click.prevent="handleEdit(row)" class="link">Edit Tag</a></li>
              </ul> 
            </td>
            <td></td>
          </template>
      </table-grid>
    </div>
  `,
};