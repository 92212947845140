import Loading from './Loading.js';
import FormInput from './form/FormInput.js';

export default {
  name: 'AdminMyAccount',
  components: {
    Loading,
    FormInput
  },
  data() {
    return {
      success: null,
      loading: false,
      isStrongPassword: false,
      updatedAccount: {},
      errorMsg: null,
      successMsg: null
    }
  },
  computed: {
    user () {
      return this.$store.state.user;
    }
  },
  watch: {

  },
  methods: {
    handleAddSection () {
      this.sectionToEdit = {
        id: 'new'
      };
    },
    handleInputChange (value, name) {
      console.log(value, name)
      this.updatedAccount[name] = value;

    },
    handlePasswordUpdate() {
      if (!this.updatedAccount.password) {
        this.errorMsg = 'Enter Password!';
      }
      else if (this.updatedAccount.password.length < 8) { 
        this.errorMsg = 'Password must be at least 8 characters!';
      }
      else if (this.updatedAccount.password !== this.updatedAccount.password_confirm) {
        this.errorMsg = 'Passwords do not match!';
      }
      else {
        this.errorMsg = null;
        this.postAccount();
      }
    },
    handleSendEmailVerification() {
      this.postEmailVerification();
    },
    postEmailVerification () {
      this.loading = true;
      this.$http.post('/auth/send-email-verification', {email: this.user.email})
        .then((response) => {
          if (response.data.success) {
            this.successMsg = 'Email Sent!';
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    postAccount () {
      this.loading = true;
      this.$http.post('/auth/update', this.updatedAccount)
        .then((response) => {
          if (response.data.success) {
            this.successMsg = 'Updated Account!';
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {
  },
  filters: {
    
  },
  template: `
    <div class="admin-my-account-component">
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li><router-link :to="{name: 'hotel'}" class="item">My Account</router-link></li>
        </ul>
      </div>
      <section class="component-body">
        <loading v-bind="{loading: loading}"></loading>
        <form v-on:submit.prevent="handlePasswordUpdate" class="grid-3">
          <div>
          <form-input v-bind="{name: 'email', label: 'Email', disabled: true}" :value="user.email">
            <span class="warning" v-if="!user.email_verified">Email is not verified!</span>
          </form-input>
          <form-input v-bind="{name: 'password', label: 'New Password', type: 'password'}" :onChange="handleInputChange"></form-input>
          <form-input v-bind="{name: 'password_confirm', label: 'Confirm Password', type: 'password'}" :onChange="handleInputChange"></form-input>
          <div class="error" v-if="errorMsg">{{errorMsg}}</div>
          <div class="success success-block" v-if="successMsg">{{successMsg}}</div>
          
          <div class="buttons padding-top">
            <button type="submit" class="button">Update</button>
            <a href="#" v-on:click.prevent="handleSendEmailVerification" v-if="!user.email_verified" class="button">Resend Verification Email</a>
          </div>
          </div>
        </form>
      </section>
    </div>
  `
};