import axios from 'axios';

export default {
  install (Vue, options) {
    const defaultOptions = {
      // request interceptor handler
      reqHandleFunc: config => config,
      reqErrorFunc: error => Promise.reject(error),
      // response interceptor handler
      resHandleFunc: response => response,
      resErrorFunc: error => Promise.reject(error)
    };
  
    const initOptions = {
      ...defaultOptions,
      ...options
    };
  
    const service = axios.create(initOptions);
  
    // Add a request interceptor
    service.interceptors.request.use(
      config => initOptions.reqHandleFunc(config),
      error => initOptions.reqErrorFunc(error)
    );
    // Add a response interceptor
    service.interceptors.response.use(
      response => initOptions.resHandleFunc(response),
      error => initOptions.resErrorFunc(error)
    );
  
    Vue.prototype.$axios = service;
    Vue.prototype.$http = {
      get: (url, options) => {
        console.log(url);
        let axiosOpt = {
          ...options,
          ...{
            method: 'get',
            url: url
          }
        };
        return service(axiosOpt);
      },
      post: (url, data, options) => {
        let axiosOpt = {
          ...options,
          ...{
            method: 'post',
            url: url,
            data: data
          }
        };
        return service(axiosOpt);
      }
    };
  }
};
