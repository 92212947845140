
// app-style.js
// adding <style></style> directly inside template will result in error or empty element which
// this component is trying to address
// this will allow you to dynamically insert css inside Vue template without the use of vue loader
// or if you do not want to use .vue extension at all
// however it doesnt really apply scope css (for now).
export default {
  name: 'AppStyle',
  data () {
    return {
      style: ''
    };
  },
  created: function () {
    this.$slots.default.forEach(val => {
      this.style += val.text
    });
  },
  mounted: function() {
    // create <style/>
    const styl = document.createElement('style')
    const txtNode = document.createTextNode(this.style)
    // replace current node
    styl.append(txtNode)
    this.$el.replaceWith(styl)
  },
  template:'<span><!-- please see styling in head section--></span>'
}