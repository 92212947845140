import DateTime from './DateTime.js';
import Loading from './Loading.js';
import AdminEditPunchModal from './AdminEditPunchModal.js';

export default {
  name: 'AdminDailyPunches',
  components: {
    Loading,
    AdminEditPunchModal,
    Info: {
      template: `
        <div class="info">
          <span class="title">
            <slot name="title"></slot>
          </span>
          <span class="value"><slot></slot></span>
        </div>
      `
    }
  },
  data() {
    return {
      loading: false,
      punchToEdit: null,
      punches: [],
      attendances: [],
      payPeriods: null,
      errorMsg: null
    };
  },
  computed: {
    date () {
      let date = this.$route.params.date;
      if (date === 'today') {
        date = +DateTime.today;
      }
      else if (date === 'yesterday') {
        date = +DateTime.yesterday;
      }

      return DateTime.inputDate(date);
    },
    punchesByPosition () {
      const punchesByPosition = {};
      this.punches.forEach((punch) => {
        const inDate = new Date(punch.punch_in);
        const outDate = new Date(punch.punch_out);

        let position = this.pivotPosition(punch.position);
        if (!punchesByPosition[position]) {
          punchesByPosition[position] = [];
        }

        punchesByPosition[position].push({
          first_name: punch.first_name,
          last_name: punch.last_name,
          userId: punch.user_id,
          punchId: punch.id,
          date: DateTime.numericDate(inDate),
          day: DateTime.humanShortWeekDay(inDate),
          inTime: DateTime.humanTime(inDate),
          outTime: punch.punch_out ? DateTime.humanTime(outDate) : null,
          actualHours: punch.actual_time_minutes > 0 ? DateTime.minutesToHours(punch.actual_time_minutes) : '',
          roundedHours: punch.rounded_time_minutes > 0 ? DateTime.minutesToHours(punch.rounded_time_minutes) : '',
          nextDay: punch.punch_out ? inDate.getDate() != outDate.getDate() : false,
          prevDay: punch.punch_in ? inDate.getDate() != outDate.getDate() : false,
          punch: punch
        });
      });

      this.attendances.forEach(attendance => {
        
        let position = this.pivotPosition(attendance.position);
        if (!punchesByPosition[position]) {
          punchesByPosition[position] = [];
        }
        
        punchesByPosition[position].push({
          first_name: attendance.first_name,
          last_name: attendance.last_name,
          userId: attendance.user_id,
          date: DateTime.numericDate(attendance.date),
          day: DateTime.humanShortWeekDay(attendance.date),
          attendanceType: attendance.attendance_type
        });
      });

      return punchesByPosition;
    }
  },
  watch: {
    date () {
      this.reload();
    }
  },
  methods: {
    pivotPosition (position) {
    
      if (position === 'Front Desk Associate' || position === 'Night Auditor') {
        position = 'Front Desk';
      }

      return position;
    },
    handleDateChange(event) {
      this.$router.replace({
        name: 'dailyPunches',
        params: {
          date: event.target.value
        }
      });
    },
    handleEditPunch(punch) {
      console.log(punch);
      this.punchToEdit = {
        name: punch.first_name + ' ' + punch.last_name,
        punch_id: punch.punch.punch_id,
        punch_in: punch.punch.punch_in,
        punch_out: punch.punch.punch_out,
        user_id: punch.punch.user_id
      };
    },
    handleApprovePunch(punch) {
      this.postApprovePunch(punch.punch.punch_id);
    },
    postApprovePunch(punchId) {
      this.loading = true;

      this.$http.post('/punches/approve', {
        punch_id: punchId
      })
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.error;
          } else {
            this.$toast.info('Punch approved.');
            this.reload();
          }
        })
        .catch((error) => {
          this.errorMsg = error.response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePunchUpdate() {
      this.reload();
      this.punchToEdit = null;
    },
    getPunchesByDate() {
      this.loading = true;

      this.$http.get('/punches/date/' + this.date)
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.error;
          } else {
            this.punches = response.data.punches;
            this.payPeriods = response.data.pay_periods;
          } 
        })
        .catch((error) => {
          this.errorMsg = error.response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    reload() {
      this.getPunchesByDate();
      this.fetchAttendances();
    },
    fetchAttendances() {
      this.loading = true;

      this.$http.get('/user_attendance/?date=' + this.date)
        .then((response) => {
          if (response.data.items) {
            this.attendances = response.data.items;
          }
          else if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.response;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.reload();
  },
  filters: {
    ...DateTime
  },
  template: `
    <div class="admin-daily-punches-component">
      <div class="component-header">
        <ul class="nav">
          <li>
            <router-link :to='{name: "home"}' class="item">Home</router-link>
          </li>
          <li>
            <span class="item-input">Punches for <input type="date" :value="date" v-on:change="handleDateChange"></span>
          </li>
        </ul>
      </div>
      <section class="component-sub-header" v-if="payPeriods">
        <info>
          <template slot="title">Start Date</template>
          {{payPeriods.payroll_start_date | numericDate}}
        </info>
        <info>
          <template slot="title">End Date</template>
          {{payPeriods.payroll_end_date | numericDate}}
        </info>
        <info>
          <template slot="title">Check Date</template>
          {{payPeriods.payroll_check_date | numericDate}}
        </info>
      </section>
      <section class="">
        <loading v-bind="{loading: loading}"></loading>
        <div class="error error-block" v-if="errorMsg">{{errorMsg}}</div>
        <admin-edit-punch-modal v-bind="{punch: punchToEdit, onComplete: handlePunchUpdate}" v-if="punchToEdit"></admin-edit-punch-modal>
        <div>
          <table class="table table-block">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>Name</th>
                <th>Date</th>
                <th>Punch In</th>
                <th>Punch Out</th>
                <th>Actual Hours</th>
                <th>Rounded Hours</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-for="(punches, position) in punchesByPosition">
              <tr><th colspan="9" class="sub-caption">{{position}}</th></tr>
              <tr v-for="punch in punches">
                <td><a href="#" v-on:click.prevent="handleEditPunch(punch)" class="button button-table button-medium" v-if="!punch.attendanceType && punch.punch.is_approved != 1">Edit</a></td>
                <td>
                  <span v-if="!punch.attendanceType">
                    <span v-if="punch.punch.is_approved == 1">&checkmark;</span>
                    <a href="#" v-on:click.prevent="handleApprovePunch(punch)" class="button button-table button-medium" v-else>Approve</a>
                  </span>
                </td>
                <td>
                  <router-link :to="{name: 'employees', params: {employeeId: punch.userId, action: 'punches'}}">
                    {{punch.first_name}} {{punch.last_name}}
                  </router-link>
                </td>
                <td>{{punch.date}} ({{punch.day}})</td>
                <td>
                  <mark v-if="punch.attendanceType">{{punch.attendanceType}}</mark>
                  {{punch.inTime}}
                </td>
                <td>{{punch.outTime}} <span class="next-day" v-if="punch.nextDay">(Next Day)</span><mark class="still-here" v-if="punch.inTime && !punch.outTime">Working</mark></td>
                <td>{{punch.actualHours}}</td>
                <td :class="{highlight: punch.punch.rounded_time_minutes > 480}" v-if="punch.punch">{{punch.roundedHours}}</td>
                <td v-else></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  `
};