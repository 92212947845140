import Modal from './Modal.js';
import Loading from './Loading.js';
import FormInput from './form/FormInput.js';
import FormSelect from './form/FormSelect.js';

export default {
  name: 'AdminShopProductEditModal',
  components: {
    FormInput,
    FormSelect,
    Modal,
    Loading
  },
  props: ['item', 'onUpdate'],
  data () {
    return {
      loading: false,
      errorMsg: null,
      success: null,
      updatedItem: {},
      vendors: null
    }
  },
  computed: {
    vendorOptions () {
      const roomTypes = {};
      this.vendors.forEach(vendor => {
          roomTypes[vendor.id] = vendor.name;
      });

      return roomTypes;
    }
  },
  methods: {
    handleChangeVendor (value) {
      this.updatedItem.vendor_id = value.trim();
    },
    handleChangeBrand (value) {
      this.updatedItem.brand = value.trim();
    },
    handleChangeDescription (value) {
      this.updatedItem.description = value.trim();
    },
    handleChangeProductId (value) {
      this.updatedItem.vendor_product_id = value.trim();
    },
    handleChangePrice (value) {
      this.updatedItem.price = value.trim();
    },
    handleChangeQty (value) {
      this.updatedItem.qty = value.trim();
    },
    handleCancel() {
      this.onUpdate('Cancelled!');
    },
    handleItemDelete() {
      this.updatedItem.active = 0;
      this.handleItemUpdate();
    },
    handleItemUpdate () {
      if (Object.keys(this.updatedItem).length) {
        if (this.item.id && this.item.id !== 'new') {
          this.updatedItem.id = this.item.id;
        }
        else if (this.item.id === 'new') {
          this.updatedItem.product_item_id = this.item.product_item_id;
        }
        this.saveItem();
      }
      else {
        this.onUpdate('No Change!');
      }
    },
    saveItem () {
      this.loading = true;
      this.errorMsg = null;
      this.$http.post('/vendor_items/', this.updatedItem)
        .then((response) => {
          if (response.data.item) {
            this.errorMsg = null;
            this.onUpdate('Updated');
            this.$toast.info((this.updatedItem.id ? 'Updated' : 'Created') + ': ' + this.item.product);
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchVendors () {
      this.loading = true;
      this.$http.get('/vendors/')
      .then((response) => {
        if (!response.data.error) {
          this.vendors = response.data.items;
        }
        else {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
  },
  mounted () {
    this.fetchVendors();
  },
  template: ` 
    <modal>
      <div class="component-header">
        <h2 class="component-header-title">
        {{item.id == 'new' ? 'Add' : 'Edit'}} Item
        </h2>
      </div>
      <div class="component-body">
        <form class="form" v-on:submit.prevent="handleItemUpdate">
          <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
          <h3>{{item.product}}</h3>
          <form-select label="Vendor" :value="item.vendor_id" :onChange="handleChangeVendor" :options="vendorOptions" v-if="vendors"></form-select>
          <form-input label="Brand" :value="item.brand" :onChange="handleChangeBrand" placeholder="Brand"></form-input>
          <form-input label="Description" :value="item.description" :onChange="handleChangeDescription" placeholder="Description"></form-input>
          <form-input label="Product ID" :value="item.vendor_product_id" :onChange="handleChangeProductId" placeholder="Product Id"></form-input>
          <form-input label="Pkg Qty" :value="item.qty" :onChange="handleChangeQty" placeholder="Pkg Qty"></form-input>
          <form-input label="Listd Price ($)" :value="item.price" :onChange="handleChangePrice" placeholder="Price"></form-input>
          <div class="error" v-if="errorMsg">{{errorMsg}}</div>
          <button class="button">
            {{item.id == 'new' ? 'Add' : 'Update'}}
          </button>
          <a href="#" v-on:click.prevent="handleCancel">Cancel</a>
        </form>
      </div>
    </modal>
  `
};