import Modal from './Modal.js';
import Loading from './Loading.js';
import FormInput from './form/FormInput.js';
import FormSelect from './form/FormSelect.js';

export default {
  name: 'AdminHotelRoomTypeEditModal',
  components: {
    FormInput,
    FormSelect,
    Modal,
    Loading
  },
  props: ['roomType', 'onUpdate'],
  data () {
    return {
      loading: false,
      errorMsg: null,
      success: null,
      usageOptions: {'guest':'Guest Room', 'other': 'Non-Guest Room'},
      updatedRoomType: {}
    };
  },
  methods: {
    handleInputUpdate(value, key) {
      this.updatedRoomType[key] = value.trim();
    },
    handleCancel() {
      this.onUpdate('Cancelled!');
    },
    handleRoomTypeUpdate () {
      if (Object.keys(this.updatedRoomType).length) {
        if (this.roomType.id && this.roomType.id !== 'new') {
          this.updatedRoomType.id = this.roomType.id;
        } else {        
          this.updatedRoomType.usage = this.roomType.usage;
        }
      
        this.saveRoomType();
      }
      else {
        this.onUpdate('No Change!');
      }
    },
    saveRoomType () {
      this.loading = true;
      this.errorMsg = null;

      this.$http.post('/v0/hotel/room_types', this.updatedRoomType)
        .then((response) => {
          if (response.data.room_type) {
            this.errorMsg = null;
            this.onUpdate('Updated');
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {
    console.log(this.roomType)
  },
  template: ` 
    <modal :onClose="handleCancel">
      <div class="component-header">
        <h2 class="component-header-title">
        {{roomType.id == 'new' ? 'Add' : 'Edit'}} Room Type
        </h2>
      </div>
      <div class="component-body">
        <form class="form" v-on:submit.prevent="handleRoomTypeUpdate">
          <loading v-bind="{loading: loading, position: 'fixed'}"></loading>

          <form-input label="Type" :value="roomType.type" name="type" :onChange="handleInputUpdate" focus="true"></form-input>
          <form-input label="Description" :value="roomType.description" name="description" :onChange="handleInputUpdate"></form-input>
          <form-select label="Room Usage" :value="roomType.usage" name="usage" :onChange="handleInputUpdate" :options="usageOptions"></form-select>
          <div class="error error-block" v-if="errorMsg">{{errorMsg}}</div>

          <div class="buttons">
            <button class="button">
              {{roomType.id == 'new' ? 'Add' : 'Update'}}
            </button>
            <a href="#" v-on:click.prevent="handleCancel">Cancel</a>
          </div>
        </form>
      </div>
    </modal>
  `
};