
export default {
  name: `AdminCreatePayrollReport`,
  data: function() {
    return {
      overTimeMultiplier: 1.5,
      vacationMultiplier: 1,
      holidayMultiplier: 1.5,
      maxRegularHours: 80
    }
  },
  template: `
    <div class="admin-payrol-calculation-info-component" style="padding: 15px">
      <details>
        <summary><strong>How wages are calculated?</strong></summary>
        <ul>
            <li>Regular Wages = regular hours * hourly rate</li>
            <li>Overtime Wages = overtime hours * hourly rate * overtime multiplier ({{this.overTimeMultiplier}})</li>
            <li>Holiday Wages = holiday hours * hourly rate * holiday multiplier ({{this.holidayMultiplier}})</li>
            <li>Vacation Wages = vacation hours * hourly rate * vacation multiplier ({{this.vacationMultiplier}})</li>
            <li>Total Wages = Regular Wages + Overtime Wages + Holiday Wages + Vacation Wages + Bonus</li>
        </ul>
      </details>
    </div>
  `,
};