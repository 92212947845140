export default {
  name: `TableGrid`,
  props: {
    data: Array,
    columns: Array,
    filterKey: {
      type: String,
      default: ''
    },
    defaultSortColumn: {
      type: String,
      default: ''
    },
    defaultSortOrder: {
      type: String,
      default: "1"
    },
    sortable: {
      type: String,
      default: "true"
    }
  },
  data () {
    const gridColumns = [];
    const sortOrders = {};
    const sortOrdersType = {};

    this.columns.forEach((column) => {
      if (typeof column == 'string') {
        column = {
          key: column,
          value: column
        };
      }

      sortOrders[column.key] = 1;
      sortOrdersType[column.key] = column.type || String;
      gridColumns.push(column);
    });

    if(this.defaultSortColumn != '' && sortOrders[this.defaultSortColumn]) {
      sortOrders[this.defaultSortColumn] = this.defaultSortOrder;
    }

    return {
      sortKey: this.defaultSortColumn,
      sortOrders,
      gridColumns,
      sortOrdersType
    };
  },
  computed: {
    filteredData () {
      const sortKey = this.sortKey;
      const filterKey = this.filterKey && this.filterKey.toLowerCase();
      const order = this.sortOrders[sortKey] || 1;
      let data = this.data;

      if (filterKey) {
        data = data.filter((row) => {
          return Object.keys(row).some((key) => {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }

      if (sortKey) {
        const isSortOrderTypeNumber = this.sortOrdersType[sortKey] === Number;

        data = data.slice().sort((a, b) => {
          a = isSortOrderTypeNumber ? a[sortKey] * 1 : a[sortKey];
          b = isSortOrderTypeNumber ? b[sortKey] * 1 : b[sortKey];
          return (a === b ? 0 : a > b ? 1 : -1) * order;
        });
      }
      return data;
    }
  },
  methods: {
    sortBy (key) {
      if (this.sortable == 'true') {
        this.sortKey = key;
        this.sortOrders[key] = this.sortOrders[key] * -1;
      }
    }
  },
  template: `
    <table class="table">
      <thead>
        <slot name="top-thead"></slot>
        <tr>
          <th 
            v-for="column in gridColumns" 
            @click="sortBy(column.key)"
            :class="{ [column.className]: !!column.className, 'sort-active': sortKey == column.key, 'sort-asc': sortKey == column.key && sortOrders[column.key] > 0  }">
            {{ column.value }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="row in filteredData">
          <tr :class="[row.classNames]">
            <slot name="rows" v-bind="{...row}">
              <td v-for="column in gridColumns" :class="{[row.classNames]: !!row.classNames}">
                {{row[column.key]}}
              </td>
            </slot>
          </tr>
        </template>
      </tbody>
      <slot name="tfoot"></slot>
    </table>
  `,
};