const convertArrayOfObjectsToCSV = function(args) {  
  let result, ctr, keys, columnDelimiter, lineDelimiter, data;

  data = args.data || null;
  if (data == null || !data.length) {
      return null;
  }

  columnDelimiter = args.columnDelimiter || ',';
  lineDelimiter = args.lineDelimiter || '\n';

  keys = Object.keys(data[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(function(item) {
      ctr = 0;
      keys.forEach(function(key) {
          if (ctr > 0) result += columnDelimiter;

          result += item[key] || '';
          ctr++;
      });
      result += lineDelimiter;
  });

  return result;
};

const downloadCSV = function(jsonData, fileName) {
  fileName = fileName || 'export.csv';

  const csv = convertArrayOfObjectsToCSV({
    data: jsonData
  });

  if (csv == null) return;

  const csvFile = new Blob([csv], {type: "text/csv"});

  // Download link
  const downloadLink = document.createElement("a");

  // File name
  downloadLink.download = fileName;

  // Create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile);

  // Hide download link
  downloadLink.style.display = "none";

  // Add the link to DOM
  document.body.appendChild(downloadLink);

  // Click download link
  downloadLink.click();

  downloadLink.parentNode.removeChild(downloadLink);
};

export default downloadCSV;