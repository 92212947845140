import Loading from './Loading.js';

export default {
  name: `AdminHome`,
  components: {
    Loading
  },
  data() {
    return {
      errorMsg : null,
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    business() {
      return this.$store.state.business;
    },
    lastPayroll() {
      return this.$store.state.lastPayroll;
    },
    currentPayrollPeriods() {
      return this.$store.state.currentPayrollPeriods;
    },
    config() {
      return this.$store.state.config;
    },
    tokenUrl() {
      let tokenUrl = '';
      if (this.config.token.values.length > 0) {
        tokenUrl = '#/token/' + this.config.token.values[0];
      }
      return tokenUrl;
    },
    dashboardUrl() {
      return window.location.protocol + '//' + window.location.hostname + '/dashboard/' + this.tokenUrl;
    },
    timeClockUrl() {
      return window.location.protocol + '//' + window.location.hostname + '/timeclock/' + this.tokenUrl;
    },
    housekeepingUrl() {
      return window.location.protocol + '//' + window.location.hostname + '/hs.htm' + this.tokenUrl;
    },
    isConfigLoading() {
      return this.$store.state.isConfigLoading;
    },
    publicUrl() {
      const hotel = this.$store.state.hotel;
      if (hotel && hotel.public_name) {
        return `https://h.innblocks.com/${hotel.public_name}`;
      }
    }
  },
  methods: {
    handleUpdateToken() {
      const isConfirmed = confirm('Do you wanna to change the token? \nThis will invalidate the previous tokens!');
      if (isConfirmed) {
        this.updateToken();
      }
    },
    updateToken() {
      this.$http.post('/business/update_token/')
        .then((response) => {
          if (response.data.business) {
            this.errorMsg = null;
            this.$store.dispatch('setBusiness', response.data.business);
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    console.log('adminhome')
  },
  template: `
    <div class="admin-home-component" v-if="business">
      <div class="component-header">
        <ul class="nav">
          <li><span class="item">Home</span></li>
        </ul>
      </div>
      <loading v-bind="{loading: isConfigLoading}"></loading>

      <div class="error error-block" v-if="errorMsg">
        {{errorMsg}}
      </div>  
      <div class="error error-block" v-if="currentPayrollPeriods && currentPayrollPeriods.error">
        {{currentPayrollPeriods.error}}
      </div>  
      <div class="warning-block" v-if="!user.email_verified">
        <router-link :to="{name: 'myAccount'}" class="button">Click here to verify your email address!</router-link>
      </div>

      <ul>
        <li>
          <router-link :to="{name: 'config'}">Business</router-link> &nbsp; &middot; &nbsp;  
          <router-link :to="{name: 'config', params: {action:'edit'}}">Config</router-link>
        </li>
        <li>
          <router-link :to="{name: 'hotel'}">Hotel</router-link>
        </li>
        <li>
          <router-link :to="{name: 'employees'}">Employees</router-link>
        </li>
        <li>
          <router-link :to="{name: 'dailyPunches', params: {date: 'today'}}">Today's Punches</router-link>
        </li>
        <li> Payroll
          <ul>
            <li v-if="currentPayrollPeriods">
              <router-link :to="{name: 'payroll', params: {
                startDate: currentPayrollPeriods.payroll_start_date,
                endDate: currentPayrollPeriods.payroll_end_date,
              }}">Current Payroll</router-link>
            </li>
            <li v-else><mark>Current Payroll Periods are not set!</mark></li>
            <li><router-link :to="{name: 'payrollReport', params: {reportType: 'id', reportId: lastPayroll.id}}" v-if="lastPayroll">Last payroll {{lastPayroll.pay_date}}</router-link></li>
            <li><router-link :to="{name: 'payrollReports'}">Payroll Reports</router-link></li>
            <li>Payroll Summary:
              <ul>
                <li><router-link :to="{name: 'payrollSummaryEmployees'}">Employees</router-link></li>
                <li><router-link :to="{name: 'payrollSummaryPositions'}">Positions</router-link></li>
                <li><router-link :to="{name: 'payrollSummaryMonths'}">Positions & Months</router-link></li>
              </ul>
            </li>
          </ul>
            
        </li>
        <li>
          <router-link :to="{name: 'products'}">All Products</router-link> - 
          <router-link :to="{name: 'productDetails', params: {productId: 'new'}}">Add a new Product</router-link>
        </li>
        <li>
          <router-link :to="{name: 'vendors'}">Vendors</router-link> &middot;
          <router-link :to='{name: "shop"}'>Shop Products</router-link> &middot;
          <router-link :to='{name: "purchaseOrders"}'>Purchase Orders</router-link>
        </li>
      </ul>

      <h3 class="component-title">STR Reports</h3>
      <ul>
        <li><router-link :to="{name: 'strDaily'}">Daily</router-link></li>
        <li><router-link :to="{name: 'strWeekly'}">Weekly</router-link></li>
        <li><router-link :to="{name: 'strMonthly', params: {monthlyOrT12: 'monthly'}}">Monthly</router-link></li>
        <li><router-link :to="{name: 'strMonthly', params: {monthlyOrT12: 't12'}}">T12</router-link></li>
        <li><router-link :to="{name: 'strMonthlyRevenue'}">Monthly Revenue</router-link></li>
      </ul>
        <h3 class="component-title">Apps</h3>
        <ul>
          <li>
          Time:Clock: <a :href="timeClockUrl">{{timeClockUrl}}</a>
          </li>
        <li>
          Dashboard <a :href="dashboardUrl">{{dashboardUrl}}</a>
        </li>
        <li>
          Housekeeping: <a :href="housekeepingUrl">{{housekeepingUrl}}</a>
        </li>
        <li>
            <a :href="publicUrl">{{publicUrl}}</a>
        </li>
      </ul>
      <br>
    </div>
  `,
};