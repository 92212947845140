import AdminPayrollReportsApi from '../apis/AdminPayrollReportsApi.js';
import AdminEmployeesApi from '../apis/AdminEmployeesApi.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import AdminPayrollHoursTd from './AdminPayrollHoursTd.js';
import DateTime from './DateTime.js';
import Loading from './Loading.js';
import TableGrid from './TableGrid.js';

export default {
  name: 'AdminEmployeePaychecks',
  mixins: [AdminPayrollReportsApi, AdminEmployeesApi],
  components: {
    AdminPayrollCurrencyTd,
    AdminPayrollHoursTd,
    Loading,
    TableGrid
  },
  data() {
    const gridColumns = [
      {key: 'pay_date', value: 'Pay Date'},
      {key: 'regular_hours', value: 'Reg Hrs'},
      {key: 'overtime_hours', value: 'OT Hrs'},
      {key: 'holiday_hours', value: 'Holiday Hrs'},
      {key: 'vacation_hours', value: 'Vacation Hrs'},
      {key: 'hourly bonus', value: '$ Hourly Bonus'},
      {key: 'bonus', value: '$ Bonus'},
      {key: 'total_wages', value: '$ Total Wages'}
    ];

    return {
      loading: false,
      employee: null,
      totalBonus: 0,
      totalWages: 0,
      totalHourlyBonus: 0,
      averageHourlyWage: 0,
      reports: [],
      averageHoursPerPayCheck: 0,
      dataByYear: {},
      isNewNoteVisible: 0,
      gridColumns
    };
  },
  props: ['employeeId', 'updateEmployee'],
  computed: {},
  watch: {
    employeeId () {
      this.fetchPayrollReport();
    }
  },
  methods: {
    handleAddNote() {
      this.isNewNoteVisible++;
    },
    pivotData() {
      let totalHourlyBonus = 0;
      let totalBonus = 0;
      let totalWages = 0;
      let totalHours = 0;
      const dataByYear = {};

      this.reports.forEach((report) => {
        const date = new Date(report.pay_date);
        const year = date.getFullYear();

        if (!dataByYear[year]) {
          dataByYear[year] = {
            totalHourlyBonus: 0,
            totalBonus: 0,
            totalWages: 0,
            totalVacationHours: 0,
            totalRegularHours: 0,
            totalHolidayHours: 0,
            totalHours: 0,
            averageHourlyWage: 0
          };
        }

        totalHourlyBonus += parseFloat(report.hourly_bonus, 10);
        totalBonus += parseFloat(report.bonus, 10);
        totalWages += parseFloat(report.total_wages, 10);
        totalHours += parseFloat(report.regular_hours, 10) + parseFloat(report.holiday_hours, 10) + parseFloat(report.overtime_hours, 10);

        dataByYear[year].totalHours += parseFloat(report.regular_hours, 10) + parseFloat(report.holiday_hours, 10) + parseFloat(report.overtime_hours, 10);
        dataByYear[year].totalHourlyBonus += parseFloat(report.hourly_bonus, 10);
        dataByYear[year].totalBonus += parseFloat(report.bonus, 10);
        dataByYear[year].totalWages += parseFloat(report.total_wages, 10);
        dataByYear[year].totalVacationHours += parseFloat(report.vacation_hours, 10);
        dataByYear[year].totalRegularHours += parseFloat(report.regular_hours, 10);
        dataByYear[year].totalHolidayHours += parseFloat(report.holiday_hours, 10);

        dataByYear[year].averageHourlyWage = window.numeral(parseFloat(dataByYear[year].totalWages / dataByYear[year].totalHours, 10)).format('0.00');
      });

      this.dataByYear = dataByYear;
      this.totalHourlyBonus = totalHourlyBonus;
      this.totalBonus = totalBonus;
      this.totalWages = totalWages;
      this.averageHourlyWage = window.numeral(totalWages / totalHours).format('0.00');
      this.averageHoursPerPayCheck = window.numeral(totalHours / this.reports.length).format('0.00');
    },
    fetchEmployee () {
      this.loading = true;
      this
        .fetchUser(this.employeeId)
        .then((response) => {
          if (response.data.user) {
            this.employee = response.data.user;
          } else if (response.data.error) {
            this.errorMsg = response.data.error;
          } 
        })
        .catch((error) => {
          this.errorMsg = error.response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchPayrollReport() {
      this.loading = true;
      this.reports = [];
      this.employee = null;
      this.totalBonus = 0;
      this.totalWages = 0;
      this.averageHoursPerPayCheck = 0;
      this.dataByYear = {};
      this.fetchEmployee();

      this
        .gePayrollReportForUser(this.employeeId)
        .then((response) => {
          if (response.data.error){
            this.errorMsg = response.data.error;
          } else {
            this.reports = response.data.payroll_report;
            this.pivotData();
          }  
        })
        .catch((error) => {
          this.errorMsg = error.response;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.fetchPayrollReport();
  },
  filters: {
    ...DateTime,
    integerCurrency(num) {
      return window.numeral(num).format('0,000');
    }
  },
  template: `
    <div class="admin-employee-paychecks-component">
      <loading v-bind="{loading: loading}"></loading>
      <section class="component-header">
        <ul class="component-options">
          <li>
            <router-link
              :to="{name: 'employees', params: {employeeId}}">
              Profile
            </router-link>
          </li>
          <li>
            <router-link
            :to="{name: 'employees', params: {employeeId, action: 'punches'}}">
              Punches
            </router-link>
          </li>
        </ul>
      </section>
      <section class="content" v-if="employee">
        <h3 class="name">
          {{employee.first_name}} {{employee.last_name}}
          <span class="inactive" v-if="!employee.active">Inactive</span>
        </h3>
        <section class="grid-2">
          <div class="info">
            <span class="title">Hire Date</span>
            <span class="value">{{employee.hire_date | numericDate}}</span>
          </div>
          <div v-if="!employee.active" class="info end-date">
            <span class="title">End Date</span>
            <span class="value">{{employee.end_date}}</span>
          </div>
        </section>
        <section class="grid-5">
          <div class="info">
            <span class="title">Average Hrs</span>
            <span>{{averageHoursPerPayCheck}}</span>
          </div>
          <div class="info">
            <span class="title"># of paychecks</span>
            <span>{{reports.length}}</span>
          </div>
          <div class="info">
            <span class="title">Total Hourly Bonus</span>
            <span>$ {{totalHourlyBonus | integerCurrency}}</span>
          </div>
          <div class="info">
            <span class="title">Total Bonus</span>
            <span>$ {{totalBonus | integerCurrency}}</span>
          </div>
          <div class="info">
            <span class="title">Total Wages</span>
            <span>$ {{totalWages | integerCurrency}}</span>
          </div>
          <div class="info">
            <span class="title">Ave Hourly Wage</span>
            <span>$ {{averageHourlyWage}}</span>
          </div>
        </section>
        <hr class="line">
        <div v-for="(data, year) in dataByYear" class="grid-5">
          <div class="info">
            <span class="title">Reg Hrs ({{year}})</span>
            <span>{{data.totalRegularHours}}</span>
          </div>
          <div class="info">
            <span class="title">Holiday Hrs ({{year}})</span>
            <span>{{data.totalHolidayHours}}</span>
          </div>
          <div class="info">
            <span class="title">Vacation Hrs ({{year}})</span>
            <span>{{data.totalVacationHours}}</span>
          </div>
          <div class="info">
            <span class="title">Hourly Bonus ({{year}})</span>
            <span>$ {{data.totalHourlyBonus | integerCurrency}}</span>
          </div>
          <div class="info">
            <span class="title">Bonus ({{year}})</span>
            <span>$ {{data.totalBonus | integerCurrency}}</span>
          </div>
          <div class="info">
            <span class="title">Wages ({{year}})</span>
            <span>$ {{data.totalWages | integerCurrency}}</span>
          </div>
          <div class="info">
            <span class="title">Avg Hourly Wage ({{year}})</span>
            <span>$ {{data.averageHourlyWage}}</span>
          </div>
        </div>
        <hr class="line">
        <h3 class="component-sticky-title">Paychecks</h3>

        <table-grid
          class="table-block"
          :data="reports"
          defaultSortColumn="pay_date"
          defaultSortOrder="-1"
          :columns="gridColumns">
          <template slot="rows" slot-scope="row">
            <td>
              <router-link :to="{name: 'payrollReport', params: {reportType: 'id', reportId: row.payroll_report_id}}">
                {{row.pay_date | numericDate}}
              </router-link>
            </td>
            <AdminPayrollHoursTd v-bind="{value: row.regular_hours}"></AdminPayrollHoursTd>
            <AdminPayrollHoursTd v-bind="{value: row.overtime_hours}"></AdminPayrollHoursTd>
            <AdminPayrollHoursTd v-bind="{value: row.holiday_hours}"></AdminPayrollHoursTd>
            <AdminPayrollHoursTd v-bind="{value: row.vacation_hours}"></AdminPayrollHoursTd>
            <AdminPayrollCurrencyTd v-bind="{value: row.hourly_bonus}"></AdminPayrollCurrencyTd>
            <AdminPayrollCurrencyTd v-bind="{value: row.bonus}"></AdminPayrollCurrencyTd>
            <AdminPayrollCurrencyTd v-bind="{value: row.total_wages}"></AdminPayrollCurrencyTd>
          </template>
        </table-grid>
      </section>
    </div>
  `
};