
export default {
  methods: {
    fetchUser(id) {
      return this.$http.get('users/' + id)
    },
    fetchActiveUsers() {
      return this.$http.get('users/active')
    },
    fetchInActiveUsers() {
      return this.$http.get('users/inactive')
    },
    fetchUsers(type) {
      return this.$http.get('users/' + (type == 'active' ? 'active' : 'inactive'));
    },
    updateUser(user) {
      return this.$http.post('users/', user)
    },
    fetchEmployeePunches(userId) {
      return this.$http.get('punches/user/' + userId);
    },
    fetchEmployeeNotes(userId) {
      return this.$http.get('users/' + userId + '/notes');
    },
    postEmployeeNotes(data) {
      return this.$http.post('users/note', data);
    },
    fetchEmployeeAttendance(userId) {
      return this.$http.get('user_attendance/' + userId);
    },
    postEmployeeAttendance(data) {
      return this.$http.post('user_attendance', data);
    }
  }
};