
export default {
  name: `Modal`,
  props: ['size', 'onClose'],
  methods: {
    handleClose() {
      console.log('handleClose')
      if (this.onClose) {
        this.onClose();
      }
    }
  },
  template: `
    <transition name="modal">
      <div class="modal-component">
        <div class="modal-overlay" v-on:click.prevent="handleClose"></div>
        <div :class="{'modal-container': true, [this.size]: this.size}">
          <a href="#" class="modal-close" @click="onClose" v-if="onClose">&#10005;</a>
          <slot></slot>
        </div>
      </div>
    </transition>
  `,
};