

export default {
  name: `HeaderDropdown`,
  components: {
    vnode: {
      functional: true,
      render(h, context){
        return context.props.node
      }
    }
  },
  data () {
    return {
      subActive: false
    }
  },
  computed: {
  },
  watch: {

  },
  methods: {
    handleClick() {
      console.log('handleClick');
      this.subActive = !this.subActive;
    },
    handleOptionClick() {
      this.subActive = false;
    }
  },
  mounted () {

  },
  template: `
    <div class="header-dropdown-component">
      <a href="#" v-on:click.prevent="handleClick"><slot name="default"></slot></a>
      
      <ul :class="{active: subActive}" v-on:click.prevent="handleOptionClick">
        <li v-for="option in $slots.option"><vnode :node="option" /></li>
      </ul>
    </div>
  `,
};