
export default {
  name: 'Four0Four',
  data () {
    return {

    }
  },
  computed: {
    href () {
      return window.location.href;
    }
  },
  template: `
    <div class="404-component" style="background: white; text-align: center; padding-bottom: 4rem;">
      <h1 style="font-weight: 100; font-size: 9rem;">404</h1>
      <p>
      Well, this is awkward. The page you were trying to view does not exist.
      </p>
      <br><br>
      {{href}}
    </div>
  `,
};