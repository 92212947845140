
import Loading from './Loading.js';
import AdminEmployeesApi from '../apis/AdminEmployeesApi.js';
import DateTime from './DateTime.js';
import AdminEmpoyeeAttendanceEditModal from './AdminEmpoyeeAttendanceEditModal.js';

export default {
  name: `AdminEmployeeNotes`,
  mixins: [AdminEmployeesApi],
  components: {
    Loading,
    AdminEmpoyeeAttendanceEditModal
  },
  props: ['employeeId', 'isNewNoteVisible'],
  data () {
    return {
      loading: false,
      errorMsg: null,
      notes: [],
      attendance: [],
      isNoteFormVisible: false,
      isAttendanceModalVisible: false,
      note: ''
    }
  },
  computed: {
    me () {
      return this.$store.state.user;
    },
    pivotedItems () {
      const items = [];
      this.notes.forEach(item => {
        items.push(item);
      });

      this.attendance.forEach(item => {
        items.push(item);
      });

      items.sort((a, b) => {
        const aDate = +new Date(a.created_on);
        const bDate = +new Date(b.created_on);

        return bDate - aDate;
      });

      return items;
    }
  },
  watch: {
    employeeId () {
      this.fetchNotes();
      this.fetchAttedence();
    },
    isNewNoteVisible () {
      this.isNoteFormVisible = this.isNewNoteVisible > 0;
      this.focusForm();
    }
  },
  methods: {
    focusForm() {
      setTimeout(() => {
        this.$refs.form.focus();
      }, 10);
    },
    handleEditAttendance () {
      this.isAttendanceModalVisible = true;
    },
    handleUpdateAttendance (updated) {
      if (updated) {
        this.$toast.show(updated);
        this.fetchAttedence();
      }
      this.isAttendanceModalVisible = false;
    },
    handleWriteNote () {
      this.isNoteFormVisible = !this.isNoteFormVisible;
      this.focusForm();
    },
    handleCancelNote () {
      this.isNoteFormVisible = false;
      this.note = '';
    },
    handleSaveNote () {
      this.postEmployeeNotes({
        note_user_id: this.employeeId,
        note: this.note
      })
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        } else {
          this.notes = response.data.notes;
          this.isNoteFormVisible = false;
          this.note = '';
        }
      })
      .catch((error) => {
        this.errorMsg = error.response;
      })
      .finally(() => {
        this.loading = false;
      });
    },
    fetchNotes () {
      this.loading = true;
      this.notes = [];
      this.fetchEmployeeNotes(this.employeeId)
      .then((response) => {
        if (!response.data.error) {
          this.errorMsg = null;
          this.notes = response.data.notes;
        }
        else {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    fetchAttedence () {
      this.loading = true;
      this.attendance = [];
      this.fetchEmployeeAttendance(this.employeeId)
      .then((response) => {
        if (!response.data.error) {
          this.errorMsg = null;
          this.attendance = response.data.items;
        }
        else {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchNotes();
    this.fetchAttedence();
  },
  template: `
    <div class="admin-employee-notes-component">
      <loading v-bind="{loading}"></loading>
      <section class="note-write">
        <a href="#" v-on:click.prevent="handleWriteNote" v-if="!isNoteFormVisible" class="button button-small">Add a Note</a>
        <a href="#" v-on:click.prevent="handleEditAttendance" v-if="!isNoteFormVisible" class="button button-small">Add an Attendance</a>
        <AdminEmpoyeeAttendanceEditModal v-if="isAttendanceModalVisible" :employeeId="employeeId" :onUpdate="handleUpdateAttendance"></AdminEmpoyeeAttendanceEditModal>
        <form class="form form-note" v-if="isNoteFormVisible">
          <div>Author: {{me.first_name}} {{me.last_name}}</div>
          <div><textarea class="textarea" v-model="note" maxlength="120" ref="form"></textarea></div>
          <div class="buttons">
            <button class="button" v-on:click.prevent="handleSaveNote">Save Note</button>
            <button class="button button-cancel" v-on:click.prevent="handleCancelNote">Cancel</button>
          </div>
        </form>
      </section>
      <section class="pivotedItems">
        <ol>
          <li v-for="item in pivotedItems">
            <p v-if="item.note">{{item.note}}</p>
            <p v-if="item.attendance_type">
              <mark>{{item.attendance_type}}</mark> on {{item.date | numericDate}}
              <mark v-if="item.deleted_on" class="muted">Deleted on {{item.deleted_on | numericDateTime}}</mark>  
            </p>
            <div class="note-meta"><span class="note-time">{{item.created_on | numericDateTime}}</span> <router-link :to="{name: 'employees', params: {employeeId: item.created_user_id}}">Author</router-link></div>
          </li>
        </ol>
      </section>
    </div>
  `,
};