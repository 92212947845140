
const DropdownDate = Object.freeze({
  LAST_30_DAYS: 'LAST_30_DAYS',
  LAST_12_MONTHS: 'LAST_12_MONTHS',
  YEAR_TO_DATE: 'YEAR_TO_DATE',
  LAST_YEAR: 'LAST_YEAR',
  NO_FILTER: 'NO_FILTER',
  DEFAULT_SELECTED: 'LAST_30_DAYS',
  YEAR_2018: 'YEAR_2018',
  YEAR_2019: 'YEAR_2019',
  YEAR_2020: 'YEAR_2020',
  YEAR_2021: 'YEAR_2021',
  YEAR_2022: 'YEAR_2022',
  YEAR_2023: 'YEAR_2023',
  LABELS: {
    LAST_30_DAYS: "Last 30 Days",
    LAST_12_MONTHS: "Last 12 Months",
    YEAR_TO_DATE: "Year to Date",
    LAST_YEAR: "Last Year",
    NO_FILTER: "No Filter",
    YEAR_2018: '2018',
    YEAR_2019: '2019',
    YEAR_2020: '2020',
    YEAR_2021: '2021',
    YEAR_2022: '2022',
    YEAR_2023: '2023',
  }, 
  ORDER: ['LAST_30_DAYS', 'LAST_12_MONTHS', 'YEAR_TO_DATE', 'LAST_YEAR', 'NO_FILTER', 'YEAR_2023',  'YEAR_2022', 'YEAR_2021', 'YEAR_2020', 'YEAR_2019', 'YEAR_2018']
});

export default DropdownDate;