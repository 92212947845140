

export default {
  name: `AdminError`,
  props: {
    error: String
  },
  data () {
    const messages = {
      'invalid_user': "Please login as a valid user who has access to this page!"
    };

    return {
      messages
    };
  },
  computed: {
    errorMsg () {
      return this.messages[this.error] || this.error;
    }
  },
  template: `
    <div class="admin-error-component" v-if="error">
      {{this.errorMsg}}
    </div>
  `,
};