import Modal from './Modal.js';
import Loading from './Loading.js';
import FormInput from './form/FormInput.js';
import FormSelectTimezone from './form/FormSelectTimezone.js';

export default {
  name: 'AdminConfigEditModal',
  components: {
    FormInput,
    FormSelectTimezone,
    Modal,
    Loading
  },
  props: ['configItem', 'onUpdate'],
  data () {
    return {
      loading: false,
      errorMsg: null,
      success: null,
      updatedConfigItem: {
      }
    };
  },
  methods: {
    handleChangeConfigValue (value) {
      this.updatedConfigItem.value = value.trim();
    },
    handleCancel() {
      this.onUpdate('Cancelled Config Edit!');
    },
    handleDelete() {
      if (confirm('Are you sure you want to delete this config item?')) {
        this.deleteItem();
      }
    },
    saveConfigItem () {
      console.log(this.updatedConfigItem.value, this.configItem.value, this.updatedConfigItem.value != this.configItem.value)
        
      if (this.updatedConfigItem.value && this.updatedConfigItem.value != this.configItem.value) {
        if (this.configItem.id) {
          this.updatedConfigItem.id = this.configItem.id;
        }
        if (this.configItem.name) {
          this.updatedConfigItem.name = this.configItem.name;
        }
        this.updateConfigItem();
      }
    },
    updateConfigItem() {
      this.loading = true;
      this.errorMsg = null;

      this.$http.post('/config/update/', this.updatedConfigItem)
        .then((response) => {
          if (response.data.item) {
            this.errorMsg = null;
            this.onUpdate('Updated Config ' + this.updatedConfigItem.name );
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItem () {
      this.loading = true;
      this.errorMsg = null;

      this.$http.post('/config/delete/', {id: this.configItem.id})
        .then((response) => {
          if (response.data.item) {
            this.errorMsg = null;
            this.onUpdate('Deleted Config ' + this.configItem.name );
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {
    console.log(this.configItem.label)
  },
  template: ` 
    <modal :onClose="handleCancel">
      <div class="component-header">
        <h2 class="component-header-title">
        {{configItem.id ? 'Edit' : 'Add'}} Config {{configItem.label || configItem.name}} ({{configItem.name}})
        </h2>
      </div>
      <div class="component-body">
        <form class="form" v-on:submit.prevent="saveConfigItem">
          <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
          <form-select-timezone :label="configItem.label || configItem.name" :value="configItem.value" :onChange="handleChangeConfigValue" v-if="configItem.name=='timezone'"></form-select-timezone>
          <form-input :label="configItem.label || configItem.name" :value="configItem.value" :onChange="handleChangeConfigValue" :type="configItem.type || 'text'" focus="true" v-else></form-input>
          <div class="error" v-if="errorMsg">{{errorMsg}}</div>
          <div class="padding-top">
            <button class="button">
              {{configItem.id ? 'Save' : 'Add'}}
            </button>
            <a class="button button-cancel" href="#" v-on:click.prevent="handleDelete" v-if="configItem.id">delete</a>
            <a class="button button-cancel" href="#" v-on:click.prevent="handleCancel">Cancel</a>
          </div>
        </form>
      </div>
    </modal>
  `
};