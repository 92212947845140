import DateTime from './DateTime.js';
import Dropdown from './Dropdown.js';
import DropdownDateConstant from './constants/DropdownDateConstant.js';
import DateRangeHelper from './helpers/DateRangeHelper.js';

export default {
  name: `DropdownDateRange`,
  components: {
    Dropdown
  },
  props: {
    onSelect: {
      functional: true
    },
    selectedOptionName: String
  },
  data () {
    const dropdownOptions = DropdownDateConstant.ORDER.map(item => {
      return {
        label: DropdownDateConstant.LABELS[item],
        name: item
      };
    });

    const selected = this.selectedOptionName || DropdownDateConstant.DEFAULT_SELECTED;
    const dropdownSelectedOption = {
      label: DropdownDateConstant.LABELS[selected],
      name: selected
    };
    
    return {
      dropdownOptions,
      dropdownSelectedOption
    };
  },
  computed: {
    startDate () {
      return this.$route.query.startDate;
    },
    endDate () {
      return this.$route.query.endDate;
    }
  },
  watch: {

  },
  methods: {
    handleDropdownSelect (selectedOption) {
      this.dropdownSelectedOption = selectedOption;
      const range = DateRangeHelper.getDateRange(selectedOption.name);
      this.onSelect(DateTime.inputDate(range.startDate), DateTime.inputDate(range.endDate), selectedOption.name);
      console.log(DateTime.inputDate(range.startDate), DateTime.inputDate(range.endDate));
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    console.log(this.$route.query);
  },
  template: `
    <div class="dropdown-date-range-component">
      <dropdown v-bind='{options: dropdownOptions, selectedOption: dropdownSelectedOption, onSelect: handleDropdownSelect}'></dropdown>
    </div>
  `,
};