
const state = {
  isIncompleteProfile: null,
  isConfigLoading: true,
  user: null,
  business: null,
  hotel: null,
  username: null,
  isValidSession: true,
  lastPayroll: null,
  roomTypes: null,
  currentPayrollPeriods: null,
  config: null
};

const mutations = {
  IS_INCOMPLETE_PROFILE (state, isIncompleteProfile) {
    state.isIncompleteProfile = isIncompleteProfile;
  },
  IS_CONFIG_LOADING (state, isConfigLoading) {
    state.isConfigLoading = isConfigLoading;
  },
  ACTIVE_EMPLOYEES (state, activeEmployees) {
    state.activeEmployees = activeEmployees;
  },
  USER (state, user) {
    state.user = user;
  },
  USERNAME (state, username) {
    state.username = username;
  },
  BUSINESS (state, business) {
    state.business = business;
  },
  CONFIG (state, config) {
    state.config = config;
  },
  HOTEL (state, hotel) {
    state.hotel = hotel;
  },
  IS_VALID_SESSION (state, isValidSession) {
    console.log('---- IS_VALID_SESSION', isValidSession)
    state.isValidSession = isValidSession;
  },
  LAST_PAYROLL (state, lastPayroll) {
    state.lastPayroll = lastPayroll;
  },
  CURRENT_PAYROLL_PERIODS (state, currentPayrollPeriods) {
    state.currentPayrollPeriods = currentPayrollPeriods;
  },
  ROOM_TYPES (state, roomTypes) {
    state.roomTypes = roomTypes;
  }
};

const actions = {
  setIsIncompleteProfile (context, payload) {
    context.commit('IS_INCOMPLETE_PROFILE', payload)
  },
  setIsConfigLoading: (context, payload) => context.commit('IS_CONFIG_LOADING', payload),
  setUser: (context, payload) => context.commit('USER', payload),
  setUsername: (context, payload) => context.commit('USERNAME', payload),
  setBusiness: (context, payload) => context.commit('BUSINESS', payload),
  setConfig: (context, payload) => context.commit('CONFIG', payload),
  setHotel: (context, payload) => context.commit('HOTEL', payload),
  setActiveEmployees: (context, payload) => context.commit('ACTIVE_EMPLOYEES', payload),
  setIsValidSession: (context, payload) => context.commit('IS_VALID_SESSION', payload),
  setLastPayroll: (context, payload) => context.commit('LAST_PAYROLL', payload),
  setCurrentPayrollPerids: (context, payload) => context.commit('CURRENT_PAYROLL_PERIODS', payload),
  setRoomTypes: (context, payload) => context.commit('ROOM_TYPES', payload),
  goToLogin () {
    window.setTimeout(() => {
      window.location.href = window.loginUrl || '/ho/login/';
    }, 10);
  },
  logout: (context) => {
    window.api.get('/auth/logout')
      .then((response) => {
        if (!response.data.user) {
          context.commit('IS_VALID_SESSION', false);
          location.reload();
        }
        else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
      });
  },
  fetchConfig (context) {
    context.commit('IS_CONFIG_LOADING', true);
    window.api.get('/app/admin')
      .then((response) => {
        if (response.data.error) {
          if (response.data.error === 'invalid_session_user') {
            window.sessionStorage.setItem('afterLoginPath', window.location.href);
            context.dispatch('goToLogin');
          }
          this.errorMsg = response.data.error;
        }
        else {
          if (response.data.incomplete !== null) {
            context.dispatch('setIsIncompleteProfile', response.data.incomplete);
          }

          if (response.data.lastPayroll && response.data.lastPayroll.length) {
            context.dispatch('setLastPayroll', response.data.lastPayroll[0]);            
          }
          
          const actionSet = new Map([
            ['business', 'setBusiness'],
            ['hotel', 'setHotel'],
            ['config', 'setConfig'],
            ['logged_in_user', 'setUser'],
            ['username', 'setUsername'],
            ['current_payroll_period', 'setCurrentPayrollPerids'],
          ]);
  
          for (const [key, value] of actionSet) {
            if (response.data[key]) {
              context.dispatch(value, response.data[key]);
            } else {
              console.error('key not found: ' + key);
            }
          }

        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
        context.commit('IS_CONFIG_LOADING', false);
      });
  },
  start: context => {
    // Add a response interceptor
    if (window.api) {
      window.api.interceptors.response.use((response) => {
        // Do something with response data
        if (response.data.error == 'invalid_session_user') {
          context.commit('IS_VALID_SESSION', false);
        }
        return response;
      }, (error) => {
        // Do something with response error
        return Promise.reject(error);
      });
    }
    context.dispatch('fetchConfig');
  }
};

const getters = {
  
};

export default {
  state,
  getters,
  actions,
  mutations
};
