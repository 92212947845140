import AdminPayrollCalculationInfo from './AdminPayrollCalculationInfo.js';
import AdminPayrollHoursTd from './AdminPayrollHoursTd.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import DateTime from './DateTime.js';
import Loading from './Loading.js';
import FormInput from './form/FormInput.js';
import FormTextarea from './form/FormTextarea.js';

export default {
  name: `AdminEmailPayrollReport`,
  components: {
    AdminPayrollCalculationInfo,
    AdminPayrollHoursTd,
    AdminPayrollCurrencyTd,
    Loading,
    FormInput,
    FormTextarea,
    Name: {
      props: ['detail'],
      methods: {
        removeEmployee () {
          this.detail.hidden = true;
        }
      },
      template: `
        <td>
          <a href="#" v-on:click.prevent="removeEmployee">Hide</a>
        </td>
      `
    },
    Info: {
      template: `
        <div class="info">
          <span class="title">
            <slot name="title"></slot>
          </span>
          <span class="value"><slot></slot></span>
        </div>
      `
    },
    Cell: {
      props: ['value', 'prefix'],
      computed: {
        renderValue () {
          return parseFloat(this.value, 10) === 0 ? '' : (this.prefix ? this.prefix + ' ' : '') + this.value;
        }
      },
      template: `
        <td align="right">{{renderValue}}</td>
      `
    }
  },
  data () {
    return {
      payrollData: null,
      payrollReport: null,
      loading: false,
      startTime: 0,
      errorMsg: null,
      email: {
        to: 'dharmesh@gmail.com',
        subject: 'Payroll Report',
        notes: ''
      },
      isEmailFormat: false,
      successMsg: null
    };
  },
  computed: {
    payrollReportId () {
      return this.$route.params.reportId;
    },
    payrollReportType () {
      return this.$route.params.reportType;
    },
    user () {
      return this.$store.state.user;
    },
    business () {
      return this.$store.state.business;
    }
  },
  watch: {
    payrollReportId () {
      this.fetchPayrollReport();
    }
  },
  methods: {
    handleOpenInGmail () {
      const subject = this.emailSubject;
      const body = document.getElementById('emailcontent').innerHTML;

      window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=&su=${subject}&body=${body}`);
    },
    handleInputChange (value, name) {
      this.email[name] = value;
    },
    handleSendCancel () {
      this.isEmailFormat = false;
    },
    handleCreateEmail () {
      this.isEmailFormat = true;
    },
    handleSendEmail () {
      this.email.body = this.$refs.emailBody.innerHTML;
      console.log(this.$refs.emailBody.innerHTML);
      this.postEmail();
    },
    pivoteData (data) {
      if (!data) {
        return;
      }

      data.details.forEach((item) => {
        if (typeof item.hidden === 'undefined') {
          item.hidden = false;
        }
      });

      let notes = [];

      data.notes.forEach(note => {
        notes.push(note.note);
      });

      this.email.subject = 'Payroll Report ' + DateTime.humanDate(data.end_date);
      this.email.notes = notes.join('\n');

      this.payrollReport = data;
    },
    fetchPayrollReport () {
      this.loading = true;
      this.errorMsg = null;

      this.$http.get('/payroll/reports/' + this.payrollReportType +'/' + this.payrollReportId)
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        } else {
          if (response.data.payroll_report) {
            this.pivoteData(response.data.payroll_report);
          }
          else {
            this.errorMsg = 'Payroll does not exist!';
          }
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    removeEmployee (detail) {
      this.payrollData.details.forEach((item) => {
        if (item.id === detail.id) {
          item.hidden = true;
        }
      });
    },
    hourlyRate (detail) {
      return detail.is_hourly == 1 ? detail.rate : 0;
    },
    salary (detail) {
      return detail.is_hourly == 1 ? 0 : detail.rate;
    },
    getTotalBonus(detail) {
      return window.numeral(detail.bonus).add(detail.hourly_bonus).format('0.00');
    },
    postEmail() {
      this.loading = true;
      this.errorMsg = null;

      this.$http.post('/email/', this.email)
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        } else {
          this.successMsg = response.data;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchPayrollReport();
  },
  template: `
    <div class="admin-payroll-report">
      <div class="component-header">

        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li><router-link :to="{name: 'payrollReports'}" class="item">Payroll Reports</router-link></li>
          <li><router-link :to="{name: 'payrollReport', payrollReportId: payrollReportId}" class="item">{{payrollReportId}}</router-link></li>
          <li><span class="item">Email Payroll</span></li>
        </ul>
        <ul class="component-options">
          <li></li>
        </ul>
      </div>
      <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
      <div class="error" v-if="errorMsg">{{errorMsg}}</div>
      <div v-if="payrollReport" class="grid">
        <info>
          <template slot="title">Start Date</template>
          {{payrollReport.start_date | humanDate}}
        </info>
        <info>
          <template slot="title">End Date</template>
          {{payrollReport.end_date | humanDate}}
        </info>
        <info>
          <template slot="title">Check Date</template>
          {{payrollReport.pay_date | humanDate}}
        </info>
        <info>
          <template slot="title">Report ID</template>
          {{payrollReport.id}}
        </info>
        <info>
          <template slot="title">Created By</template>
          {{payrollReport.first_name}} {{payrollReport.last_name}}
        </info>
        <info>
          <template slot="title">Created Date</template>
          {{payrollReport.updated_on | humanDate}}
        </info>
      </div>
      <div class="payroll-report" v-if="payrollReport && !isEmailFormat">
        <form class="form" v-on:submit.prevent>

          <form-input type="email" name="email" @change="handleInputChange" label="Email" placeholder="Email Address" :value="email.to"></form-input>
          <form-input type="text" name="subject" @change="handleInputChange" label="Subject" placeholder="Subject" :value="email.subject"></form-input>
          <div>
            <form-textarea name="notes" @change="handleInputChange" label="Notes" placeholder="Notes" :value="email.notes"></form-textarea>
          </div>
    
          <div>
            <strong>Start Date:</strong> {{this.payrollReport.start_date | humanDate}}
            <br>
            <strong>End Date:</strong> {{this.payrollReport.end_date | humanDate}}
            <br>
            <strong>Check Date:</strong> {{this.payrollReport.pay_date | humanDate}}
          </div>
          <br>
          <table border="1" cellpadding="3" cellspacing="0" id="email-table" borderColor="#dddddd">
            <thead>
              <tr>
                <th>Remove?</th>
                <th>Name</th>
                <th>$ Hourly Rate</th>
                <th>$ Salary</th>
                <th>Regular Hours</th>
                <th>Overtime Hours</th>
                <th>Holiday Hours</th>
                <th>Vacation Hours</th>
                <th>$ Houlry Bonus</th>
                <th>$ Bonus</th>
                <th>$ Total Bonus</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="detail in payrollReport.details" v-if="!detail.hidden">
                <td>
                  <name v-bind="{detail}"></name>
                </td>
                <td>{{detail.first_name}} {{detail.last_name}}</td>

                <cell :value="hourlyRate(detail)" prefix="$"></cell>
                <cell :value="salary(detail)" prefix="$"></cell>
                <cell :value="detail.regular_hours"></cell>
                <cell :value="detail.overtime_hours"></cell>
                <cell :value="detail.holiday_hours"></cell>
                <cell :value="detail.vacation_hours"></cell>
                <cell :value="detail.bonus" prefix="$"></cell>
                <cell :value="detail.hourly_bonus " prefix="$"></cell>
                <cell :value="getTotalBonus(detail)" prefix="$"></cell>
              </tr>
            </tbody>
          </table>

          <button class="button" v-on:click="handleCreateEmail">Create</button>
          <button type="button" class="button button-cancel" v-on:click="handleSendCancel">cancel</button>
        </form>
      </div>
      <div class="payroll-report" v-if="payrollReport && isEmailFormat">
      <div>
        To: <span>{{email.to}}</span>
      </div>
      <div>
        Subject: <span>{{email.subject}}</span>
      </div>

          <div id="emailcontent" ref="emailBody">
            <div>
              Notes: 
              <pre>
                {{email.notes}}
              </pre>
            </div>

            <div>
              <strong>Start Date:</strong> {{this.payrollReport.start_date | humanDate}}
              <br>
              <strong>End Date:</strong> {{this.payrollReport.end_date | humanDate}}
              <br>
              <strong>Check Date:</strong> {{this.payrollReport.pay_date | humanDate}}
            </div>
      
            <br>
            <table border="1" cellpadding="3" cellspacing="0" id="email-table" borderColor="#dddddd">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>$ Hourly Rate</th>
                  <th>$ Salary</th>
                  <th>Regular Hours</th>
                  <th>Overtime Hours</th>
                  <th>Holiday Hours</th>
                  <th>Vacation Hours</th>
                  <th>$ Bonus</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="detail in payrollReport.details" v-if="!detail.hidden">
                  <td>{{detail.first_name}} {{detail.last_name}}</td>
                  <cell :value="hourlyRate(detail)" prefix="$"></cell>
                  <cell :value="salary(detail)" prefix="$"></cell>
                  <cell :value="detail.regular_hours"></cell>
                  <cell :value="detail.overtime_hours"></cell>
                  <cell :value="detail.holiday_hours"></cell>
                  <cell :value="detail.vacation_hours"></cell>
                  <cell :value="getTotalBonus(detail)" prefix="$"></cell>
                </tr>
              </tbody>
            </table>

            <br><br>
            Thanks, <br>
            {{user.last_name}}, {{user.first_name}} <br>
            {{business.name}}
            <br><br>
          </div>
          <div v-if="successMsg" class="success-block">{{successMsg}}</div>
          <button class="button" v-on:click="handleSendEmail" v-if="loading">Sending</button>
          <button class="button" v-on:click="handleSendEmail" v-else>Send</button>
          <button class="button button-cancel" v-on:click="handleSendCancel">cancel</button>
          <br><br>
      </div>
    </div>
  `,
};