
const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return phoneNumberString
};

const zeroPadding = (num, digit) => {
  const zero = '0000000000000000000000000000';
  return (zero + num).slice(-digit);
};

export default {
  formatPhoneNumber,
  zeroPadding
}