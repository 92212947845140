
export default {
  name: 'FormField',
  props: ['label'],
  methods: {
    
  },
  template: `
    <div class="row">
      <label class="label">
        <span class="label-text" v-if="label">{{label}}</span>
        <span class="input-wrapper"><slot></slot></span>
      </label>
    </div>
  `
};