export default {
  name: 'OurLogo',
  props: ['name', 'htmlLogo', 'inverse', 'theme'],
  data () {
    return {
    };
  },
  computed: {
    logo () {
      return this.inverse ? 'https://innblocks.com/media/innblocks_inverse.svg' : 'https://innblocks.com/media/innblocks.svg';
    }
  },
  template: `
  <a href="https://innblocks.com" :class="{'our-logo': 1,  'brand': 1, 'our-logo-name': name}" target="_blank">
    <div class="" v-if="htmlLogo"><span class="icon"><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i><i></i></span><span class="text" v-if="name">Inn <div>Blocks</div></span></div>
    <div class="img-wrapper" v-else><img :src="logo" :class="{'theme': theme, 'innblocks-img': 1}"></div><span class="text" v-if="name">Inn <div>Blocks</div></span>
  </a>
  `
};