import TableGrid from './TableGrid.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import Loading from './Loading.js';
import AdminShopProductEditModal from './AdminShopProductEditModal.js';

export default {
  name: `AdminShop`,
  components: {
    Loading,
    TableGrid,
    AdminPayrollCurrencyTd,
    AdminShopProductEditModal,
    Tag: {
      props: ['tags'],
      computed: {
        t () {
          return this.tags ? this.tags.split(',') : [];
        }
      },
      template: `
        <ul class="tags">
          <li v-for="tag in t"><router-link :to='{name: "shopProductsByTags", params: {tags: tag}}' class="tag small">{{tag}}</router-link></li>
        </ul>
      `
    },
    Info: {
      template: `
        <div class="info">
          <span class="title">
            <slot name="title"></slot>
          </span>
          <span class="value"><slot></slot></span>
        </div>
      `
    }
  },
  data () {
    const gridColumns = [
      {key: '', value: ''},
      {key: 'name', value: 'Name'},
      {key: 'brand', value: 'Brand'},
      {key: '', value: ''},
    ];

    return {
      products: null,
      loading: false,
      gridColumns,
      gridData: [],
      itemsToShow: null,
      productToEdit: null,
    }
  },
  computed: {
    tags () {
      return this.$route.params.tags;
    }
  },
  watch: {
    itemsToShow () {
      this.pivotData();
    },
    tags() {
      this.fetchProducts();
    }
  },
  methods: {
    handleNew () {
      this.productToEdit = {
        id: 'new'
      };
    },
    handleEdit (item) {
      this.productToEdit = item;
    },
    handleEditSuccess () {
      this.productToEdit = null;
      this.fetchProducts();
    },
    fetchProducts () {
      this.loading = true;
      this.$http.get('/product_items/', {
        params: {
          tags: this.tags
        }
      })
      .then((response) => {
        if (!response.data.error) {
          this.products = response.data.items;
          this.pivotData();
        }
        else {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    pivotData () {
      const products = this.products;
      const gridData = [];

      products.forEach(item => {
        if (!this.itemsToShow || item.item === this.itemsToShow) {
          gridData.push(item);
        }
      });

      this.gridData = gridData;
    }
  },
  filters: {
  },
  mounted () {
    this.fetchProducts();
  },
  template: `
    <div class="admin-products-component component-fixed">
      <div class="component-header">
        <ul class="nav">
          <li>
            <router-link :to='{name: "home"}' class="item">Home</router-link>
          </li>
          <li>
            <router-link :to='{name: "shop"}' class="item" v-if="tags">Shop Products</router-link>
            <span class="item" v-else>Shop Products</span>
          </li>
          <li v-if="tags">
            <span class="item">Label: {{tags}}</span>
          </li>
        </ul>
        <ul class="component-options">
          <li><router-link :to='{name: "productTags"}'>Prodcut Tags</router-link></li>
          <li><router-link :to='{name: "purchaseOrders"}'>Purchase Orders</router-link></li>
          <li><router-link :to='{name: "vendors"}'>Vendors</router-link></li>
          <li><a href="#" v-on:click.prevent="handleNew">Add Product</a></li>
        </ul>
      </div>
      <loading v-bind="{loading: loading}"></loading>
      <admin-shop-product-edit-modal v-bind="{item: productToEdit, onUpdate: handleEditSuccess}" v-if="productToEdit"></admin-shop-product-edit-modal>
      <table-grid
        class="table-block"
        :data="gridData"
        defaultSortColumn="purchased_on"
        defaultSortOrder="-1"
        :columns="gridColumns"
        v-if="gridData.length">
          <template slot="rows" slot-scope="row">
          <td></td>
            <td>
              <router-link :to='{name: "shopProductDetails", params: {productId: row.id}}'>{{row.name}}</router-link>
              <tag :tags="row.tags"></tag>
            </td>
            <td>
              <ul class="options-buttons">
                <li><a href="#" v-on:click.prevent="handleEdit(row)" class="link">Edit Name</a></li>
                <li>
                  <router-link :to="{name: 'purchaseOrders', params: {field: 'product_item_id', fieldValue: row.id}}">
                    Previous Purchases
                  </router-link>
                </li>
              </ul> 
            </td>
            <td></td>
          </template>
      </table-grid>
    </div>
  `,
};