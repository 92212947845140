import DateTime from './DateTime.js';
import Loading from './Loading.js';
import TableGrid from './TableGrid.js';

import Dropdown from './Dropdown.js';
const Cell = {
  props: ['value', 'classNames', 'prefix', 'postfix'],
  template: `
    <td :class="{currency: 1, zero: value == 0, [classNames]: !!classNames}">
      <span class="muted" v-if="prefix">{{prefix}}</span>
      <span class="v">{{value}}<slot></slot></span>
      <span class="muted" v-if="postfix">{{postfix}}</span>
    </td>
  `
};

const gridColumns = [
  {key: 'iso_date', value: 'Date', type: String},
  {key: 'weekday', value: 'Weekdays', type: String},
  {key: 'my_occupancy_rounded', value: 'My ', type: Number},
  {key: 'comp_set_occupancy_rounded', value: 'Comp', type: Number},
  {key: 'index_occupancy_rounded', value: 'Index %', type: Number},
  {key: 'rank_occupancy', value: 'Rank', type: Number},
  {key: 'my_adr_rounded', value: 'My $', type: Number},
  {key: 'comp_set_adr_rounded', value: 'Comp $', type: Number},
  {key: 'index_adr_rounded', value: 'Index %', type: Number},
  {key: 'rank_adr', value: 'Rank', type: Number},
  {key: 'my_revpar_rounded', value: 'My $', type: Number},
  {key: 'comp_set_revpar_rounded', value: 'Comp $', type: Number},
  {key: 'index_revpar_rounded', value: 'Index %', type: Number},
  {key: 'rank_revpar', value: 'Rank', type: Number},
];


export default {
  name: `AdminStrDaily`,
  components: {
    Loading,
    Dropdown,
    TableGrid,
    Cell
  },
  data () {
    return {
      gridColumns,
      strItems: [],
      dropdownOptions: null,
      monthDropdownOptions: [],
      dateRangeSelectedOption: null,
      minIsoDate: null,
      maxIsoDate: null,
      selectedMonthOption: DateTime.getCurrentMonthName(),
      loading: false,
      errorMsg: null,
    };
  },
  computed: {
    business () {
      return this.$store.state.business;
    },
    dropdownSelectedOption () {
      const option = this.dropdownOptions.find(item => {
        return item.name == this.dateRangeSelectedOption;
      });

      return option || this.dropdownOptions[1];
    },
    monthDropdownSelectedOption () {
      const option = this.monthDropdownOptions.find(item => {
        return item.name == this.selectedMonthOption;
      });

      return option || this.monthDropdownOptions[0];
    },
    compareDate () {
      return this.$route.query.compareDate;
    },
    compareWeekday () {
      return this.$route.query.compareWeekday;
    },
    compareMonth () {
      return this.$route.query.compareMonth;
    }
  },
  watch: {
    compareDate () {
      this.fetchStrReport();
    },
    compareWeekday () {
      this.fetchStrReport();
    },
    compareMonth () {
      this.fetchStrReport();
    }
  },
  methods: {
    handleDropdownSelect (selectedOption) {
      this.dateRangeSelectedOption = selectedOption.name;
      console.log('selected', selectedOption.name);
      this.updateMonthDropwdownOption();
      
      if (this.compareDate || this.compareWeekday) {
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params
        });
      }
      this.fetchStrReport();
    },
    handleMonthDropdownSelect (selectedOption) {
      console.log('selected', selectedOption.name);
      this.selectedMonthOption = selectedOption.name;
      if (this.compareDate || this.compareWeekday) {
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params
        });
      }
      this.fetchStrReport();
    },
    updateMonthDropwdownOption () {
      let monthDropdownOptions = [];
      if (this.dateRangeSelectedOption.indexOf('YEAR_') === 0) {
        const selectedYear = this.dateRangeSelectedOption.replace('YEAR_', '');
        const minDate = new Date(this.minIsoDate);
        const maxDate = new Date(this.maxIsoDate);

        DateTime.months.forEach((month, monthIndex) => {
          let option;
          if (selectedYear == maxDate.getFullYear()) {
            if (monthIndex <= maxDate.getMonth()) {
              option = {
                name: month,
                label: month
              };
            }
          } else if (selectedYear == minDate.getFullYear()) {
            if (monthIndex > minDate.getMonth()) {
              option = {
                name: month,
                label: month
              };
            }
          } else {
            option = {
              name: month,
              label: month
            };
          }

          if (option) {
            monthDropdownOptions.push(option);
          }
        });
      } 
      
      this.monthDropdownOptions = monthDropdownOptions;
    },
    updateDropdownOptions(minIsoDate, maxIsoDate) {
      this.minIsoDate = minIsoDate;
      this.maxIsoDate = maxIsoDate;
      let options = [
        {
          name: 'last_30_days',
          label: 'Last 30 Days',
        },
        {
          name: 'last_90_days',
          label: 'Last 90 Days',
        }
      ];

      if (minIsoDate) {
        const minDate = new Date(minIsoDate);
        const minYear = minDate.getFullYear();
        const maxDate = new Date(maxIsoDate);
        let year = maxDate.getFullYear();

        while (year >= minYear) {
          options.push({
            name: 'YEAR_' + year,
            label: year
          });
          year--;
        }
      }

      this.dropdownOptions = options;
    },
    pivoteData (data) {
      if (!data) {
        return;
      }

      let currentYear;
      data.forEach(item => {
        item.weekday = DateTime.getShortWeekday(item.iso_date);

        let classNames = [];
        
        if (this.compareDate) {
          if (item.weekday.toLowerCase() == 'fri' || item.weekday.toLowerCase() == 'sat') {
            classNames.push('highlight');
          }
        }
        else if (this.compareWeekday) {
          if (item.year !== currentYear) {
            currentYear = item.year;
            classNames.push('border-bottom');
            console.log(currentYear);
          }
        }
        else if (this.dateRangeSelectedOption) {
          if (this.dateRangeSelectedOption.indexOf('YEAR_') === 0) {
            console.log(1);
          }
        } else {
          if (item.weekday.toLowerCase() == 'sun') {
            classNames.push('border-bottom');
          }
          if (item.weekday.toLowerCase() == 'sat') {
            classNames.push('border-top');
          }
        }

        item.classNames = classNames.join(' ');
      });

      this.strItems = data;
    },
    fetchStrReport () {
      this.loading = true;
      this.errorMsg = null;
      
      let params = {};

      if (this.compareDate) {
        const date = DateTime.isoDate(this.compareDate); 
        console.log(date);
        params.date = date.getDate();
        params.month = DateTime.getShortMonth(date);
      }
      else if (this.compareWeekday) {
        params.week_day = this.compareWeekday.substring(0, 2);
        params.month = this.compareMonth;
      }
      else if (this.dateRangeSelectedOption) {
        if (this.dateRangeSelectedOption.indexOf('YEAR_') === 0) {
          params.year = this.dateRangeSelectedOption.replace('YEAR_', '');
          params.month = this.selectedMonthOption.substring(0, 3);
        }
      }

      this.$http.get('/str/daily', {params})
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        } else {
          if (response.data.items) {
            this.pivoteData(response.data.items);
          }
          else {
            this.errorMsg = 'STR does not exist!';
          }
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    fetchRange () {
      this.loading = true;
      this.errorMsg = null;

      this.$http.get('/str/range/daily')
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        } else {
          if (response.data.items && response.data.items.length === 1) {
            this.updateDropdownOptions(response.data.items[0].min_iso_date, response.data.items[0].max_iso_date);
          }
          else {
            this.errorMsg = 'STR does not exist!';
          }
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchStrReport();
    this.fetchRange();
  },
  template: `
    <div class="admin-str-monthly">
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li><router-link :to="{name: 'strDaily'}" class="item">Str Daily</router-link></li>
          <li v-if="dropdownOptions"><span class="item"><dropdown v-bind='{options: dropdownOptions, selectedOption: dropdownSelectedOption, onSelect: handleDropdownSelect}'></dropdown></span></li>
          <li v-if="monthDropdownOptions.length"><span class="item"><dropdown v-bind='{options: monthDropdownOptions, selectedOption: monthDropdownSelectedOption, onSelect: handleMonthDropdownSelect}'></dropdown></span></li>
        </ul>
        <ul class="component-options">
          <li><router-link :to="{name: 'strWeekly'}" class="item">Weekly</router-link></li>
          <li><router-link :to="{name: 'strMonthly', params: {monthlyOrT12: 'monthly'}}" class="item">Monthly</router-link></li>
          <li><router-link :to="{name: 'strMonthly', params: {monthlyOrT12: 't12'}}" class="item">T12</router-link></li>
          <li><router-link :to="{name: 'strMonthlyRevenue'}" class="item">Monthly Revenue</router-link></li>
        </ul>
      </div>
      <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
      <div class="error" v-if="errorMsg">{{errorMsg}}</div>
      <div class="component-body">
        <h3 class="component-title">STR Daily</h3>
        <div class="error" v-if="!loading && strItems.length === 0">No Items Found</div>
        <table-grid
          v-if="strItems.length"
          class="table-block"
          :data="strItems"
          defaultSortColumn="iso_date"
          defaultSortOrder="-1"
          :columns="gridColumns">

          <template slot="top-thead">
            <tr>
              <th></th>
              <th></th>
              <th colspan="4">Occupancy %</th>
              <th colspan="4">ADR $</th>
              <th colspan="4">RevPar $</th>
            </tr>
          </template>
          <template slot="rows" slot-scope="row">
            <td :class="row.classNames">
              <router-link :to="{name: 'strDaily',query: {compareDate: row.iso_date}}" class="item">{{row.iso_date}}</router-link>
            </td>
            <td :class="row.classNames">
              <router-link :to="{name: 'strDaily',query: {compareWeekday: row.weekday, compareMonth: row.month}}" class="item">{{row.weekday}}</router-link>
            </td>
            <cell :class="[row.classNames, 'border-left']"  postfix="%" :value="row.my_occupancy_rounded"></cell>
            <cell :class="row.classNames" postfix="%" :value="row.comp_set_occupancy_rounded"></cell>
            <cell :class="row.classNames" postfix="%" :value="row.index_occupancy_rounded"></cell>
            <cell :class="row.classNames" :value="row.rank_occupancy"></cell>
            <cell :class="[row.classNames, 'border-left']" :value="row.my_adr_rounded"></cell>
            <cell :class="row.classNames" :value="row.comp_set_adr_rounded"></cell>
            <cell :class="row.classNames" postfix="%" :value="row.index_adr_rounded"></cell>
            <cell :class="row.classNames" :value="row.rank_adr"></cell>
            <cell :class="[row.classNames, 'border-left']" :value="row.my_revpar_rounded"></cell>
            <cell :class="row.classNames" :value="row.comp_set_revpar_rounded"></cell>
            <cell :class="row.classNames" postfix="%" :value="row.index_revpar_rounded"></cell>
            <cell :class="row.classNames" :value="row.rank_revpar"></cell>
          </template>
        </table-grid>

        {{strItems[0]}}
      </div>
    </div>
  `,
};