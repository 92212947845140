import DateTime from './DateTime.js';
import Loading from './Loading.js';
import TableGrid from './TableGrid.js';

export default {
  name: `AdminStrMonthly`,
  components: {
    Loading,
    TableGrid
  },
  data () {
    const gridColumns = [
      {key: 'month', value: 'Month', type: String},
      {key: 'year_monthnumber', value: 'Year', type: String},
      {key: 'my_occupancy_rounded', value: 'My ', type: Number},
      {key: 'comp_set_occupancy_rounded', value: 'Comp', type: Number},
      {key: 'my_adr_rounded', value: 'My $', type: Number},
      {key: 'comp_set_adr_rounded', value: 'Comp $', type: Number},
      {key: 'my_revpar_rounded', value: 'My $', type: Number},
      {key: 'comp_set_revpar_rounded', value: 'Comp $', type: Number},
      {key: 'my_revenue', value: 'My $', type: Number},
      {key: 'comp_set_revenue', value: 'Comp $', type: Number},
      {key: 'revenue_difference', value: 'Diff $', type: Number},
    ];
    const monthlyGridColumns = gridColumns.concat([
      {key: 'my_ytd_revenue', value: 'YTD $', type: Number}
    ]);
    return {
      monthlyGridColumns,
      t12GridColumns: gridColumns,
      strMonthlyItems: [],
      strT12Items: [],
      yearlyRevenue: null,
      loading: false,
      errorMsg: null,
    };
  },
  computed: {
    business () {
      return this.$store.state.business;
    },
    compareMonth () {
      return this.$route.query.compareMonth;
    },
    compareYear () {
      return this.$route.query.compareYear;
    }
  },
  watch: {
    compareMonth () {
      this.fetchStrMonhtly();
      this.fetchStrT12();
    },
    compareYear () {
      this.fetchStrMonhtly();
      this.fetchStrT12();
    }
  },
  methods: {
    pivoteT12Data (data) {
      data.forEach(item => {
        let my_t12_revenue = 0;
        let comp_set_t12_revenue = 0;
        
        if (item.my_revpar) {
          my_t12_revenue = window.numeral(63).multiply(DateTime.daysInT12(item.year, item.month_number)).multiply(item.my_revpar).format('0');
        }
        if (item.comp_set_revpar) {
          comp_set_t12_revenue = window.numeral(63).multiply(DateTime.daysInT12(item.year, item.month_number)).multiply(item.comp_set_revpar).format('0');
        }
        item.my_t12_revenue = my_t12_revenue;
        item.comp_set_t12_revenue = comp_set_t12_revenue;
        item.revenue_difference = window.numeral(my_t12_revenue).subtract(comp_set_t12_revenue).format('0');
      });

      this.strT12Items = data;
    },
    pivoteMonthlyData (data) {
      if (!data) {
        return;
      }

      const yearlyRevenue = {};
      const yearlyData = {};

      data.forEach(item => {
        let my_revenue = 0;
        let comp_set_revenue = 0;

        if (item.my_revpar) {
          my_revenue = window.numeral(63).multiply(DateTime.daysInMonth(item.year, item.month_number)).multiply(item.my_revpar).format('0');
        }
        if (item.my_revpar) {
          comp_set_revenue = window.numeral(63).multiply(DateTime.daysInMonth(item.year, item.month_number)).multiply(item.comp_set_revpar).format('0');
        }
        item.my_revenue = my_revenue;
        item.comp_set_revenue = comp_set_revenue;
        item.revenue_difference = window.numeral(my_revenue).subtract(comp_set_revenue).format('0');

        if (!yearlyRevenue[item.year]) {
          yearlyRevenue[item.year] = 0;
        }
        yearlyRevenue[item.year] = window.numeral(yearlyRevenue[item.year] + my_revenue).format('0');
      });

      data.forEach(item => {
        if (!yearlyData[item.year]) {
          yearlyData[item.year] = {};
        }
        yearlyData[item.year][item.month_number] = {
          month: item.month_number,
          my_revenue: item.my_revenue
        };
      });
      Object.keys(yearlyData).forEach(year => {
        Object.keys(yearlyData[year]).forEach(month => {
          let ytd = 0;
          let count = 1;
          while(count <= month) {
            if (yearlyData[year][count]) {
              ytd = ytd + parseInt(yearlyData[year][count].my_revenue);
            }
            count++;
          }
          yearlyData[year][month].ytd = ytd;
        });
      });

      data.forEach(item => {
        item.my_ytd_revenue = yearlyData[item.year][item.month_number].ytd;
      });

      this.strMonthlyItems = data;
      this.yearlyRevenue = yearlyRevenue;
    },
    fetchStrMonhtly () {
      this.loading = true;
      this.errorMsg = null;
      let params = {};

      if (this.compareMonth) {
        params.month = this.compareMonth;
      }
      else if (this.compareYear) {
        params.year = this.compareYear;
      }

      this.$http.get('/str/monthly/', {params})
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        } else {
          if (response.data.items) {
            this.pivoteMonthlyData(response.data.items);
          }
          else {
            this.errorMsg = 'STR does not exist!';
          }
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    fetchStrT12 () {
      this.loading = true;
      this.errorMsg = null;
      let params = {};

      if (this.compareMonth) {
        params.month = this.compareMonth;
      }
      else if (this.compareYear) {
        params.year = this.compareYear;
      }

      this.$http.get('/str/t12/', {params})
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        } else {
          if (response.data.items) {
            this.pivoteT12Data(response.data.items);
          }
          else {
            this.errorMsg = 'STR does not exist!';
          }
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchStrMonhtly();
    this.fetchStrT12();
  },
  template: `
    <div class="admin-str-monthly">
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li><router-link :to="{name: 'strMonthly'}" class="item">Str Monthly</router-link></li>
        </ul>
        <ul class="component-options">
          <li><router-link :to="{name: 'strDaily'}" class="item">Daily</router-link></li>
          <li><router-link :to="{name: 'strWeekly'}" class="item">Weekly</router-link></li>
          <li><router-link :to="{name: 'strMonthly', params: {monthlyOrT12: 'monthly'}}" class="item">Monthly</router-link></li>
          <li><router-link :to="{name: 'strMonthly', params: {monthlyOrT12: 't12'}}" class="item">T12</router-link></li>
      </ul>
      </div>
      <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
      <div class="error" v-if="errorMsg">{{errorMsg}}</div>
      <div class="component-body">
        <h3 class="component-title">T12 Revenue from STR</h3>
        <table-grid
          v-if="strT12Items.length"
          class="table-block"
          :data="strT12Items"
          defaultSortColumn="year_monthnumber"
          defaultSortOrder="-1"
          :columns="t12GridColumns">

          <template slot="top-thead">
            <tr>
              <th></th>
              <th></th>
              <th colspan="2">Occupancy %</th>
              <th colspan="2">ADR $</th>
              <th colspan="2">RevPar $</th>
              <th colspan="3">Revenue $</th>
            </tr>
          </template>
          <template slot="rows" slot-scope="row">
          
            <td :class="[row.classNames]"><nobr>
              <router-link :to="{name: 'strMonthlyRevenue', query: {compareMonth: row.month}}" class="item">{{row.month}}</router-link>
              <router-link :to="{name: 'strMonthlyRevenue', query: {compareYear: row.year}}" class="item">{{row.year}}</router-link>
            </nobr></td>
            <td>{{row.year_monthnumber}}</td>
            <td class="currency border-left">{{row.my_occupancy_rounded}}</td>
            <td class="currency">{{row.comp_set_occupancy_rounded}}</td>
            <td class="currency border-left">{{row.my_adr_rounded}}</td>
            <td class="currency">{{row.comp_set_adr_rounded}}</td>
            <td class="currency border-left">{{row.my_revpar_rounded}}</td>
            <td class="currency">{{row.comp_set_revpar_rounded}}</td>
            <td class="currency border-left">{{row.my_t12_revenue}}</td>
            <td class="currency">{{row.comp_set_t12_revenue}}</td>
            <td class="currency">{{row.revenue_difference}}</td>
          </template>
        </table-grid>
      </div>
      <div class="component-body">
        <h3 class="component-title">Monthly Revenue from STR</h3>
        <table-grid
          v-if="strMonthlyItems.length"
          class="table-block"
          :data="strMonthlyItems"
          defaultSortColumn="year_monthnumber"
          defaultSortOrder="-1"
          :columns="monthlyGridColumns">

          <template slot="top-thead">
            <tr>
              <th></th>
              <th></th>
              <th colspan="2">Occupancy %</th>
              <th colspan="2">ADR $</th>
              <th colspan="2">RevPar $</th>
              <th colspan="4">Revenue $</th>
            </tr>
          </template>
          <template slot="rows" slot-scope="row">
            <td :class="[row.classNames]"><nobr>
              <router-link :to="{name: 'strMonthlyRevenue', query: {compareMonth: row.month}}" class="item">{{row.month}}</router-link>
              <router-link :to="{name: 'strMonthlyRevenue', query: {compareYear: row.year}}" class="item">{{row.year}}</router-link>
            </nobr></td>
            <td>{{row.year_monthnumber}}</td>
            <td class="currency border-left">{{row.my_occupancy_rounded}}</td>
            <td class="currency">{{row.comp_set_occupancy_rounded}}</td>
            <td class="currency border-left">{{row.my_adr_rounded}}</td>
            <td class="currency">{{row.comp_set_adr_rounded}}</td>
            <td class="currency border-left">{{row.my_revpar_rounded}}</td>
            <td class="currency">{{row.comp_set_revpar_rounded}}</td>
            <td class="currency border-left">{{row.my_revenue}}</td>
            <td class="currency">{{row.comp_set_revenue}}</td>
            <td class="currency">{{row.revenue_difference}}</td>
            <td class="currency">{{row.my_ytd_revenue}}</td>
          </template>
        </table-grid>
      </div>
    </div>
  `,
};