import AppAdmin from '../components/AppAdmin.js';
import AdminHome from '../components/AdminHome.js';
import AdminConfig from '../components/AdminConfig.js';
import AdminMe from '../components/AdminMe.js';
import Issues from '../components/Issues.js';
import AdminPayroll from '../components/AdminPayroll.js';
import AdminDailyPunches from '../components/AdminDailyPunches.js';
import AdminEmployees from '../components/AdminEmployees.js';
import AdminPPP from '../components/AdminPPP.js';
import AdminEmailPayrollReport from '../components/AdminEmailPayrollReport.js';
import AdminPayrollReport from '../components/AdminPayrollReport.js';
import AdminPayrollReports from '../components/AdminPayrollReports.js';
import AdminPayrollSummaryMonths from '../components/AdminPayrollSummaryMonths.js';
import AdminPayrollSummaryEmployees from '../components/AdminPayrollSummaryEmployees.js';
import AdminPayrollSummaryPositions from '../components/AdminPayrollSummaryPositions.js';
import AdminCreatePayrollReport from '../components/AdminCreatePayrollReport.js';
import AdminHotelEdit from '../components/AdminHotelEdit.js';
import AdminHotel from '../components/AdminHotel.js';
import AdminHotelSections from '../components/AdminHotelSections.js';
import AdminHotelRooms from '../components/AdminHotelRooms.js';
import AdminHotelRoomTypes from '../components/AdminHotelRoomTypes.js';
import AdminProducts from '../components/AdminProducts.js';
import AdminProduct from '../components/AdminProduct.js';
import AdminShop from '../components/AdminShop.js';
import AdminShopProductDetails from '../components/AdminShopProductDetails.js';
import AdminVendors from '../components/AdminVendors.js';
import AdminVendorProducts from '../components/AdminVendorProducts.js';
import AdminPurchaseOrders from '../components/AdminPurchaseOrders.js';
import AdminPurchaseOrdersGroupBy from '../components/AdminPurchaseOrdersGroupBy.js';
import AdminPurchaseOrdersByLabel from '../components/AdminPurchaseOrdersByLabel.js';
import AdminProductTags from '../components/AdminProductTags.js';
import AdminStrDaily from '../components/AdminStrDaily.js';
import AdminStrMonthly from '../components/AdminStrMonthly.js';
import AdminStrWeekly from '../components/AdminStrWeekly.js';
import AdminStrMonthlyRevenue from '../components/AdminStrMonthlyRevenue.js';
import AdminMyAccount from '../components/AdminMyAccount.js';

import Four0four from '../components/404.js';

const routes = [{
  path: '/',
  component: AppAdmin,
  children: [
    {
      name: 'home',
      path: '',
      component: AdminHome
    },
    {
      name: 'config',
      path: 'config/:action?',
      component: AdminConfig
    },
    {
      name: 'me',
      path: 'me',
      component: AdminMe
    },
    {
      name: 'employees',
      path: 'employees/:employeeId?/:action?',
      component: AdminEmployees
    },
    {
      name: 'dailyPunches',
      path: 'punches/:date',
      component: AdminDailyPunches
    },
    {
      name: 'hotelIssues',
      path: 'issues/:issueId?',
      component: Issues
    },
    {
      name: 'ppp',
      path: 'ppp',
      component: AdminPPP
    },
    {
      name: 'payroll',
      path: 'payroll/:startDate/:endDate',
      component: AdminPayroll
    },
    {
      name: 'payrollReports',
      path: 'payroll-reports',
      component: AdminPayrollReports
    },
    {
      name: 'payrollReport',
      path: 'payroll-reports/:reportType/:reportId',
      component: AdminPayrollReport
    },
    {
      name: 'emailPayrollReport',
      path: 'email-payroll-reports/:reportType/:reportId',
      component: AdminEmailPayrollReport
    },
    {
      name: 'payrollSummaryMonths',
      path: 'payroll-summary-months',
      component: AdminPayrollSummaryMonths
    },
    {
      name: 'payrollSummaryEmployees',
      path: 'payroll-summary-employees',
      component: AdminPayrollSummaryEmployees
    },
    {
      name: 'payrollSummaryPositions',
      path: 'payroll-summary-positions',
      component: AdminPayrollSummaryPositions
    },
    {
      name: 'createPayrollReport',
      path: 'create-payroll-report/:date',
      component: AdminCreatePayrollReport
    },
    {
      name: 'purchaseOrders',
      path: 'purchase-orders/:field?/:fieldValue?',
      component: AdminPurchaseOrders
    },
    {
      name: 'purchaseOrdersGroupBy',
      path: 'purchase-orders-group-by',
      component: AdminPurchaseOrdersGroupBy
    },
    {
      name: 'purchaseOrdersByLabel',
      path: 'purchase-orders-by-label',
      component: AdminPurchaseOrdersByLabel
    },
    {
      name: 'vendors',
      path: 'vendors',
      component: AdminVendors
    },
    {
      name: 'vendorProducts',
      path: 'vendors/:vendorId/',
      component: AdminVendorProducts
    },
    {
      name: 'products',
      path: 'products',
      component: AdminProducts
    },
    {
      name: 'productDetails',
      path: 'products/:productId',
      component: AdminProduct
    },
    {
      name: 'productTags',
      path: 'product-tags',
      component: AdminProductTags
    },
    {
      name: 'shop',
      path: 'shop',
      component: AdminShop
    },
    {
      name: 'shopProductsByTags',
      path: 'shop/label/:tags',
      component: AdminShop
    },
    {
      name: 'shopProductDetails',
      path: 'shop/product-item/:productId',
      component: AdminShopProductDetails
    },
    {
      name: 'hotel',
      path: 'hotel',
      component: AdminHotel
    },
    {
      name: 'hotelEdit',
      path: 'hotel/edit',
      component: AdminHotelEdit
    },
    {
      name: 'hotelSections',
      path: 'hotel/sections',
      component: AdminHotelSections
    },
    {
      name: 'hotelRooms',
      path: 'hotel/rooms',
      component: AdminHotelRooms
    },
    {
      name: 'hotelRoomTypes',
      path: 'hotel/room/types',
      component: AdminHotelRoomTypes
    },
    {
      name: 'strDaily',
      path: 'str/daily',
      component: AdminStrDaily
    },
    {
      name: 'strWeekly',
      path: 'str/weekly',
      component: AdminStrWeekly
    },
    {
      name: 'strMonthly',
      path: 'str/:monthlyOrT12',
      component: AdminStrMonthly
    },
    {
      name: 'strMonthlyRevenue',
      path: 'str/monthly/revenue',
      component: AdminStrMonthlyRevenue
    },
    {
      name: 'myAccount',
      path: 'my-account',
      component: AdminMyAccount
    },
    {
      name: 'feedback',
      path: 'feedback'
    }
  ]
},
{
  path: '/404', alias: '*', component: Four0four
}];



export default routes;
