import AdminEmployeeNotes from './AdminEmployeeNotes.js';
import DateTime from './DateTime.js';
import Helper from './Helper.js';
import Loading from './Loading.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import AdminEmployeesApi from '../apis/AdminEmployeesApi.js';
import TableGrid from './TableGrid.js';
import AdminEmployeeSalary from './AdminEmployeeSalary.js';
import AdminEmployeeAttributes from './AdminEmployeeAttributes.js';

export default {
  name: 'AdminEmployeeDetails',
  mixins: [AdminEmployeesApi],
  components: {
    AdminEmployeeAttributes,
    AdminEmployeeSalary,
    AdminEmployeeNotes,
    AdminPayrollCurrencyTd,
    Loading,
    TableGrid
  },
  props: ['employeeId', 'updateEmployee'],
  data() {
    const salariesColumns = [
      {key: 'started_on', value: 'Start Date'},
      {key: 'position', value: 'Position'},
      {key: 'is_houlry', value: 'Hourly/Salary'},
      {key: 'rate', value: 'Wages'}
    ];

    return {
      loading: false,
      salaryUpdateMsg: null,
      isNewNoteVisible: 0,
      employee: null,
      salaryToEdit: null,
      salariesColumns
    };
  },
  computed: {
    isActive () {
      return typeof this.employee.active == 'undefined' ? false : this.employee.active == 1;
    }
  },
  watch: {
    employeeId () {
      this.employee = null;
      this.salaryUpdateMsg = null;
      this.salaryToEdit = null;
      this.fetchEmployee();
    }
  },
  methods: {
    handleAddNote() {
      this.isNewNoteVisible++;
    },
    handleEmployeeSalaryUpdate (salaryUpdateMsg) {
      console.log(salaryUpdateMsg, 'handleEmployeeSalaryUpdate');
      this.fetchEmployee();
    },
    fetchEmployee () {
      this.loading = true;
      this
        .fetchUser(this.employeeId)
        .then((response) => {
          if (response.data.user) {
            this.employee = response.data.user;
          } else if (response.data.error) {
            this.errorMsg = response.data.error;
          } 
        })
        .catch((error) => {
          this.errorMsg = error.response;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.fetchEmployee();
  },
  filters: {
    ...DateTime,
    formatPhoneNumber: Helper.formatPhoneNumber
  },
  template: `
    <div class="admin-employee-details-component">
      <loading v-bind="{loading: loading}"></loading>
      <section class="component-header">
        <ul class="component-options" v-if="employee">
          <li>
            <a href="#" v-on:click.prevent="handleAddNote">Add Note</a>
          </li>
          <li>
            <router-link
              :to="{name: 'employees', params: {employeeId: employee.id, action: 'edit'}}">
              Edit Profile
            </router-link>
          </li>
          <li>
            <router-link
            :to="{name: 'employees', params: {employeeId: employee.id, action: 'punches'}}">
              Punches
            </router-link>
          </li>
          <li>
            <router-link
            :to="{name: 'employees', params: {employeeId: employee.id, action: 'paychecks'}}">
              Pay History
            </router-link>
          </li>
        </ul>
      </section>
      <section class="content" v-if="employee">
        <h3 class="name">
          {{employee.first_name}} {{employee.last_name}}
          <span class="inactive" v-if="!isActive">Inactive</span>
        </h3>
        <section class="grid-2">
          <div class="info">
            <span class="title">Hire Date</span>
            <span class="value">{{employee.hire_date | numericDate}}</span>
          </div>
          <div v-if="!isActive" class="info end-date">
            <span class="title">End Date</span>
            <span class="value">{{employee.end_date | numericDate}}</span>
          </div>
        </section>
        <section class="" v-if="isActive">
          <div class="info">
            <span class="title">Can Login?</span>
            <span class="value" v-if="employee.pin">Yes</span>
            <mark class="value" v-else>No (missing pin)</mark>
          </div>
        </section>
        <section class="" v-if="!isActive">
          <div class="info">
            <span class="title">End Reason</span>
            <span>{{employee.end_reason}}</span>
          </div>
        </section>
       
        <AdminEmployeeAttributes v-bind="{employeeId}"></AdminEmployeeAttributes>
        <hr class="line">
        <AdminEmployeeSalary v-bind="{employee, onEmployeeSalaryUpdate: handleEmployeeSalaryUpdate}" v-if="employee"></AdminEmployeeSalary>
        <hr class="line">
        <h3 class="component-sticky-title">Notes</h3>
        <admin-employee-notes v-bind="{employeeId, isNewNoteVisible}" v-if="employee"></admin-employee-notes>
      </section>
    </div>
  `
};