import Loading from './Loading.js';
import AdminConfigEditModal from './AdminConfigEditModal.js';
import AppStyle from './helpers/AppStyle.js';
import AdminBusinessEditModal from './AdminBusinessEditModal.js';

export default {
  name: `AdminConfig`,
  components: {
    Loading,
    AppStyle,
    AdminConfigEditModal,
    AdminBusinessEditModal
  },
  data () {
    return {
      loading: false,
      errorMsg: null,
      showBusinessEditModal: null,
      configItemToEdit: null,
      configItems: []
    };
  },
  computed: {
    business () {
      return this.$store.state.business;
    },
    config () {
      return this.$store.state.config;
    },
    allowEdit () {
      return this.$route.params.action == 'edit';
    }
  },
  methods: {
    handleClickAdd (name, label, type) {
      this.configItemToEdit = {
        name,
        label,
        type
      };
    },  
    handleClickEdit (name, value, label, type) {
      this.configItemToEdit = {
        name, 
        value,
        label,
        type
      };

      this.configItems.forEach(item => {
        if (item.name == name && item.value == value) {
          this.configItemToEdit.id = item.id;
        }
      });

      console.log(this.configItemToEdit.id);
    },
    clearSuccess () {
      this.success = null;
    },
    handleEditBusinessSuccess (updateMsg) {
      this.$toast.info(updateMsg);
      console.log(updateMsg)
      this.showBusinessEditModal = null;
    },
    handleEditSuccess(success) {
      this.$toast.info(success);
            
      window.setTimeout(this.clearSuccess, 2000);
      this.configItemToEdit = null;
      this.fetchConfig();
    },
    fetchConfig() {
      this.loading = true;
      this.errorMsg = null;

      this.$http.get('/config/')
          .then((response) => {
            if (response.data.config) {
              this.errorMsg = null;
              this.$store.dispatch('setConfig', response.data.config);
            }
            if (response.data.items) {
              this.errorMsg = null;
              this.configItems = response.data.items;
            }
            if (response.data.error) {
              this.errorMsg = response.data.error;
            }
          })
          .catch((error) => {
            this.errorMsg = error.toString();
          })
          .finally(() => {
            this.loading = false;
          });
    }
  },
  mounted () {
    console.log('adminConfig');
    this.fetchConfig();
  },
  template: `
    <div class="admin-config-component" v-if="business">
      <app-style>
        .admin-config-component table td {
          vertical-align: baseline;
        }
      </app-style>
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to='{name: "home"}' class="item">Home</router-link></li>
          <li><span class="item">Config</span></li>
        </ul>
      </div>
      <h3 class="component-title">{{business.name}} <a href="#" class="button button-medium" v-on:click.prevent="showBusinessEditModal = true" v-if="allowEdit">Edit</a></h3>

      <div class="component-body">
        <loading v-bind="{loading}"></loading>
        <admin-config-edit-modal v-bind="{configItem: configItemToEdit, onUpdate: handleEditSuccess}" v-if="configItemToEdit"></admin-config-edit-modal>
        <admin-business-edit-modal v-bind="{onUpdate: handleEditBusinessSuccess}" v-if="showBusinessEditModal"></admin-business-edit-modal>

        <p class="error" v-if="errorMsg">{{errorMsg}}</p>
          <table class="table" v-if="config && !errorMsg" >
            <thead>
              <tr>
                <th></th>
                <th>Config</th> 
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, value) in config">
                <td>
                  <a href="#" class="button button button-table button-medium" v-on:click.prevent="handleClickAdd(value, item.label, item.type)" v-if="allowEdit && (item.count == 100 || item.values == 0)">add</a>
                </td>
                <td>
                  {{item.label || value}}
                  <div><small class="muted">{{item.comment}}</small></div>  
                </td>
                <td>
                  <div  v-for="subItem in item.values">
                    {{subItem}} &nbsp; <a href="#" class="button button-table button-medium" v-on:click.prevent="handleClickEdit(value, subItem, item.label, item.type)" v-if="allowEdit">Edit</a>
                  </div>
                  <div>
                  </div>
                </td>
                <td>{{item.count}}</td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  `,
};