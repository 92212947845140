
import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import axios from 'axios';

import ToastPlugin from './plugins/v-toast.js';
import HttpPlugin from './plugins/Http.js';

import adminStore from './store/adminStore.js';
import routes from './routes/AdminRoutes.js';

Vue.config.productionTip = false;

require('./style/admin-common.css');
require('./style/common-our-logo.css');
require('./style/common-component.css');
require('./style/common-grid.css');
require('./style/common-buttons.css');
require('./style/common-table.css');
require('./style/common-modal.css');
require('./style/common-toast.css');
require('./style/common-dropdown.css');
require('./style/common-input.css');
require('./style/loading.css');
require('./style/list-details.css');
require('./style/admin-payroll.css');
require('./style/admin-create-payroll-report.css');
require('./style/admin-payroll-report.css');
require('./style/admin-employees.css');
require('./style/admin-product.css');


Vue.use(Vuex);
const store = new Vuex.Store(adminStore);

Vue.use(ToastPlugin);
Vue.use(HttpPlugin, {
  baseURL: window.appConfig.apiUrl + '/',
  withCredentials: true,
  headers: {'x-cors-domain': window.location.protocol + '//' + window.location.hostname},
  resHandleFunc(response) {
    if (response.data.error && response.data.error === 'invalid_session_user') {
      console.log('admin.js invalid_user');
      store.dispatch('setIsValidSession', false);
    } else if (response.data.error) {
      console.log(response.data.error);
    }
    return response;
  },
  resErrorFunc (response) {
    console.log('error', response);
    return response;
  }
});
Vue.use(VueRouter);


(function() {
  window.toggleFullScreen = function(event) {
    var element = document.documentElement;
  
    if (event instanceof HTMLElement) {
      element = event;
    }
  
    var isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;
  
    element.requestFullScreen = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || function () { return false; };
    document.cancelFullScreen = document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen || function () { return false; };
  
    isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
  };

  //used in the store
  window.api = axios.create({
    baseURL: window.appConfig.apiUrl + '/',
    withCredentials: true,
    headers: {'x-cors-domain': window.location.protocol + '//' + window.location.hostname}
  });
  
  window.api.interceptors.response.use(function (response) {
    if (response.headers['x-xsrf-token']) {
      document.cookie = 'XSRF-TOKEN=' + response.headers['x-xsrf-token'] + '; path=/';
    }
    return response;
  });
}());

const router = new VueRouter({
  routes
}); 

new Vue({
  router,
  store
}).$mount('#app')
