
import Loading from './Loading.js';
import DateTime from './DateTime.js';
import AdminEditPunchModal from './AdminEditPunchModal.js';
import DownloadCSV from './helpers/DownloadCsv.js';

let componentState = {
  isDailyViewEnable: false,
  isActualHoursVisible: true
};

export default {
  name: `AdminPayroll`,
  components: {
    Loading,
    AdminEditPunchModal
  },
  data () {
    return {
      payroll: null,
      isDailyViewEnable: componentState.isDailyViewEnable,
      isActualHoursVisible: componentState.isActualHoursVisible,
      dailyViewEmployeeId: {},
      loading: false,
      fetchPayrollTimeoutId: null,
      selectedDate: null,
      punch: null,
      housekeepersReport: {}
    }
  },
  computed: {
    payrollStartDate () {
      return this.$route.params.startDate;
    },
    payrollEndDate () {
      return this.$route.params.endDate;
    }
  },
  watch: {
    payrollStartDate () {
      this.mayFetchPayroll();
    },
    payrollEndDate () {
      this.mayFetchPayroll();
    }
  },
  methods: {
    handleEditPunch(punch, employee) {
      this.punch = {
        name: employee.first_name + ' ' + employee.last_name,
        punch_id: punch.id,
        punch_in: punch.punch_in,
        punch_out: punch.punch_out,
        user_id: punch.user_id
      };
    },
    handleAddPunch(employee) {
      this.punch = {
        name: employee.first_name + ' ' + employee.last_name,
        user_id: employee.id
      };
    },
    handlePunchUpdate() {
      this.fetchPayroll();
      this.punch = null;
    },
    handleToggleDailyView () {
      componentState.isDailyViewEnable = this.isDailyViewEnable = !this.isDailyViewEnable;
    },
    handleToggleActualHours () {
      componentState.isActualHoursVisible = this.isActualHoursVisible = !this.isActualHoursVisible;
    },
    handleDateChange (event) {
      this.fetchPayrollDates(event.target.value);
    },
    handleApprovePunch(punch) {
      console.log(punch);
      this.postApprovePunch(punch.id);
    },
    postApprovePunch(punchId) {
      this.loading = true;
      this.errorMsg = null;
      
      this.$http.post('/punches/approve', {
          punch_id: punchId
        })
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.error;
          } else {
            this.$toast.info('Punch approved.');
            this.fetchPayroll();
          }
        })
        .catch((error) => {
          this.errorMsg = error.response;
        })
        .finally(() => {
          this.loading = false;
          if (this.errorMsg) {
            this.$toast.error(this.errorMsg);
          }
        });
    },
    fetchPayrollDates (date) {
      this.$http.get('/payroll/pay_periods/' + date + '/')
        .then((response) => {
          if (response.data.pay_periods) {
            const pay_periods = response.data.pay_periods;
            this.$router.push({
              name: 'payroll',
              params: {
                startDate: pay_periods.payroll_start_date,
                endDate: pay_periods.payroll_end_date
              }
            });
          } else {
            this.errorMsg = response.data.error ? response.data.error : 'Missing Pay Periods';
          }
        });
    },
    mayFetchPayroll () {
      if (this.fetchPayrollTimeoutId) {
        window.clearTimeout(this.fetchPayrollTimeoutId);
      }
      this.fetchPayrollTimeoutId = window.setTimeout(this.fetchPayroll, 500);
      this.loading = true;
    },
    fetchPayroll () {
      this.loading = true;
      this.errorMsg = null;
      this.fetchPayrollTimeoutId = null;
      this.$http.get('/payroll/', {
        params: {
          start_date: this.payrollStartDate,
          end_date: this.payrollEndDate
        }
      })
      .then((response) => {
        if (response.data.result) {
          this.pivotPayroll(response.data.result)
        }
        else {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    pivotPayroll (items) {
      const users = [];

      items.users.forEach(user => {
        if (user.housekeepingReport) {
          user.housekeepingReport.totalMinutes = (parseInt(user.housekeepingReport.stay_ready) * 15) +
          (parseInt(user.housekeepingReport.vacant_ready) * 30)  + 
          (parseInt(user.housekeepingReport.deep_clean) * 90);

          console.log(user.housekeepingReport.first_name, user.housekeepingReport.totalMinutes)
        }
        users.push(user);
      });
      this.payroll = items;
    },
    exportCsv () {
      const jsonData = [];
      const startDate = new Date(this.payroll.payroll_start_date);
      const endDate = new Date(this.payroll.payroll_end_date);
      const dateOption = {month: 'numeric', year:'numeric', day:'2-digit'};
      const fileName = startDate.toLocaleString('en-US', dateOption) + '-' + endDate.toLocaleString('en-US', dateOption) + '.csv';

      this.payroll.users.forEach((user) => {
        const data = {
          'First Name': user.first_name,
          'Last Name': user.last_name,
        };

        if (this.isDailyViewEnable) {
          user.punches.forEach((punch) => {
            const d = Object.assign({}, data, {
              'Punch In': punch.punch_in,
              'Punch Out': punch.punch_out,
              'Actual Hours': this.$options.filters.minutesToHours(punch.actual_time_minutes),
              'Rounded Hours': this.$options.filters.minutesToHours(punch.rounded_time_minutes),
            });
            jsonData.push(d);
          });
        }
        else {
          data.Hours = this.$options.filters.minutesToHours(user.rounded_time_minutes);
          jsonData.push(data);
        }
      });

      DownloadCSV(jsonData, fileName);
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    console.log('AdminPayroll');
    this.fetchPayroll();
  },
  template: `
    <div class="admin-payroll-component">
      <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
      <div class="component-header">
        <ul class="nav">
          <li>
            <router-link :to='{name: "home"}' class="item">Home</router-link>
          </li>
          <li>
            <span class="item">Payroll</span>
          </li>
        </ul>
        <ul class="component-options">
          <li><span class="item-input">Change Date: <input type="date" class="date" v-model="selectedDate" @change="handleDateChange" /></span></li>
          <li><a href="#" v-on:click.prevent="handleToggleDailyView">
            {{isDailyViewEnable ? 'Disable': 'Enable'}} Daily View
          </a></li>
          <li><a href="#" v-on:click.prevent="handleToggleActualHours">
            {{isActualHoursVisible ? 'Show': 'Hide'}} Actual Hours
          </a></li>
          <li><a href="#" v-on:click.prevent="exportCsv">
            Export CSV
          </a></li>
        </ul>
      </div>
      <div class="sub-header">
        <h3>{{payrollStartDate | humanDate}} - {{payrollEndDate | humanDate}}</h3>
      </div>
      <admin-edit-punch-modal v-bind="{punch: punch, onComplete: handlePunchUpdate}" v-if="punch"></admin-edit-punch-modal>
      <div class="payroll" v-if="payroll">
        <div class="payroll-header strong">
          <div class="name">Name</div>
          <div v-bind:class="{'actual-hours-hidden': isActualHoursVisible}">Actual Hours</div>
          <div>Hours</div>
        </div>
        <div
          v-bind:class="{'payroll-employee': true, 'daily-view': isDailyViewEnable}"
          v-for="employee in payroll.users"
        >
          <div
            v-bind:class="{strong: isDailyViewEnable, employee: true}"
          >
            <div class="name">
              <router-link :to="{name: 'employees', params: {employeeId: employee.id}}">{{employee.first_name}} {{employee.last_name}}</router-link>
              <a href="#" v-on:click.prevent="handleAddPunch(employee)" class="edit-punch button button-small" v-if="isDailyViewEnable && employee.is_hourly != 0">+ Punch</a>
              <mark class="attendance-count" v-if="employee.attendances">{{employee.attendances.length}}</mark>
              <mark class="end-date" v-if="employee.active == 0 && employee.end_date">Last Day {{employee.end_date | numericDate}}</mark>
            </div>
            <div v-bind:class="{hours: true, 'actual-hours': true, 'actual-hours-hidden': isActualHoursVisible} ">{{employee.actual_time_minutes | minutesToHours}}</div>
            <div class="hours rounded-hours" v-if="employee.is_hourly != 0">{{employee.rounded_time_minutes | minutesToHours}}</div>
            <div class="hours rounded-hours" v-else><mark>Salary</mark></div>
          </div>
          <div class="punches" v-if="isDailyViewEnable">
            <div v-for="attendance in employee.attendances" class="attendance">
              <span></span>              
              <router-link class="date" :to="{name: 'dailyPunches', params: {date: attendance.date}}">{{attendance.date | numericDate}}</router-link>
              <span><mark>{{attendance.attendance_type}}</mark></span>
            </div>
            <div :class="{punch: true, 'still-clocked-in': !punch.punch_out, 'highlight': punch.rounded_time_minutes > 480}" v-for="punch in employee.punches" v-if="punch.punch_in" :key="punch.id">
              
              <span v-if="punch.is_approved == 1">&checkmark;</span>
              <span v-else>
                <a href="#" v-on:click.prevent="handleEditPunch(punch, employee)" class="button button-table button-small">Edit</a>
                <a href="#" v-on:click.prevent="handleApprovePunch(punch)" class="button button-table button-small">Approve</a>
              </span>
              <router-link class="date" :to="{name: 'dailyPunches', params: {date: punch.punch_in}}">{{punch.punch_in | numericDate}}</router-link>
              <div class="weekday">{{punch.punch_in | humanShortWeekDay}}</div>
              <div class="in">{{punch.punch_in | humanTime}}</div>
              <div class="out">{{punch.punch_out | humanTime}} <div class="still-clocked-in" v-if="!punch.punch_out">Still clocked in</div></div>
              <div v-bind:class="{hours: true, 'actual-hours': true, 'actual-hours-hidden': isActualHoursVisible}">{{punch.actual_time_minutes | minutesToHours}}</div>
              <div class="hours">{{punch.rounded_time_minutes | minutesToHours}}</div>
            </div>
            
            <div v-if="employee.housekeepingReport">
              Total Time: {{employee.housekeepingReport.totalMinutes | minutesToHours}} <br>
              Vacant Ready: {{employee.housekeepingReport.vacant_ready}} <br>
              Stay Ready: {{employee.housekeepingReport.stay_ready}} <br>
              Deep Clean: {{employee.housekeepingReport.deep_clean}} <br>
            {{employee.housekeepingReport}}</div>
          </div>
        </div>
        <div class="payroll-footer strong">
          <div class="total">Total</div>
          <div v-bind:class="{'actual-hours-hidden': isActualHoursVisible}">{{payroll.payroll_actual_time_minutes | minutesToHours}}</div>
          <div>{{payroll.payroll_rounded_time_minutes | minutesToHours}}</div>
        </div>
        <div class="continue-buttons">
        
          <router-link class="button" :to="{name:'createPayrollReport', params: {date: payrollStartDate}}">Next</router-link>
        </div>
      </div>
    </div>
  `,
};