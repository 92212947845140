
import FormField from './FormField.js';
let unique = 0;

export default {
  name: 'FormInput',
  components: {
    FormField
  },
  props: ['id', 'name', 'list', 'label', 'type', 'value', 'onChange', 'placeholder', 'focus', 'disabled', 'step', 'min', 'max', 'required', 'checked', 'inputClass', 'uniqueKey'],
  data () {
    return {
      ref: 'input-' + unique++
    };
  },
  methods: {
    handleChange (event) {
      if (event && event.target) {
        if (event.target.type === 'checkbox') {
          this.onChange(event.target.checked, this.name, event);
        }
        else {
          this.onChange(event.target.value, this.name, event);
        }
      }
    }
  },
  mounted () { 
    if (this.focus) {
      this.$refs[this.ref].focus();
    }
  },
  template: `
    <form-field :label="label"><input 
      :name="name" 
      :id="id" 
      :key="uniqueKey" 
      :list="list" 
      :type="type" 
      :checked="checked" 
      :class="{input: true, disabled: !!disabled, [inputClass]: true}" 
      :value="value" 
      @change="handleChange" 
      :placeholder="placeholder" 
      :ref="ref" 
      :disabled="disabled" 
      :step="step" 
      :min="min" 
      :max="max" /><slot></slot></form-field>
  `
};