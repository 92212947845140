const months = 'January, February, March, April, May, June, July, August, September, October, November, December'.split(', ');
const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const shortWeekdays3 = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const shortWeekdays2 = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
const shortWeekdays1 = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

const ISOOffset = (() => {
  const date = new Date();
  date.setHours(0); date.setMinutes(0); date.setSeconds(0); date.setMilliseconds(0);
  return 'T' + date.toISOString().split('T')[1];
})();

console.log(ISOOffset)
const isoDate = dateStr => {
  return window.dayjs(dateStr).$d;
  // if (dateStr instanceof Date) {
  //   return dateStr
  // }
  // else if (typeof dateStr === 'number') {
  //   return new Date(dateStr);
  // }  
  // else if (typeof dateStr === 'string') {
  //   if (dateStr.length === 10) {
  //     dateStr += ISOOffset;
  //   }
  //   return new Date(dateStr);
  // }
  // else {
  //   console.error(typeof dateStr, dateStr);
  //   throw new Error('dateStr is invalid', typeof dateStr, dateStr);
  // }
};

const humanDate = dateStr => {
  if (dateStr) {
    const date = isoDate(dateStr);
    return date.toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric'});
  }
};

const numericDate = dateStr => {
  if (dateStr) {
    return window.dayjs(dateStr).format('MM/DD/YYYY');
  } else {
    console.error(typeof dateStr, dateStr);
   // throw new Error('dateStr is invalid', typeof dateStr, dateStr);
  }
};

const humanTime = (dateStr) => {
  if (!dateStr) {
    return '------';
  }
  return window.dayjs(dateStr).format('hh:mm A');
};

const numericDateTime = dateStr => {
  if (dateStr) {
    return numericDate(dateStr) + ' ' + humanTime(dateStr);
  }
}
const humanDateTime = dateStr => {
  if (dateStr) {
    const date = isoDate(dateStr);
    return date.toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'});
  }
}

const humanWeekDay = (dateStr) => {
  const date = isoDate(dateStr);
  return getLongWeeday(date);
};

const humanShortWeekDay = (dateStr) => {
  const date = isoDate(dateStr);
  return getShortWeekday(date);
};

const secondsToHours = (secs) => {
    const sec_num = parseInt(secs, 10);
    let hours   = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0" + hours;}
    if (minutes < 10) {minutes = "0" + minutes;}
    if (seconds < 10) {seconds = "0" + seconds;}
    return hours + ':' + minutes;
};

const minutesToHours = (timeInMinutes) => {
  if (timeInMinutes) {
    return secondsToHours(timeInMinutes * 60);
  }
  else {
    return '00:00';
  }
};

const getMonthSlashDate = (date)  => {
  if (typeof date === 'string') {
    date = isoDate(date);
  }
  return (date.getMonth() + 1) + '/' + date.getDate();
};

const getLongWeeday = (date)  => {
  if (typeof date === 'string') {
    date = isoDate(date);
  }
  return weekdays[date.getDay()];
}

const getLongMonth = (date) => {
  if (typeof date === 'string') {
    date = isoDate(date);
  }
  return months[date.getMonth()];
}

const getShortWeekday = (date)  => {
  if (typeof date === 'string') {
    date = isoDate(date);
  }
  return weekdays[date.getDay()].substr(0, 3);
}

const getShortMonth = (date)  => {
  if (typeof date === 'string') {
    date = isoDate(date);
  }
  return months[date.getMonth()].substr(0, 3);
}

const getWeekNumber = (date) => {
  if (typeof date === 'string') {
    date = isoDate(date);
  }

  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                        - 3 + (week1.getDay() + 6) % 7) / 7);
};

const inputDate = dateStr => {
  if (!dateStr) {
    return '';
  }

  return window.dayjs(dateStr).format('YYYY-MM-DD');
}

const inputDateTime = dateStr => {
  if (!dateStr) {
    return '';
  }

  return window.dayjs(dateStr).format('YYYY-MM-DDTHH:mm');
};

const daysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
};

const daysInYear = (year) => {
  return ((year % 4 === 0 && year % 100 > 0) || year %400 == 0) ? 366 : 365;
};

const daysInT12 = (year, month) => {
  let count = 0;
  let days = 0;
  while(count < 12) {
    days += daysInMonth(year, month - count);
    count++;
  }
  return days;
};

const getCurrentMonthName = () => {
  const date = new Date();
  return months[date.getMonth()];
};

export default {
  isoDate,
  humanDate,
  numericDate,
  humanTime,
  numericDateTime,
  humanDateTime,
  humanWeekDay,
  humanShortWeekDay,
  secondsToHours,
  minutesToHours,
  getLongWeeday,
  getLongMonth,
  getShortWeekday,
  getShortMonth,
  getMonthSlashDate,
  getWeekNumber,
  inputDate,
  inputDateTime,
  daysInMonth,
  daysInYear,
  daysInT12,
  months,
  weekdays,
  shortMonths,
  shortWeekdays3,
  shortWeekdays2,
  shortWeekdays1,

  getCurrentMonthName,
  get today () {
    return new Date();
  },
  get now () {
    return new Date();
  },
  get yesterday() {
    const d = new Date();
    d.setDate(d.getDate() - 1);
    return d;
  },
  get thrity_days_ago () {
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    return d;
  },
  dateDiffInDays (date1, date2) {
    date1 = isoDate(date1);
    date2 = isoDate(date2);
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  },
  getDaysInMonth (year, month) {
    return new Date(year, month, 0).getDate();
  },
  getDaysInYear (year) {
    return ((year % 4 === 0 && year % 100 > 0) || year %400 == 0) ? 366 : 365;
  },
  getCalendarDays (year, month) {
    // Create a date object for the first day of the given month
    const firstDayOfMonth = new Date(year, month - 1, 1);
    const lastDayOfMonth = new Date(year, month, 0);
  
    // Get the day of the week for the first and last day of the given month
    const firstWeekDay = firstDayOfMonth.getDay();
    const lastWeekDay = lastDayOfMonth.getDay();
  
    // Calculate the start and end dates for the calendar
    const startDate = new Date(firstDayOfMonth);
    startDate.setDate(startDate.getDate() - firstWeekDay);
  
    const endDate = new Date(lastDayOfMonth);
    endDate.setDate(endDate.getDate() + (6 - lastWeekDay));
  
    // Generate the calendar days
    const calendarDays = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      calendarDays.push(window.dayjs(currentDate).format('YYYY-MM-DD'));
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return calendarDays;
  },
  getSurroundingWeeks(referenceDate) {
    // Calculate the start of the current week
    const currentWeekStart = isoDate(referenceDate);
    currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay());
  
    // Generate the dates for the previous week, current week, and next week
    const previousWeek = [];
    const currentWeek = [];
    const nextWeek = [];
  
    for (let i = -7; i < 0; i++) {
      const date = new Date(currentWeekStart);
      date.setDate(currentWeekStart.getDate() + i);
      previousWeek.push(window.dayjs(date).format('YYYY-MM-DD'));
    }
  
    for (let i = 0; i < 7; i++) {
      const date = new Date(currentWeekStart);
      date.setDate(currentWeekStart.getDate() + i);
      currentWeek.push(window.dayjs(date).format('YYYY-MM-DD'));
    }
  
    for (let i = 7; i < 14; i++) {
      const date = new Date(currentWeekStart);
      date.setDate(currentWeekStart.getDate() + i);
      nextWeek.push(window.dayjs(date).format('YYYY-MM-DD'));
    }
  
    // Combine all weeks into one array
    const allDays = [...previousWeek, ...currentWeek, ...nextWeek];
    return allDays;
  },
  getWeekDays(referenceDate, numeberOfWeeks) {
    numeberOfWeeks = numeberOfWeeks || 1;
    const currentWeekStart = isoDate(referenceDate);
    currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay());

    const days = [];
    console.log('numeberOfWeeks', numeberOfWeeks)
    for (let i = 0; i < (7 * numeberOfWeeks); i++) {
      const date = new Date(currentWeekStart);
      date.setDate(currentWeekStart.getDate() + i);
      days.push(window.dayjs(date).format('YYYY-MM-DD'));
    }
    return days;
  },
  getFourWeeks(referenceDate) {
    // Calculate the start of the current week
    const currentWeekStart = isoDate(referenceDate);
    currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay());
  
    // Generate the dates for the previous week, current week, and next week
    const previousWeek = [];
    const currentWeek = [];
    const nextWeek = [];
  
    for (let i = -7; i < 0; i++) {
      const date = new Date(currentWeekStart);
      date.setDate(currentWeekStart.getDate() + i);
      previousWeek.push(window.dayjs(date).format('YYYY-MM-DD'));
    }
  
    for (let i = 0; i < 7; i++) {
      const date = new Date(currentWeekStart);
      date.setDate(currentWeekStart.getDate() + i);
      currentWeek.push(window.dayjs(date).format('YYYY-MM-DD'));
    }
  
    for (let i = 7; i < 21; i++) {
      const date = new Date(currentWeekStart);
      date.setDate(currentWeekStart.getDate() + i);
      nextWeek.push(window.dayjs(date).format('YYYY-MM-DD'));
    }
  
    // Combine all weeks into one array
    const allDays = [...previousWeek, ...currentWeek, ...nextWeek];
    return allDays;
  },
  getWeekDaysFromDates(startDate, endDate) {
    
    const currentWeekStart = isoDate(startDate);
    currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay());

    const numeberOfDays = Math.abs(window.dayjs(endDate).diff(window.dayjs(currentWeekStart), 'day'));
    const numeberOfWeeks = Math.ceil(numeberOfDays / 7);

    const days = [];
    for (let i = 0; i < (7 * numeberOfWeeks); i++) {
      const date = new Date(currentWeekStart);
      date.setDate(currentWeekStart.getDate() + i);
      days.push(window.dayjs(date).format('YYYY-MM-DD'));
    }
    return days;
  }


}
