
import Loading from './Loading.js';
import FormInput from './form/FormInput.js';

export default {
  name: `AdminBusiness`,
  components: {
    Loading,
    FormInput
  },
  props: ['onBoarding'],
  data () {
    return {
      loading: false,
      errorMsg: null,
      updatedBusiness: {},
      businessNameError: false
    };
  },
  computed: {
    business () {
      return this.$store.state.business;
    },
    user() {
      return this.$store.state.user;
    }
  },
  watch: {
    
  },
  methods: {
    handleBusinessName (value) {
      this.updatedBusiness.name = value;
    },
    handleUpdateBusiness () {
      if (this.updatedBusiness.name) {
        this.updateBusiness();        
      } 
      else if (!this.business.name) {
        this.errorMsg = 'Business Name is required';
      }
      else {
        this.errorMsg = 'Nothing to Update!';
      }
    },
    updateBusiness () {
      this.loading = true;
    
      this.$http.post('/business/update', this.updatedBusiness)
      .then((response) => {
        if (response.data.business) {
          this.errorMsg = null;
          this.$store.dispatch('fetchConfig');
        }
        else if (response.data.error) {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    }
  },
  mounted () {
    window.setTimeout(() => {this.$refs.form.querySelector('input').focus()}, 500);

  },
  template: `
    <div class="admin-complete-signup-component">
      <div class="component-header">
        <h2 class="component-header-title">Business</h2>
      </div>
      <loading v-bind="{loading}"></loading>
      <div class="component-body onboarding-component">
        <div>
          <div v-if="onBoarding" class="padding-bottom">
            <h2>{{user.first_name}},</h2>
            <p>Welcome to the Onboarding process.</p>
            <p>Before we can get started, we need to gather some information about business & hotel.</p>
            <p>Once you have completed the onboarding process, you will be able to add your employees and start using the system.</p>
          </div>
          <h3 class="component-title" v-else>Business:</h3>
          <form v-on:submit.prevent="handleUpdateBusiness" ref="form">
            <form-input label="Legal Business Name" value="" name="name" placeholder="Business Name" :onChange="handleBusinessName" />

            <div class="error error-block" v-if="errorMsg">{{errorMsg}}</div>
            <div class="error" v-if="businessNameError">Business Name is required</div>
            <div class="row buttons padding-top">
              <button :class="{button: 1, 'button-loading': loading}">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  `,
};