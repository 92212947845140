import Modal from './Modal.js';
import Loading from './Loading.js';
import FormInput from './form/FormInput.js';

export default {
  name: 'AdminProductItemTagEditModal',
  components: {
    FormInput,
    Modal,
    Loading
  },
  props: ['item', 'onUpdate'],
  data () {
    return {
      loading: false,
      errorMsg: null,
      success: null,
      updatedItem: {}
    }
  },
  methods: {
    handleChangeItem (value) {
      console.log(value);
      this.updatedItem.tag = value.trim();
    },
    handleCancel() {
      this.onUpdate('Cancelled!');
    },
    handleItemDelete() {
      this.updatedItem.active = 0;
      this.handleItemUpdate();
    },
    handleItemUpdate () {
      if (this.item.delete) {
        this.updatedItem = {
          tag: this.item.tag,
          id: this.item.id,
          product_item_id: this.item.product_item_id,
          product_tag_id: this.item.product_tag_id
        };
      }

      if (Object.keys(this.updatedItem).length) {
        if (this.item.id && this.item.id !== 'new') {
          this.updatedItem.id = this.item.id;
        }
        this.updatedItem.product_item_id = this.item.product_item_id;
        this.saveItem();
      }
      else {
        this.onUpdate('No Change!');
      }
    },
    saveItem () {
      this.loading = true;
      this.errorMsg = null;

      this.$http.post('/product_items/tag/' + (this.item.delete ? 'delete/' : ''), this.updatedItem)
        .then((response) => {
          if (response.data.item) {
            this.errorMsg = null;
            this.$toast.info((this.updatedItem.id ? 'Updated' : 'Created') + ' product - ' + this.updatedItem.tag);
            this.onUpdate('Updated');
          }
          else if (response.data.deleted) {
            this.$toast.info('Removed Tag: ' + this.updatedItem.tag);
            this.onUpdate(true, 'Deleted!');
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  template: ` 
    <modal>
      <div class="component-header">
        <h2 class="component-header-title">
        {{item.id == 'new' ? 'Add' : 'Edit'}} Item
        </h2>
      </div>
      <div class="component-body">
        <form class="form" v-on:submit.prevent="handleItemUpdate">
          <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
          <div v-if="item.delete">Do you want to remove <strong>{{item.tag}}</strong> tag?</div>
          <form-input label="" :value="item.tag" :onChange="handleChangeItem" placeholder="Tag" v-if="!item.delete" focus="true"></form-input>
          <div class="error" v-if="errorMsg">{{errorMsg}}</div>
          <button class="button">
            {{item.id == 'new' ? 'Add' : 'Remove'}}
          </button>
          <a href="#" v-on:click.prevent="handleCancel">Cancel</a>
        </form>
      </div>
    </modal>
  `
};