import Modal from './Modal.js';
import Loading from './Loading.js';
import DateTime from './DateTime.js';
import FormInput from './form/FormInput.js';
import FormSelect from './form/FormSelect.js';

export default {
  name: 'AdminEmpoyeeAttendanceEditModal',
  components: {
    FormInput,
    FormSelect,
    Modal,
    Loading
  },
  props: ['employeeId', 'onUpdate'],
  data () {
    return {
      ATTENDANCE_TYPES: {
        CALLED_OFF: 'Called Off',
        NOCALL_NOSHOW: 'No Call No Show'
      },
      attendance: {date: DateTime.inputDate(+new Date()), user_id: this.employeeId},
      loading: false,
      errorMsg: null
    };
  },
  computed: {
  },
  methods: {
    handleChangeType (attendance_type) {
      console.log(attendance_type);
      this.attendance.attendance_type = attendance_type;
    },
    handleChangeDate (date) {
      console.log(date);
      this.attendance.date = date;
    },
    handleCancel () {
      this.attendance = {};
      this.success = null;
      this.onUpdate();
    },
    handleAdd() {
      if (!this.attendance.user_id) {
        this.errorMsg = 'Select User!';
      }
      else if (!this.attendance.attendance_type) {
        this.errorMsg = 'Select Reason!';
      }
      else if (!this.attendance.date) {
        this.errorMsg = 'Select Date!';
      }
      else {
        this.saveItem();
      }
    },
    saveItem () {
      this.loading = true;
      this.errorMsg = null;
      this.$http.post('/user_attendance/', this.attendance)
        .then((response) => {
          console.log(response.data);
          if (response.data.attendance) {
            this.errorMsg = null;
            this.onUpdate('Marked as ' + this.attendance.attendance_type + '!');
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {
    
  },
  template: ` 
      <modal :onClose="handleCancel">
        <div class="component-header">
          <h2 class="component-header-title">
            Add Absent
          </h2>
        </div>
        <div class="component-body">
          <form class="form" v-on:submit.prevent="handleAdd">
            <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
            <form-select label="Reason" :onChange="handleChangeType" :options="ATTENDANCE_TYPES"></form-select>
            <form-input label="Absent Date" type="date" :value="attendance.date" :onChange="handleChangeDate"></form-input>
            <div class="error" v-if="errorMsg">{{errorMsg}}</div>
            <button class="button">
              Save
            </button>
            <a href="#" v-on:click.prevent="handleCancel">Cancel</a>
          </form>
        </div>
    </modal>
  `
};