
import FormField from './FormField.js';
let unique = 0;

export default {
  name: 'FormTextarea',
  components: {
    FormField
  },
  props: ['label', 'name', 'value', 'onChange', 'placeholder', 'focus', 'disabled'],
  data () {
    return {
      ref: 'textarea-' + unique++
    };
  },
  methods: {
    handleChange (event) {
      this.onChange(event.target.value, this.name, event);
    }
  },
  mounted () { 
    if (this.focus) {
      this.$refs[this.ref].focus();
    }
  },
  template: `
    <form-field :label="label"><textarea :name="name" :class="{textarea: true, disabled: !!disabled}" @change="handleChange" :placeholder="placeholder" :ref="ref" :disabled="disabled">{{value}}</textarea></form-field>
  `
};