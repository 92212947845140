import Modal from './Modal.js';
import Loading from './Loading.js';
import FormInput from './form/FormInput.js';

export default {
  name: 'AdminEmployeeAttributesEditModal',
  components: {
    FormInput,
    Modal,
    Loading
  },
  props: ['attribute', 'onUpdate'],
  data () {
    return {
      loading: false,
      errorMsg: null,
      success: null,
      updatedAttribute: {
      }
    }
  },
  methods: {
    handleChangeConfigValue (value) {
      this.updatedAttribute.value = value.trim();
    },
    handleCancel() {
      this.onUpdate('Cancelled Edit!');
    },
    handleDelete() {
      if (this.attribute.id) {
        const con = confirm('Are you sure you want to delete?');
        if (con) {  
          this.updatedAttribute.id = this.attribute.id;
          this.updatedAttribute.name = this.attribute.name;
          this.deleteAttribute();
        }
      }
    },
    handleUpdate () {
      console.log(this.updatedAttribute.value, this.attribute.value, this.updatedAttribute.value != this.attribute.value)
      
      if (this.updatedAttribute.value && this.updatedAttribute.value != this.attribute.value) {
        if (this.attribute.id) {
          this.updatedAttribute.id = this.attribute.id;
        }
        if (this.attribute.name) {
          this.updatedAttribute.name = this.attribute.name;
        }
        if (this.attribute.user_id) {
          this.updatedAttribute.user_id = this.attribute.user_id;
        }
        this.saveAttribute();
      }
    },
    saveAttribute() {
      this.loading = true;
      this.errorMsg = null;

      this.$http.post('/user_attributes/', this.updatedAttribute)
        .then((response) => {
          if (response.data.item) {
            this.errorMsg = null;
            this.onUpdate('Updated Employee ' + this.updatedAttribute.name );
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteAttribute() {
      this.loading = true;
      this.errorMsg = null;

      this.$http.post('/user_attributes/delete', this.updatedAttribute)
        .then((response) => {
          if (response.data.item) {
            this.errorMsg = null;
            this.onUpdate('Deleted Employee ' + this.updatedAttribute.name );
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {
    console.log(this.attribute)
  },
  template: ` 
    <modal :onClose="handleCancel">
      <div class="component-header">
        <h2 class="component-header-title">
        {{attribute.id == 'new' ? 'Add' : 'Edit'}} Config {{attribute.label || attribute.name}}
        </h2>
      </div>
      <div class="component-body">
        <form class="form" v-on:submit.prevent="handleUpdate">
          <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
          <form-input :label="attribute.label || attribute.name" :value="attribute.value" :onChange="handleChangeConfigValue" :type="attribute.type || 'text'" focus="true"></form-input>
          <div class="error" v-if="errorMsg">{{errorMsg}}</div>
          <div class="row padding-top">
            <button class="button">
              {{attribute.id == 'new' ? 'Add' : 'Update'}}
            </button>
            <a class="button button-transparent" href="#" v-on:click.prevent="handleDelete" v-if="attribute.id">delete</a>
            <a class="button button-transparent" href="#" v-on:click.prevent="handleCancel">Cancel</a>
          </div>
        </form>
      </div>
    </modal>
  `
};