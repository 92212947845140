import Modal from './Modal.js';
import Loading from './Loading.js';
import FormInput from './form/FormInput.js';
import FormSelect from './form/FormSelect.js';

export default {
  name: 'AdminEmployeeEditSalaryModal',
  components: {
    FormInput,
    FormSelect,
    Modal,
    Loading
  },
  props: ['salaryDetails', 'onUpdate'],
  data () {
    let updatedSalaryDetails = {
      user_id: this.salaryDetails.user_id
    };

    if (!this.salaryDetails.id) {
      updatedSalaryDetails.is_hourly = this.salaryDetails.is_hourly;
      updatedSalaryDetails.started_on = this.salaryDetails.started_on;

      if (this.salaryDetails.global_position_id) {
        updatedSalaryDetails.global_position_id = this.salaryDetails.global_position_id;
      }
    } 
    else {
      updatedSalaryDetails.id = this.salaryDetails.id;
    }

    const isPositionActive = true;
    const isAttedanceBonusActive = this.salaryDetails.id && this.salaryDetails.is_hourly == 1 ? true : false;
    const isWeekendPremiumActive = this.salaryDetails.id && this.salaryDetails.is_hourly == 1 ? true : false;

    return {
      loading: false,
      errorMsg: null,
      success: null,
      updatedSalaryDetails,
      inputDisable: this.salaryDetails.id ? true: false,
      isHourly: updatedSalaryDetails.is_hourly, // updatedSalaryDetails.is_hourly is not reactive
      salaryTypeOptions: {
        '0': 'Salary (Per Paycheck)',
        '1': 'Hourly Rate'
      },
      positionOptions: null,
      step: this.salaryDetails.id ? 'confirm' : 1,
      isPositionActive,
      isAttedanceBonusActive,
      isWeekendPremiumActive,
      editing: this.salaryDetails.id ? true : false
    }
  },
  computed: {
    salaryLabel () {
      let labels = ['Salary ($)', 'Hourly ($)'];
      let label = labels[1]
      if (typeof this.isHourly == 'undefined') {
        label = labels[this.salaryDetails.is_hourly];
      } else {
        label = labels[this.isHourly];
      }
      return label;
    }
  },
  methods: {
    handleChangeSalaryType (value) {
      this.updatedSalaryDetails.is_hourly = this.isHourly = value.trim();
    },
    handleChangePosition (value) {
      this.updatedSalaryDetails.global_position_id = value.trim() ;
    },
    handleInputChange (value, name) {
      this.updatedSalaryDetails[name] = value.trim();
      console.log(name, value);
    },
    handleCancel() {
      this.onUpdate('Cancelled Edit salary!');
    },
    handleUpdateSalaryDetails() {
      this.saveSalary();
    },
    pivotPositions (positions) {
      const positionOptions = {};
      positions.forEach(position => {
        positionOptions[position.id] = position.position;
      });
      this.positionOptions = positionOptions;
    },
    handleHourlyStep () {
      if (this.isPositionActive == true) {
        this.isAttedanceBonusActive = true;
        this.isPositionActive = false;
      } else if (this.isAttedanceBonusActive == true) {
        this.isWeekendPremiumActive = true;
        this.isAttedanceBonusActive = false;
      } else if (this.isWeekendPremiumActive == true) {
        this.step = 'confirm';
        this.isWeekendPremiumActive = true;
        this.isAttedanceBonusActive = true;
        this.isPositionActive = true;
      }
    },
    saveSalary () {
      this.errorMsg = null;

      if (this.updatedSalaryDetails.id == null && this.updatedSalaryDetails.global_position_id == null) {
        this.errorMsg = 'Position is required!';
      } 
      else if (this.updatedSalaryDetails.rate == null) {
        this.errorMsg = 'Rate is required!';
      }
      else if (this.isHourly == 1 && this.step != 'confirm') {
        this.handleHourlyStep();
      }
      else {
        this.postSalary();
      }
    },
    postSalary () {
      this.loading = true;
      this.errorMsg = null;

      this.$http.post('/users/salary/', this.updatedSalaryDetails)
        .then((response) => {
          if (response.data.position) {
            this.errorMsg = null;
            this.onUpdate('Added new salary!');
          }
          else {
            this.errorMsg = response.data.error || 'Request Error!';
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getAllPositions () {
      this.loading = true;
      this.errorMsg = null;

      this.$http.get('/positions/')
        .then((response) => {
          if (response.data.positions) {
            this.errorMsg = null;
            this.pivotPositions(response.data.positions);
          }
          else {
            this.errorMsg = response.data.error || '/positions Request Failed!';
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {
    this.getAllPositions();
    console.log(this.salaryDetails);
  },
  template: ` 
    <modal :onClose="handleCancel" :size="isHourly == 1 && (inputDisable || this.step == 'confirm') ? 'large': ''">
      <div class="component-header">
        <h2 class="component-header-title">
        </h2>
      </div>
      <div class="component-body">
        <loading v-bind="{loading: loading, position: 'fixed'}"></loading>

        <form :class="{'grid-3 grid-gap-1': isHourly == 1 && (inputDisable || this.step == 'confirm')}" v-on:submit.prevent="handleUpdateSalaryDetails">
          <div :class="{'active': isPositionActive, 'step padding-bottom': 1}">
            <h3>Position & Salary Details</h3>
            <form-select label="Position" :value="salaryDetails.global_position_id" :onChange="handleChangePosition" :options="positionOptions" v-if="positionOptions" focus="true" :disabled="inputDisable"></form-select>
            <form-input type="date" label="Start Date" name="started_on" :value="salaryDetails.started_on" :onChange="handleInputChange" :disabled="inputDisable"></form-input>
            <form-select label="Wages" :value="salaryDetails.is_hourly" :onChange="handleChangeSalaryType" :options="salaryTypeOptions" :disabled="inputDisable"></form-select>
            <form-input :label="salaryLabel" name="rate" :value="salaryDetails.rate" :onChange="handleInputChange" :disabled="inputDisable"></form-input>
          </div>
          
          <div :class="{'active': isAttedanceBonusActive, 'step padding-bottom': 1}">
            <h3>Attendance Bonus</h3>
            <p>For each pay period, employee will receive an hourly bonus if they have perfect attendance.</p>
            <form-input label="Bonus Rate ($)" name="bonus_rate" :value="salaryDetails.bonus_rate" :onChange="handleInputChange" :disabled="inputDisable"></form-input>
          </div>

          <div :class="{'active': isWeekendPremiumActive, 'step padding-bottom': 1}">
            <h3>Weekend Premium</h3>
            <p>Hourly premium for working on weekends.</p>
            <form-input label="Fri ($)" name="premium_friday" :value="salaryDetails.premium_friday" :onChange="handleInputChange" :disabled="inputDisable"></form-input>
            <form-input label="Sat ($)" name="premium_saturday" :value="salaryDetails.premium_saturday" :onChange="handleInputChange" :disabled="inputDisable"></form-input>
            <form-input label="Sun ($)" name="premium_sunday" :value="salaryDetails.premium_sunday" :onChange="handleInputChange" :disabled="inputDisable"></form-input>
          </div>

          <div class="error position-bottom" v-if="errorMsg">{{errorMsg}}</div>
          <div class="padding-bottom">
            <button class="button" v-if="!inputDisable">
              <span v-if="isHourly == 0 || step == 'confirm'">Confirm & Save</span>
              <span v-else>Next</span>
            </button>

            <a href="#" class="button button-cancel" v-on:click.prevent="handleCancel" v-if="this.step == 'confirm' || this.step==1">Cancel</a>
            <button class="button button-cancel" v-else>Skip</button>
          </div>
        </form>
      </div>
    </modal>
  `
};