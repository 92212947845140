import DateTime from './DateTime.js';
import TableGrid from './TableGrid.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import Loading from './Loading.js';

export default {
  name: `AdminProducts`,
  components: {
    Loading,
    TableGrid,
    AdminPayrollCurrencyTd
  },
  data () {
    const gridColumns = [
      {key: '', value: ''},
      {key: 'item', value: 'Item'},
      {key: 'make', value: 'Make'},
      {key: 'model', value: 'Model'},
      {key: 'serial_number', value: 'Serial #'},
      {key: 'purchased_on', value: 'Purchased On'},
      {key: 'qty', value: 'Quntity', type: Number},
      {key: 'location', value: 'Location'},
      {key: 'price', value: 'Cost', type: Number},
      {key: '', value: ''}
    ];

    return {
      products: null,
      loading: false,
      gridColumns,
      gridData: [],
      totalCost: 0,
      totalItems: 0,
      itemsToShow: null,
      uniqueItems: {},
    }
  },
  computed: {
  },
  watch: {
    itemsToShow () {
      this.pivotData();
    }
  },
  methods: {
    fetchProducts () {
      this.loading = true;
      this.$http.get('/products')
      .then((response) => {
        if (!response.data.error) {
          this.products = response.data.products;
          this.generateUniqueItems();
          this.pivotData();
        }
        else {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    generateUniqueItems () {
      const products = this.products;
      const uniqueItems = {};

      products.forEach(item => {
        if (!uniqueItems[item.item]) {
          uniqueItems[item.item] = 0;
        }
        uniqueItems[item.item] += parseInt(item.qty || 1, 10);
      });

      this.uniqueItems = uniqueItems;
    },
    pivotData () {
      const products = this.products;
      const gridData = [];
      let totalCost = 0;
      let totalItems = 0;

      products.forEach(item => {
        if (!this.itemsToShow || item.item === this.itemsToShow) {
          totalItems = totalItems + parseInt(item.qty, 10);
          item.total_price = window.numeral(parseFloat(item.price || 0, 10) * parseInt(item.qty, 10)).format('0.00');
          totalCost = totalCost + (parseFloat(item.price || 0, 10) * parseInt(item.qty, 10));
          gridData.push(item);
        }
      });

      this.totalItems = totalItems;
      this.totalCost = window.numeral(totalCost).format('0.00');
      this.gridData = gridData;
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchProducts();
  },
  template: `
    <div class="admin-products-component component-fixed">
      <div class="component-header">
        <h2 class="component-header-title">Products</h2>
        <ul class="component-options">
          <li><router-link :to='{name: "productDetails", params: {productId: "new"}}'>New</router-link></li>
        </ul>
      </div>
      <loading v-bind="{loading: loading}"></loading>
      <div>
        Items: {{totalItems}} <br>
        Total Cost: {{totalCost}} <br>
        Only Show: <select class="select" v-model="itemsToShow" placeholder="select item">
          <option></option>
          <option :value="key" v-for="(value, key) in uniqueItems">{{key}} ({{value}})</option>
        </select>
      </div>
      <table-grid
        class="table-block"
        :data="gridData"
        defaultSortColumn="purchased_on"
        defaultSortOrder="-1"
        :columns="gridColumns"
        v-if="gridData.length">
          <template slot="rows" slot-scope="row">
          <td></td>
            <td>
              <router-link :to='{name: "productDetails", params: {productId: row.id}}'>{{row.item}}</router-link>
            </td>
        
            <td>{{row.make}}</td>
            <td>{{row.model}}</td>
            <td>{{row.serial_number}}</td>
            <td>{{row.purchased_on | numericDate}}</td>
            <td>{{row.qty}}</td>
            <td>{{row.location}}</td>
            <AdminPayrollCurrencyTd v-bind="{value: row.price}"></AdminPayrollCurrencyTd>
            <td></td>
          </template>
      </table-grid>
    </div>
  `,
};