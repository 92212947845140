export default {
  name: `Toast`,
  props: ['type'],
  data () {
    return {
      visible: true,
      index: 0,
      items: [],
      options: {
        timeout: 5000
      }
    }
  },
  methods: {
    show (message, newOptions) {
      const options = Object.assign({}, this.options, newOptions);

      const item = {
        message: message,
        index: this.index++,
        type: options.type
      };

      this.items.push(item);
      window.setTimeout(() => {
        this.hide(item)
      }, options.timeout);
    },
    hide (item) {
      let i = this.items.indexOf(item);
      if (i >= 0) {
        this.items.splice(i, 1);
      }
      else {
        window.setTimeout(() => {
          this.visible = false;
        }, 2000);
      }
    }
  },
  template: `
    <div class="toast-component">
      <transition-group name="toast" v-if="visible">
        <div v-for="item in items" :key="item.index" :class="{'toast-item': 1, ['toast-' + item.type]: !!item.type}" :index="item.index">
          {{item.message}}
        </div>
      </transition-group>
    </div>
  `,
};