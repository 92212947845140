import DateTime from './DateTime.js';
import Loading from './Loading.js';

export default {
  name: `AdminProductNotes`,
  components: {
    Loading,
  },
  props: ['productId', 'productNotes'],
  data () {
    return {
      notes: this.productNotes,
      note: '',
      loading: false,
      errorMsg: null,
      success: null
    }
  },
  watch: {
    productNotes () {
      this.notes = Object.assign({}, this.productNotes);
    }
  },
  methods: {
    handleSave () {
      this.note = this.note.trim();
      if (this.note) {
        this.saveNote();
      }
    },
    handleCancel () {
      this.note = '';
    },
    fetchProductNotes () {
      this.success = null;
      this.errorMsg = null;
      if (this.productId) {
        console.log('fetchNotes');
        this.loading = true;
        this.$http.get('/product/notes/' + this.productId)
        .then((response) => {
          if (response.data.product) {
            this.product = response.data.product;
            this.cachedProduct = Object.assign({}, response.data.product);
          }
          else if (response.data.error) {
            this.errorMsg = response.data.error;
          }
          else {
            this.errorMsg = `Can't find the product (id ${this.productId})`;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
      }
    },
    saveNote () {
      this.loading = true;
      this.success = null;
      this.errorMsg = null;

      this.$http.post('/product/notes', {product_id: this.productId, note: this.note})
        .then((response) => {
          if (response.data.notes) {
            this.notes = response.data.notes;
            this.note = '';
          }
          else if (response.data.error) {
            this.errorMsg = response.data.error;
          }
          else {
            this.errorMsg = `Can't Add a note for product (id ${this.productId})`;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  filters: {
    ...DateTime
  },
  mounted () {
    
  },
  template: `
    <div class="admin-product-notes-component">
      <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
      <div class="component-body">
          <ul class="product-notes">
            <li class="product-note" v-for="note in notes">
              <div class="product-note-meta">{{note.first_name}} {{note.last_name}} - {{note.updated_on | numericDateTime}}</div>
              <div class="product-note-content">{{note.note}}</div>
            </li>
          </ul>
          <form class="form form-note" v-on:submit.prevent="handleSave" v-if="productId">
            <div class="row">
              <label class="label">
                <span class="label-text">
                  Note
                </span>
                <span class="input-wrapper">
                  <textarea v-model="note"></textarea>
                </span>
              </label>
            </div>
            <div class="row">
              <div class="error" v-if="errorMsg">
                {{errorMsg}}
              </div>
              <div class="success" v-if="success">
                {{success}}
              </div>
            </div>
            <div class="buttons">
              <button class="button">
                Add
                <span class="button-loading" v-if="loading"></span>
              </button>
              <a class="button button-cancel" v-on:click.prevent="handleCancel">
                Clear
              </a>
            </div>
          </form>
      </div>
    </div>
  `,
};