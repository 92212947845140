
import Loading from './Loading.js';

export default {
  name: 'issueEdit',
  components: {
    Loading
  },
  props: ['issueDetails', 'onEditComplete'],
  data () {
    return {
      errorMsg: null,
      loading: false,
      issue: Object.assign({}, this.issueDetails),
      saveInProgress: false,
      users: [],
      rooms: []
    }
  },
  computed: {
    isResolved () {
      return this.issue.resolved == 1;
    },
    possibleResolvers () {
      return this.users;
    }
  },
  watch: {
    
  },
  methods: {
    handleCancelEdit () {
      const params = {};
      
      if (this.issue.id) {
        this.issue = Object.assign({}, this.issueDetails);
        params.issueId = this.issue.id;
      }

      this.$router.push({
        name: 'hotelIssues',
        params
      });
    },
    saveIssue () {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.errorMsg = null;

      window.api.post('/issues/', this.issue)
        .then((response) => {
          if (!response.data.error) {
            this.issue = response.data.issue;
            Object.assign(this.issueDetails, this.issue);
            this.$emit('onEditComplete', this.issue);
            this.onEditComplete();
            this.$router.push({
              name: 'hotelIssues',
              params: {
                issueId: this.issue.id
              }
            });
          } else {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchUsers () {
      this.loading = true;
      window.api.get('/users/active')
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.error;
          } else {
            this.users = response.data.active_users;
          }
        })
        .catch((error) => {
          this.errorMsg = error.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchRooms () {
      this.loading = true;
      window.api.get('/rooms/')
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.error;
          } else {
            this.rooms = response.data.rooms;
          }
        })
        .catch((error) => {
          this.errorMsg = error.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRoomChange () {
      const room = this.rooms.find((room) => {
        return room.room == this.issue.room;
      });

      if (room) {
        this.issue.hotel_room_id = room.id;
      }
    }
  },
  mounted () {
    this.fetchUsers();
    this.fetchRooms();
  },
  template: `
    <div class="issue-edit-component">
    
      <loading v-bind="{loading: loading}"></loading>
      <form class="form issue-form" v-on:submit.prevent>
        <h3>{{!this.issue.id ? 'Create' : 'Edit'}} Issue</h3>

        <div class="input-fields grid-2">
          <div class="row span-2">
            <label class="label">
              <span class="label-text">
                Description
              </span>
              <span class="input-wrapper">
                <textarea v-model="issue.description"></textarea>
              </span>
            </label>
          </div>
          <div class="row">
            <label class="label">
              <span class="label-text">
                Room
              </span>
              <span class="input-wrapper">
                <input type="text" v-model="issue.room" list="rooms" @change="onRoomChange" />
                <datalist id="rooms">
                  <option v-for="room in rooms" :key="room.id">{{room.room}}  <small v-if="room.hotel_room_type">({{room.hotel_room_type}})</small></option>
                </datalist>
              </span>
            </label>
          </div>
          <div class="row">
            <label class="label">
              <span class="label-text">
                Category
              </span>
              <span class="input-wrapper">
                <input type="text" v-model="issue.category" />
              </span>
            </label>
          </div>
          <div class="row" v-if="issue.created_user">
            <label class="label">
              <span class="label-text">
                Created By
              </span>
              <span class="input-wrapper">
                {{issue.created_user.first_name}} {{issue.created_user.last_name}}
              </span>
            </label>
          </div>
          <div class="row" v-if="issue.id">
            <label class="label">
              <span class="label-text">
                Issue #
              </span>
              <span class="input-wrapper">
                {{issue.id}}
              </span>
            </label>
          </div>
          <div class="row" v-if="isResolved">
            <label class="label">
              <span class="label-text">
                Resolved By
              </span>
              <span class="input-wrapper">
                  <select v-model="issue.resolved_user_id">
                      <option value=""></option>
                      <option v-for="(user, index) in possibleResolvers" :value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
                  </select>
              </span>
            </label>
          </div>
          <div class="row" v-if="isResolved">
            <label class="label">
              <span class="label-text">
                Resolved On:
              </span>
              <span class="input-wrapper">
                {{issue.resolved_on}}
              </span>
            </label>
          </div>
        </div>

        <div class="error" v-if="errorMsg">
          {{errorMsg}}
        </div>

        <div class="buttons">
          <button class="button" v-on:click="saveIssue">
            {{issue.id ? 'Save' : 'Create'}}
          </button>
          <button class="button button-cancel" v-on:click="handleCancelEdit">
            Cancel
          </button>
        </div>
      </form>
    </div>
  `
};