import Modal from './Modal.js';
import DateTime from './DateTime.js';
import Loading from './Loading.js';
import FormInput from './form/FormInput.js';
import FormSelect from './form/FormSelect.js';

let lastPurchaseDate;

export default {
  name: 'AdminPurchaseOrderEditModal',
  components: {
    FormInput,
    FormSelect,
    Modal,
    Loading
  },
  props: ['item', 'onUpdate'],
  data () {
    const updatedItem = {};

    if (this.item.id === 'new') {
      updatedItem.total_cost = this.item.total_cost || '';
      updatedItem.order_qty = this.item.order_qty || '';

      if (this.item.order_number) {
        updatedItem.order_number = this.item.order_number;
      }

      console.log('lpd', lastPurchaseDate);
      if (!lastPurchaseDate) {
        lastPurchaseDate = DateTime.inputDate((new Date()).toISOString());
      }
      
      updatedItem.ordered_on = this.item.ordered_on || lastPurchaseDate;
    }

    return {
      loading: false,
      errorMsg: null,
      success: null,
      updatedItem,
      vendors: null,
      lastPurchaseDate
    }
  },
  computed: {
    calculatedCost () {
      return this.updatedItem.total_cost || this.item.total_cost || '';
    },
    orderOnDate () {
      console.log(this.updatedItem.ordered_on || this.item.ordered_on || lastPurchaseDate);
      return this.updatedItem.ordered_on || this.item.ordered_on || lastPurchaseDate;
    }
  },
  methods: {
    handleChangeOrderNumber (value) {
      this.updatedItem.order_number = value.trim();
    },
    handleChangeTotalCost (value) {
      this.updatedItem.total_cost = value.trim();
    },
    handleChangeOrderedOn (value) {
      this.updatedItem.ordered_on = value.trim();
    },
    handleChangeOrderQty (value) {
      this.updatedItem.order_qty = value.trim();
    },
    handleCancel() {
      this.onUpdate('Cancelled!');
    },
    handleItemDelete() {
      this.updatedItem.active = 0;
      this.handleItemUpdate();
    },
    handleItemUpdate () {
      if (Object.keys(this.updatedItem).length) {
        if (this.item.id && this.item.id !== 'new') {
          this.updatedItem.id = this.item.id;
        }
        else if (this.item.id === 'new') {
          this.updatedItem.vendor_product_item_id = this.item.vendor_product_item_id;
        }

        if (!this.updatedItem.ordered_on) {
          this.updatedItem.ordered_on = this.orderOnDate;
        }

        if (!this.updatedItem.order_qt) {
          this.updatedItem.order_qt = this.order_qt;
        }

        lastPurchaseDate = this.orderOnDate;
        console.log(JSON.stringify(this.item));
        console.log(JSON.stringify(this.updatedItem));
        this.saveItem();
      }
      else {
        this.onUpdate('No Change!');
      }
    },
    saveItem () {
      this.loading = true;
      this.errorMsg = null;
      this.$http.post('/product_orders/', this.updatedItem)
        .then((response) => {
          if (response.data.item) {
            this.errorMsg = null;
            this.onUpdate('Updated');
            this.$toast.info((this.item.id === 'new' ? 'Added' : 'Updated') + ' Purchase order: ' + this.item.product);
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  filters: {
    ...DateTime
  },
  mounted () {

  },
  template: ` 
    <modal>
      <div class="component-header">
        <h2 class="component-header-title">
        {{item.id == 'new' ? 'Add' : 'Edit'}} Item
        </h2>
      </div>
      <div class="component-body">
        <form class="form" v-on:submit.prevent="handleItemUpdate">
          <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
          <h3>{{item.product}}</h3>
          <form-input label="Vendor" :value="item.vendor" disabled="disabled"></form-input>
          <form-input label="Item" :value="item.product" disabled="disabled"></form-input>
          <form-input label="Brand" :value="item.brand" disabled="disabled"></form-input>
          <form-input label="Product ID" :value="item.vendor_product_id" disabled="disabled"></form-input>
          <form-input label="Pkg Qty" :value="item.qty" disabled="disabled"></form-input>
          <form-input label="Listed Price ($)" :value="item.price" disabled="disabled"></form-input>
          <form-input label="Purchase Date" type="date" :value="orderOnDate" :onChange="handleChangeOrderedOn"></form-input>
          <form-input label="Order Number" :value="item.order_number" :onChange="handleChangeOrderNumber"></form-input>
          <form-input label="Old Qty" :value="item.old_qty" disabled="disabled" v-if="item.id!='new'"></form-input>
          <form-input label="Order Qty" :value="item.order_qty" :onChange="handleChangeOrderQty"></form-input>
          <form-input label="Total Cost ($)" :value="calculatedCost" :onChange="handleChangeTotalCost"></form-input>
          <div v-if="item.addingFromThirdParty" class="warning"><small>
            Adding Directly from Vendor! Please Verify<br> {{item.ordered_on}}
            Ordered: {{item.order_qty}} items @ $ {{item.pricePerQty}} = total $<span>{{item.total_cost}}</span>
          </small></div>
          <div class="error" v-if="errorMsg">{{errorMsg}}</div>
          <div class="error" v-if="item.error">{{item.error}}</div>
          <button class="button" :disabled="item.error">
            {{item.id == 'new' ? 'Add' : 'Update'}}
          </button>
          <a href="#" v-on:click.prevent="handleCancel">Cancel</a>
        </form>
      </div>
    </modal>
  `
};