import DateTime from "./DateTime.js";
import Loading from "./Loading.js";

export default {
  name: 'issueDetails',
  components: {
    Loading
  },
  data () {
    return {
      users: [],
      comments: [],
      comment: '',
      loading: false,
      loadingComments: false,
      isCommentFormVisible: false,
      isResolutionFormVisible: false,
      resolvedBy: null,
      resolution: ''
    }
  },
  props: ['issueDetails'],
  computed: {
    createdOn () {
      if (this.issueDetails.created_on) {
        return this.issueDetails.created_on.substring(0, 10).trim();
      }
      return 'No Date';
    },
    isResolved () {
      return !!this.issueDetails.resolved_on;
    },
    me () {
      return this.$store.state.user;
    },
    possibleResolvers () {
      return this.users;
    },
    issueId () {
      return this.$route.params.issueId;
    }
  },
  watch: {
    issueDetails () {
      console.log('issueDetails');
      this.comments = [];
      this.fetchComments();
      this.isCommentFormVisible = false;
      this.comment = '';

      this.resolution = '';
      this.resolvedBy = null;
      this.isResolutionFormVisible = false;
    }
  },
  methods: {
    handleWriteComment() {
      this.isCommentFormVisible = true;
    },
    handleResolve () {
      this.fetchActiveUsers();
      this.isResolutionFormVisible = true;
      window.setTimeout(() => {
        this.$refs.resolution.focus();
      }, 10);
    },
    handleSaveComment() {
      this.loading = true;
      window.api.post('issues/comments', {
        comment: this.comment,
        issue_id: this.issueDetails.id
      })
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        } else {
          this.comments = response.data.comments;
          this.isCommentFormVisible = false;
          this.comment = '';
        }
      })
      .catch((error) => {
        this.errorMsg = error.response;
      })
      .finally(() => {
        this.loading = false;
      });
    },
    handleSaveResolution () {
      this.postResolution();
    },
    handleCancelComment() {
      this.comment = '';
      this.isCommentFormVisible = false;
    },
    handleCancelResolve() {
      this.resolution = '';
      this.resolvedBy = null;
      this.isResolutionFormVisible = false;
    },
    postResolution () {
      this.loading = true;

      window.api.post('/issues/resolve', {
          issue_id: this.issueDetails.id,
          resolved_user_id: this.resolvedBy,
          resolution: this.resolution
        })
        .then((response) => {
          if (!response.data.error) {
            Object.assign(this.issueDetails, response.data.issue);          
            this.comments = response.data.comments;
            this.resolution = '';
            this.resolvedBy = null;
            this.isResolutionFormVisible = false;
          } else {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchActiveUsers () {
      this.loading = true;

      window.api.get('/users/active')
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.error;
          } else {
            this.users = response.data.active_users;
          }
        })
        .catch((error) => {
          this.errorMsg = error.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchComments () {
      if (this.loadingComments) {
        return;
      }

      this.loadingComments = true;
      window.api.get('issues/' + this.issueDetails.id + '/comments')
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.error;
          } else {
            this.comments = response.data.comments;
          }
        })
        .catch((error) => {
          this.errorMsg = error.response;
        })
        .finally(() => {
          this.loadingComments = false;
        });
    },
  },
  mounted () {
    this.fetchComments();
    console.log(this.issueId, this.issueDetails);
  },
  filters: {
    ...DateTime
  },
  template: `
    <div class="issue-details-component">
      <section class="sub-header component-header">
        <ul class="component-options">
          <li><a href="#" v-on:click.prevent="handleWriteComment" v-if="!isCommentFormVisible">New Comment</a></li>
          <li v-if="!isResolved"><a href="#" v-on:click.prevent="handleResolve">Resolve</a></li>
          <li><router-link :to="{name: 'hotelIssues', params: {issueId: issueDetails.id, action: 'edit'}}" class="edit-issue">Edit</router-link></li>
        </ul>
      </section>
      <loading v-bind="{loading}"></loading>
      <section class="grid-2 content">
        <div class="detail span-2">
          <div class="type">Description</div>
          <h2 class="value">{{issueDetails.description}} <span class="resolved" v-if="isResolved">Resolved</span></h2>
        </div>
        <div class="detail">
          <div class="type">Room</div>
          <div class="value" v-if="issueDetails.room">
            <router-link :to="{name: 'hotelIssues', query: {room: issueDetails.room}}">
              {{issueDetails.room}}
            </router-link>
          </div>
        </div>
        <div class="detail">
          <div class="type">Category</div>
          <div class="value" v-if="issueDetails.category">
            <router-link :to="{name: 'hotelIssues', query: {tag: issueDetails.category}}">
              {{issueDetails.category}}
            </router-link>
          </div>
        </div>
        <div class="detail">
          <div class="type">ID</div>
          <div class="value">{{issueDetails.id}}</div>
        </div>
        <div class="detail">
          <div class="type">Last Updated</div>
          <div class="value">{{issueDetails.updated_on | numericDateTime}}</div>
        </div>
        <div class="detail">
          <div class="type">Created By</div>
          <div class="value">{{issueDetails.created_user.first_name}} {{issueDetails.created_user.last_name}}</div>
        </div>
        <div class="detail">
          <div class="type">Created On</div>
          <div class="value">{{issueDetails.created_on | numericDate}}</div>
        </div>
        <div class="detail" v-if="isResolved && issueDetails.resolved_user">
          <div class="type">Resolved By</div>
          <div class="value">{{issueDetails.resolved_user.first_name}}
              {{issueDetails.resolved_user.last_name}}
          </div>
        </div>
        <div class="detail" v-if="isResolved">
          <div class="type">Resolved On</div>
          <div class="value">
            {{issueDetails.resolved_on | numericDate}}
          </div>
        </div>
      </section>
      <ul class="list issue-options" v-if="!isCommentFormVisible && !isResolutionFormVisible">
        <li><a href="#" v-on:click.prevent="handleWriteComment" class="button">New Comment</a></li>
        <li v-if="!isResolved"><a href="#" v-on:click.prevent="handleResolve" class="button">Resolve</a></li>
      </ul>
      <section class="resolution-write">
        <form class="form form-resolution" v-if="isResolutionFormVisible">
          <div class="row">
            <label class="label">
              <span class="label-text">
                Resolved By
              </span>
              <span class="input-wrapper">
                  <select v-model="resolvedBy" placeholder="Resolver" ref="resolver">
                      <option value=""></option>
                      <option v-for="(user, index) in possibleResolvers" :value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
                  </select>
              </span>
            </label>
          </div>
          <div class="row"><textarea class="textarea" v-model="resolution" ref="resolution" maxlength="120" placeholder="How was this resolved?"></textarea></div>
          <div class="buttons">
            <button class="button" v-on:click.prevent="handleSaveResolution">Resolve</button>
            <button class="button button-cancel" v-on:click.prevent="handleCancelResolve">Cancel</button>
          </div>
        </form>
      </section>
      <section class="comment-write">
        <form class="form form-comment" v-if="isCommentFormVisible">
          <div>Comment as {{me.first_name}} {{me.last_name}}</div>
          <div><textarea class="textarea" v-model="comment" maxlength="120" placeholder="Write your comment."></textarea></div>
          <div class="buttons">
            <button class="button" v-on:click.prevent="handleSaveComment">Save</button>
            <button class="button button-cancel" v-on:click.prevent="handleCancelComment">Cancel</button>
          </div>
        </form>
      </section>
      <section class="comments">
        <loading v-bind="{loading: loadingComments, position: 'relative'}"></loading>
        <ol>
          <li v-for="comment in comments">
            <div class="resolution" v-if="comment.is_resolution">Resoltuion</div>
            <p>{{comment.comment}}</p>
            <div><span class="comment-user">{{comment.first_name}} {{comment.last_name}}</span><span class="comment-time">{{comment.created_on | numericDateTime}}</span></div>
          </li>
        </ol>
      </section>
    </div>
  `
};