import DateTime from './DateTime.js';
import Loading from './Loading.js';
import TableGrid from './TableGrid.js';

const Cell = {
  props: ['value', 'classNames', 'prefix', 'postfix'],
  template: `
    <td :class="{currency: 1, zero: value == 0, [classNames]: !!classNames}">
      <span class="muted" v-if="prefix">{{prefix}}</span>
      <span class="v">{{value}}<slot></slot></span>
      <span class="muted" v-if="postfix">{{postfix}}</span>
    </td>
  `
};
export default {
  name: `AdminStrMonthly`,
  components: {
    Loading,
    TableGrid,
    Cell
  },
  data () {
    const gridColumns = [
      {key: 'month', value: 'Month', type: String},
      {key: 'year_monthnumber', value: 'Year', type: String},
      {key: 'my_occupancy_rounded', value: 'My ', type: Number},
      {key: 'comp_set_occupancy_rounded', value: 'Comp', type: Number},
      {key: 'index_occupancy_rounded', value: 'Index %', type: Number},
      {key: 'rank_occupancy', value: 'Rank', type: Number},
      {key: 'my_adr_rounded', value: 'My $', type: Number},
      {key: 'comp_set_adr_rounded', value: 'Comp $', type: Number},
      {key: 'index_adr_rounded', value: 'Index %', type: Number},
      {key: 'rank_adr', value: 'Rank', type: Number},
      {key: 'my_revpar_rounded', value: 'My $', type: Number},
      {key: 'comp_set_revpar_rounded', value: 'Comp $', type: Number},
      {key: 'index_revpar_rounded', value: 'Index %', type: Number},
      {key: 'rank_revpar', value: 'Rank', type: Number},
    ];
    return {
      gridColumns,
      strMonthlyItems: [],
      loading: false,
      errorMsg: null,
    };
  },
  computed: {
    business () {
      return this.$store.state.business;
    },
    monthlyOrT12 () {
      return this.$route.params.monthlyOrT12;
    },
    compareMonth () {
      return this.$route.query.compareMonth;
    },
    compareYear () {
      return this.$route.query.compareYear;
    }
  },
  watch: {
    monthlyOrT12 () {
      this.strMonthlyItems = [];
      this.fetchStrReport();
    },
    compareMonth () {
      this.fetchStrReport();
    },
    compareYear () {
      this.fetchStrReport();
    }
  },
  methods: {
    handleMonthClick (date) {
      console.log(date, );
      const query = Object.assign({}, this.$route.query);
      query.compareDate = date;

      this.$router.replace({
        name: this.$route.name,
        params: this.$route.params,
        query
      });
    },
    pivoteData (data) {
      if (!data) {
        return;
      }

      data.forEach(item => {
        let classNames = [];
        if (item.month_number == 1) {
          classNames.push('border-bottom');
        }
        if (item.month_number == 12) {
          classNames.push('border-top');
        }

        item.classNames = classNames.join(' ');
      });

      this.strMonthlyItems = data;
    },
    fetchStrReport () {
      this.loading = true;
      this.errorMsg = null;
      let params = {};

      if (this.compareMonth) {
        params.month = this.compareMonth;
      }
      else if (this.compareYear) {
        params.year = this.compareYear;
      }

      this.$http.get('/str/' + this.monthlyOrT12, {params})
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        } else {
          if (response.data.items) {
            this.pivoteData(response.data.items);
          }
          else {
            this.errorMsg = 'STR does not exist!';
          }
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchStrReport();
  },
  template: `
    <div class="admin-str-monthly">
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li v-if="monthlyOrT12==='monthly'"><router-link :to="{name: 'strMonthly', params: {monthlyOrT12: 'monthly'}}" class="item">Str Monthly</router-link></li>
          <li v-else><router-link :to="{name: 'strMonthly', params: {monthlyOrT12: 't12'}}" class="item">Str T12</router-link></li>
        </ul>
        <ul class="component-options">
          <li><router-link :to="{name: 'strDaily'}" class="item">Daily</router-link></li>
          <li><router-link :to="{name: 'strWeekly'}" class="item">Weekly</router-link></li>
          <li v-if="monthlyOrT12==='t12'"><router-link :to="{name: 'strMonthly', params: {monthlyOrT12: 'monthly'}}" class="item">Monthly</router-link></li>
          <li v-else><router-link :to="{name: 'strMonthly', params: {monthlyOrT12: 't12'}}" class="item">T12</router-link></li>
          <li><router-link :to="{name: 'strMonthlyRevenue'}" class="item">Monthly Revenue</router-link></li>
        </ul>
      </div>
      <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
      <div class="error" v-if="errorMsg">{{errorMsg}}</div>
      <div class="component-body">
        <h3 class="component-title">STR <span v-if="monthlyOrT12 === 'monthly'">Monthly</span><span v-else>T12</span></h3>
        <table-grid
          v-if="strMonthlyItems.length"
          class="table-block"
          :data="strMonthlyItems"
          defaultSortColumn="year_monthnumber"
          defaultSortOrder="-1"
          :columns="gridColumns">

          <template slot="top-thead">
            <tr>
              <th></th>
              <th></th>
              <th colspan="4">Occupancy %</th>
              <th colspan="4">ADR $</th>
              <th colspan="4">RevPar $</th>
            </tr>
          </template>
          <template slot="rows" slot-scope="row">
            <td :class="[row.classNames]"><nobr>
              <router-link :to="{name: 'strMonthly', params: {monthlyOrT12: monthlyOrT12}, query: {compareMonth: row.month}}" class="item">{{row.month}}</router-link>
              <router-link :to="{name: 'strMonthly', params: {monthlyOrT12: monthlyOrT12}, query: {compareYear: row.year}}" class="item">{{row.year}}</router-link>
            </nobr></td>
            <td :class="[row.classNames]">{{row.year_monthnumber}}</td>
            <cell :class="[row.classNames, 'border-left']"  postfix="%" :value="row.my_occupancy_rounded"></cell>
            <cell :class="row.classNames" postfix="%" :value="row.comp_set_occupancy_rounded"></cell>
            <cell :class="row.classNames" postfix="%" :value="row.index_occupancy_rounded"></cell>
            <cell :class="row.classNames" :value="row.rank_occupancy"></cell>
            <cell :class="[row.classNames, 'border-left']" :value="row.my_adr_rounded"></cell>
            <cell :class="row.classNames" :value="row.comp_set_adr_rounded"></cell>
            <cell :class="row.classNames" postfix="%" :value="row.index_adr_rounded"></cell>
            <cell :class="row.classNames" :value="row.rank_adr"></cell>
            <cell :class="[row.classNames, 'border-left']" :value="row.my_revpar_rounded"></cell>
            <cell :class="row.classNames" :value="row.comp_set_revpar_rounded"></cell>
            <cell :class="row.classNames" postfix="%" :value="row.index_revpar_rounded"></cell>
            <cell :class="row.classNames" :value="row.rank_revpar"></cell>
          </template>
        </table-grid>

        {{strMonthlyItems[0]}}
      </div>
    </div>
  `,
};