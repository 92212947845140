import AdminPayrollCalculationInfo from './AdminPayrollCalculationInfo.js';
import AdminPayrollHoursTd from './AdminPayrollHoursTd.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import DateTime from './DateTime.js';
import Loading from './Loading.js';
import DownloadCSV from './helpers/DownloadCsv.js';

import DropdownDateRange from './DropdownDateRange.js';
import DropdownDateConstant from './constants/DropdownDateConstant.js';
import DateRangeHelper from './helpers/DateRangeHelper.js';

export default {
  name: `AdminPayrollSummary`,
  components: {
    AdminPayrollCalculationInfo,
    AdminPayrollHoursTd,
    AdminPayrollCurrencyTd,
    Loading,
    DropdownDateRange,
    Info: {
      template: `
        <div class="info">
          <span class="title">
            <slot name="title"></slot>
          </span>
          <span class="value"><slot></slot></span>
        </div>
      `
    }
  },
  data () {
    return {
      currentDateRange: DropdownDateConstant.LAST_12_MONTHS,
      payrollData: null,
      payrollSummary: null,
      loading: false,
      showNoteArea: false,
      note: '',
      startTime: 0,
      errorMsg: null
    }
  },
  computed: {
    dateRange () {
      const dateRange = DateRangeHelper.getDateRange(this.currentDateRange);
      return dateRange;
    },
    totals () {
      const data = this.payrollSummary;
      if (!data) {
        return;
      }
    }
  },
  watch: {
    currentDateRange () {
      this.fetchPayrollSummary();
    }
  },
  methods: {
    handleDateRangeSelect (startDate, endDate, range) {
      this.currentDateRange = range;
    },
    pivotData (data) {
      if (!data) {
        return;
      }

      let dataByPosition = {};
      let months = [];
      let positions = [];
      let monthlyTotals = {};


      data.details.forEach((item) => {
        if (typeof item.hidden === 'undefined') {
          item.hidden = false;
        }

        if (!dataByPosition[item.position]) {
          dataByPosition[item.position] = {};
          positions.push(item.position);
        }

        if (!monthlyTotals[item.yearMonth]) {
          monthlyTotals[item.yearMonth] = 0;
        }
        monthlyTotals[item.yearMonth] += parseFloat(item.total_wages);

        dataByPosition[item.position][item.yearMonth] = item.total_wages;
        
        if (months.indexOf(item.yearMonth) === -1) {
          months.push(item.yearMonth);
        }
      });

      months = months.sort();

      this.payrollSummary = {
        dataByPosition,
        months,
        positions,
        monthlyTotals,
        data
      };
    },
    fetchPayrollSummary () {
      this.loading = true;
      this.errorMsg = null;
      const requestOptions = {
        start_date: this.dateRange.startDate || null,
        end_date: this.dateRange.endDate || null
      };

      this.$http.get('/payroll/summary/monthlybyposition', {
        params: requestOptions
      })
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        } else {
          if (response.data.payroll_summary) {
            this.pivotData(response.data.payroll_summary);
          }
          else {
            this.errorMsg = 'Payroll does not exist!';
          }
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },

    exportCsv () {
      const jsonData = [];
      const startDate = new Date(this.dateRange.startDate);
      const endDate = new Date(this.dateRange.endDate);
      const fileName = 'payroll_summary_months_' + DateTime.inputDate(startDate) + '_' + DateTime.inputDate(endDate) + '.csv';

      const positions = {};
      this.payrollSummary.data.details.forEach((item) => {
        if (!item.hidden) {
          if (!positions[item.position]) {
            positions[item.position] = {
              '#': '',
              'Position': item.position
            };
          }
          positions[item.position][item.yearMonth] = item.total_wages
        }
      });

      Object.keys(positions).forEach((position) => {
        jsonData.push(positions[position]);
      });

      jsonData.push({
        '#': '',
        'Position': 'Monthly Total',
        ...this.payrollSummary.monthlyTotals
      });

      jsonData.push({
        '#': ''
      });

      jsonData.push({
        '#': 'Start Date',
        'Position': DateTime.numericDate(startDate)
      });

      jsonData.push({
        '#': 'End Date',
        'Position': DateTime.numericDate(endDate)
      });

      DownloadCSV(jsonData, fileName);
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchPayrollSummary();
  },
  template: `
    <div class="admin-payroll-report">
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li><span class="item">Payroll Summary By Positions</span></li>
        </ul>
        <ul class="component-options">
          <li><router-link :to="{name: 'payrollSummaryEmployees'}">By Emploeyees</router-link></li>
          <li><router-link :to="{name: 'payrollSummaryPositions'}">By Positions</router-link></li>
          <li><a href="#" v-on:click.prevent="exportCsv">
            Export CSV
          </a></li>
        </ul>
      </div>
      <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
      <div class="error" v-if="errorMsg">{{errorMsg}}</div>
      <div class="component-sub-header">
        <info>
          <template slot="title">Filter</template>
          <dropdown-date-range v-bind='{onSelect: handleDateRangeSelect, selectedOptionName: currentDateRange}'></dropdown-date-range>
        </info>
        <info>
          <template slot="title">Start Date</template>
          {{dateRange.startDate | humanDate}}
        </info>
        <info>
          <template slot="title">End Date</template>
          {{dateRange.endDate | humanDate}}
        </info>
      </div>
      <div class="payroll-report" v-if="payrollSummary">
        <h3 class="sub-header">By Position</h3>
        <table class="table" v-if="payrollSummary">
            <thead>
              <tr>
                <th rowspan=2>Position</th>
              </tr>
              <tr>
                <th v-for="month in payrollSummary.months">{{month}}</th>
              </tr>
          </thead>
          <tbody>
            <tr v-for="position in payrollSummary.positions">
              <td class="position">
                <router-link :to='{name: "payrollReports", query: {position: position}}'>{{position}}</router-link>
              </td>
              
              <admin-payroll-hours-td v-bind="{value: parseInt(payrollSummary.dataByPosition[position][month] || 0)}" v-for="month in payrollSummary.months" :key="month">{{payrollSummary.dataByPosition[position][month]}}</admin-payroll-hours-td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>Monthly Total</th>
              <admin-payroll-hours-td v-bind="{value: parseInt(payrollSummary.monthlyTotals[month])}" v-for="month in payrollSummary.months" :key="month"></admin-payroll-hours-td>
            </tr>
          </tfoot>
        </table>
      </div>
      <AdminPayrollCalculationInfo></AdminPayrollCalculationInfo>
    </div>
  `,
};