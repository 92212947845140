import Loading from './Loading.js';
import AdminHotelRoomTypeEditModal from './AdminHotelRoomTypeEditModal.js';

export default {
  name: 'AdminHotelRoomTypes',
  components: {
    Loading,
    AdminHotelRoomTypeEditModal
  },
  data() {
    return {
      success: null,
      loading: false,
      roomTypeToEdit: null,
      otherRoomTypes: [],
      guestRoomTypes: [],
    };
  },
  computed: {
  },
  watch: {

  },
  methods: {
    pivotRoomTypes (roomTypes) {
      const roomTypesByUsage = {guest: [], other: []};
      roomTypes.forEach(roomType => {
        const usage = roomType.usage === 'guest' ? 'guest' : 'other';
        
        roomTypesByUsage[usage].push(roomType);
      }); 
      this.otherRoomTypes = roomTypesByUsage.other || [];
      this.guestRoomTypes = roomTypesByUsage.guest || [];
    },

    handleAddRoomType (usage) {
      this.roomTypeToEdit = {
        id: 'new',
        usage
      };
    },
    handleEditRoomType (roomType) {
      this.roomTypeToEdit = roomType;
    },
    clearSuccess () {
      this.success = null;
    },
    handleEditSuccess(success) {
      this.$toast.info(success);
      this.roomTypeToEdit = null;
      this.fetchRoomTypes();
    },
    fetchRoomTypes () {
      this.loading = true;
      this.errorMsg = null;

      this.$http.get('/v0/hotel/room_types')
        .then((response) => {
          if (response.data.hotel_room_types) {
            this.errorMsg = null;
            this.$store.dispatch('setRoomTypes', response.data.hotel_room_types);
            this.pivotRoomTypes(response.data.hotel_room_types);
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {
    this.fetchRoomTypes();
  },
  template: `
    <div class="admin-hotel-room-types-component component-fixed">
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li><router-link :to="{name: 'hotel'}" class="item">Hotel</router-link></li>
          <li><span class="item">Hotel Room Types</span></li>
        </ul>
      </div>
      <loading v-bind="{loading: loading}"></loading>
      <admin-hotel-room-type-edit-modal v-bind="{roomType: roomTypeToEdit, onUpdate: handleEditSuccess}" v-if="roomTypeToEdit"></admin-hotel-room-type-edit-modal>

      <section class="component-body">
        <h3 class="component-title">Guest Room Types </h3> <a href="#" v-on:click.prevent="handleAddRoomType('guest')" class="button button-medium">+</a>
        <div class="small muted">These are the room types that guests can book.</div>
        <ul v-if="guestRoomTypes">
          <li v-for="roomType in guestRoomTypes">
            {{roomType.type}} - {{roomType.description}} <a href="#" class="button button-medium button-cancel" v-on:click.prevent="handleEditRoomType(roomType)">Edit</a>
          </li>
        </ul>
        <div class="no-sections" v-else>
          <h4>You don't have any guest room type defined! </h4>
        </div>

        <h3 class="component-title">Other Areas</h3> <a href="#" v-on:click.prevent="handleAddRoomType('other')" class="button button-medium">+</a>
        <div class="small muted">These are the rooms that are shared by all guests/employees.</div>
        <ul v-if="otherRoomTypes">
          <li v-for="roomType in otherRoomTypes">
            {{roomType.type}} - {{roomType.description}} <a href="#" class="button button-medium button-cancel" v-on:click.prevent="handleEditRoomType(roomType)">Edit</a>
          </li>
        </ul>

        <div class="no-sections" v-else>
          <h4>You don't have any other areas defined!</h4>
        </div>
        
      </section>
    </div>
  `
};