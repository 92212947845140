import AdminMe from './AdminMe.js';
import AdminBusinessEdit from './AdminBusinessEdit.js';
import AdminHotelEdit from './AdminHotelEdit.js';
import AdminOnBoardingPayroll from '../components/AdminOnBoardingPayroll.js';
import OurLogo from './helpers/OurLogo.js';
import Dropdown from './Dropdown.js';

export default {
  name: `AppAdmin`,
  components: {
    AdminMe,
    AdminBusinessEdit,
    AdminHotelEdit,
    OurLogo,
    AdminOnBoardingPayroll,
    Dropdown
  },
  data () {
    return {
      isAccountOptionsVisible: false
    }
  },
  computed: {
    onBoarding () {
      return this.isIncompleteUser || this.isIncompleteBusiness || this.isIncompleteHotel;
    },
    isIncompleteUser () {
      if (!this.user.first_name || !this.user.last_name) {
        return true;
      }
    },
    isIncompleteHotel () {
      return !this.hotel.name || this.hotel.name === '';
    },
    isIncompleteBusiness () {
      if (!this.business.name || this.business.name === 'Business Name') {
        return true;
      }
    },
    isIncompletePayroll () {
      if (this.config) {
        return this.config?.payroll_days?.values?.length === 0 || this.config?.payroll_start_date?.values?.length === 0 || this.config?.timezone?.values?.length === 0;
      }
    },
    user () {
      return this.$store.state.user;
    },
    username () {
      return this.user.email;
    },
    business () {
      return this.$store.state.business;
    },
    hotel () {
      return this.$store.state.hotel;
    },
    config() {
      return this.$store.state.config;
    },
    isValidSession () {
      return this.$store.state.isValidSession;
    }
  },
  watch: {
    
  },
  methods: {
    handleAccountOptionClick () {
      this.isAccountOptionsVisible = !this.isAccountOptionsVisible;
    },
    handleLogout () {
      this.$store.dispatch('logout');
    },
    handleLogin () {
      window.sessionStorage.setItem('afterLoginPath', window.location.href);
      this.goToLogin();
    },
    goToLogin () {
      this.$store.dispatch('goToLogin');
    }
  },
  created () {
    console.log('app-created');
    this.$store.dispatch('start');
  },
  template: `
    <div class="app-admin">
      <div class="header-container">
        <header class="header">
          
          <our-logo class="logo32" inverse="true"></our-logo>
          <h2 v-if="business">{{business.name}}</h2>
          <div class="options" v-if="user">
            <div class="header-account">
              <a class="user" href="#one" v-on:click.prevent="handleAccountOptionClick">
                {{username}}
              </a>
              <div class="account-options-layer" v-if="isAccountOptionsVisible" v-on:click.prevent="handleAccountOptionClick"></div>
              <div class="account-options" v-if="isAccountOptionsVisible" v-on:click.prevent="handleAccountOptionClick">
                <h3>{{business.name}}</h3>
                <ul>
                  <li><router-link :to="{name: 'employees', params:{employeeId: user.id}}">My Profile <mark>Admin</mark></router-link></li>
                  <li><router-link :to="{name: 'myAccount'}">Change My Password</router-link></li>  
                  <li><router-link :to="{name: 'myAccount'}" v-if="!user.email_verified">Verify Email</router-link></li>  
                  <li><router-link :to="{name: 'config'}">Business/App Config</router-link></li>
                  <li><router-link :to="{name: 'hotel'}">Hotel Config</router-link></li>
                  <li><router-link :to="{name: 'feedback'}">Feedback</router-link></li>
                  <li><a href="#" v-on:click.prevent="handleLogout">Logout</a></li>
                </ul>

                <div class="more"><a href="#">Privacey <span>Terms</span></a></div> 
              </div>
            </div>
          </div>
        </header>
      </div>
      <div class="main-container" v-if="business">
        <admin-me v-if="isIncompleteUser" onBoarding="true"></admin-me>
        <admin-business-edit v-else-if="isIncompleteBusiness" onBoarding="true"></admin-business-edit>
        <admin-hotel-edit v-else-if="isIncompleteHotel" step="signup" onBoarding="true"></admin-hotel-edit>
        <admin-on-boarding-payroll v-else-if="isIncompletePayroll" onBoarding="true"></admin-on-boarding-payroll>
        <router-view v-else></router-view>
      </div>
      <div class="invalid-session" v-if="!isValidSession">
        You are not logged in! 
        <div>Please <a href="#" v-on:click.prevent="handleLogin">login</a> to continue.</div>
      </div>
      <div class="footer-container">
        <footer class="footer">
          <our-logo class="logo32" theme="true" name="true"></our-logo>
          <div class="copyright small">&copy; Copyright 2023</div>
        </footer>
      </div>
    </div>
  `,
};