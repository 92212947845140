import DateTime from './DateTime.js';
import TableGrid from './TableGrid.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import Loading from './Loading.js';
import AdminShopVendorProductEditModal from './AdminShopVendorProductEditModal.js';
import AdminPurchaseOrderEditModal from './AdminPurchaseOrderEditModal.js';
import AdminProductItemTagEditModal from './AdminProductItemTagEditModal.js';

export default {
  name: `AdminShop`,
  components: {
    Loading,
    TableGrid,
    AdminPayrollCurrencyTd,
    AdminShopVendorProductEditModal,
    AdminPurchaseOrderEditModal,
    AdminProductItemTagEditModal,
    Tag: {
      props: ['tags'],
      computed: {
        t () {
          return this.tags.split(',');
        }
      },
      template: `
        <ul class="tags">
          <li v-for="tag in t"><router-link :to='{name: "shopProductsByTags", params: {tags: tag}}' class="tag small">{{tag}}</router-link></li>
        </ul>
      `
    },
    Info: {
      template: `
        <div class="info">
          <span class="title">
            <slot name="title"></slot>
          </span>
          <span class="value"><slot></slot></span>
        </div>
      `
    }
  },
  data () {
    const gridColumns = [
      {key: '', value: ''},
      {key: 'name', value: 'Vendor'},
      {key: 'brand', value: 'Brand'},
      {key: 'description', value: 'Description'},
      {key: 'qty', value: 'Pkg Qty', type: Number},
      {key: 'price', value: 'Price', type: Number},
      {key: 'price_per_item', value: 'Price per item'},
      {key: '', value: ''},
    ];

    return {
      products: null,
      itemDetails: null,
      loading: false,
      gridColumns,
      gridData: [],
      itemsToShow: null,
      errorMsg: null,
      productToEdit: null,
      purchaseOrderToEdit: null,
      productItemTagToEdit: null
    }
  },
  computed: {
    productId () {
      return this.$route.params.productId;
    }
  },
  watch: {
    productId () {
      this.fetchProducts();
    }
  },
  methods: {
    handleNew () {
      this.productToEdit = {
        id: 'new',
        product_item_id: this.itemDetails.id,
        product: this.itemDetails.name
      };
    },
    handleEdit (item) {
      this.productToEdit = item;
    },
    handleEditSuccess () {
      this.productToEdit = null;
      this.fetchProducts();
    },
    handlePurchaseOrderEdit (item) {
      const purchaseOrder = Object.assign({}, item);
      purchaseOrder.vendor_product_item_id = item.id;
      purchaseOrder.total_cost = item.price;
      purchaseOrder.id = 'new';
      this.purchaseOrderToEdit = purchaseOrder;
    },
    handlePurchaseOrderEditSuccess () {
      this.purchaseOrderToEdit = null;
    },
    handleProductItemTagNew () {
      this.productItemTagToEdit = {
        id: 'new',
        product_item_id: this.itemDetails.id
      };
    },
    handleProductItemTagDelete (tag) {
      this.productItemTagToEdit = {
        product_item_id: this.itemDetails.id,
        tag,
        delete: true
      };
    },
    handleProductItemTagEditSuccess () {
      this.productItemTagToEdit = null;
      this.fetchProducts();
    },
    fetchProducts () {
      this.loading = true;
      this.$http.get('/product_items/details/' + this.productId + '/') 
      .then((response) => {
        if (!response.data.error) {
          this.products = response.data.items;
          this.itemDetails = response.data.item_details;
          this.pivotData();
        }
        else {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    pivotData () {
      const products = this.products;
      const gridData = [];

      products.forEach(item => {
        if (!this.itemsToShow || item.item === this.itemsToShow) {
          item.price_per_item = window.numeral(parseFloat(item.price || 0, 10) / parseInt(item.qty, 10)).format('0.0000');
          item.purchase_url = item.url.replace('%s', item.vendor_product_id);
          gridData.push(item);
        }
      });

      this.gridData = gridData;
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchProducts();
  },
  template: `
    <div class="admin-products-component component-fixed">
      <div class="component-header">
        <ul class="nav">
          <li>
            <router-link :to='{name: "home"}' class="item">Home</router-link>
          </li>
          <li>
            <router-link :to='{name: "shop"}' class="item">Shop Products</router-link>
          </li>
          <li>
            <span class="item" v-if="itemDetails">{{itemDetails.name}}</span>
          </li>
        </ul>
        <ul class="component-options">
          <li v-if="itemDetails">
            <router-link :to="{name: 'purchaseOrders', params: {field: 'product_item_id', fieldValue: itemDetails.id}}">
              Item Purchase History
            </router-link>
          </li>
          <li><a href="#" v-on:click.prevent="handleNew">New Item</a></li>
        </ul>
      </div>
      <section class="component-sub-header" v-if="itemDetails">
        <info>
          <template slot="title">Product</template>
          {{itemDetails.name}}
        </info>
        <info>
          <template slot="title">Tags</template>
          <ul class="tags" v-if="itemDetails.tags">
            <li v-for="tag in itemDetails.tags" v-if="tag">
              <router-link :to="{name: 'shopProductsByTags', params: {tags: tag}}" class="tag">
                {{tag}}
              </router-link><a href="#" v-on:click.prevent="handleProductItemTagDelete(tag)" class="delete">x</a>
            </li>
          </ul>
          <a href="#" v-on:click.prevent="handleProductItemTagNew" class="add-tag">+</a>
        </info>
      </section>
      <loading v-bind="{loading: loading}"></loading>
      <admin-product-item-tag-edit-modal v-bind="{item: productItemTagToEdit, onUpdate: handleProductItemTagEditSuccess}" v-if="productItemTagToEdit"></admin-product-item-tag-edit-modal>
      <admin-shop-vendor-product-edit-modal v-bind="{item: productToEdit, onUpdate: handleEditSuccess}" v-if="productToEdit"></admin-shop-vendor-product-edit-modal>
      <admin-purchase-order-edit-modal v-bind="{item: purchaseOrderToEdit, onUpdate: handlePurchaseOrderEditSuccess}" v-if="purchaseOrderToEdit"></admin-purchase-order-edit-modal>
      <div class="error" v-if="errorMsg">{{errorMsg}}</div>
      
      <table-grid
        class="table-block"
        :data="gridData"
        defaultSortColumn="purchased_on"
        defaultSortOrder="-1"
        :columns="gridColumns"
        v-if="gridData.length">
          <template slot="rows" slot-scope="row">
            <td></td>
            <td>
              <router-link :to='{name: "vendorProducts", params: {vendorId: row.vendor_id}}'>{{row.vendor}}</router-link>
              {{row.vendor_product_id}}
            </td>
            <td>{{row.brand}}</td>
            <td>{{row.description}}</td>
            <td>{{row.qty}}</td>
            <AdminPayrollCurrencyTd v-bind="{value: row.price}"></AdminPayrollCurrencyTd>
            <AdminPayrollCurrencyTd v-bind="{value: row.price_per_item}"></AdminPayrollCurrencyTd>
            <td class="nowrap">
              <ul class="options-buttons">
                <li><a href="#" v-on:click.prevent="handleEdit(row)">Edit</a></li>
                <li><a :href="row.purchase_url" target="_blank">Buy</a></li>
                <li><a href="#" v-on:click.prevent="handlePurchaseOrderEdit(row)">Add PO</a></li>
              </ul>
            </td>
          </template>
      </table-grid>
    </div>
  `,
};