
import Loading from './Loading.js';
import DateTime from './DateTime.js';
import AdminPayrollHoursTd from './AdminPayrollHoursTd.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';

export default {
  name: `AdminPPP`,
  components: {
    Loading,
    AdminPayrollCurrencyTd,
    AdminPayrollHoursTd
    
  },
  data () {
    return {
      payroll: null,
      isDailyViewEnable: false,
      isActualHoursVisible: true,
      dailyViewEmployeeId: {},
      loading: false,
      punch: null,
      pppReports: []
    }
  },
  computed: {
    payrollStartDate () {
      return '2020-01-01T00:00:00-04:00';
    },
    payrollEndDate () {
      return '2020-02-29T23:59:59-04:00';
    }
  },
  watch: {
    payrollStartDate () {
      this.mayFetchPayroll();
    },
    payrollEndDate () {
      this.mayFetchPayroll();
    }
  },
  methods: {
    toggleDailyView () {
      this.isDailyViewEnable = !this.isDailyViewEnable;
    },
    fetchPPP () {
      this.loading = true;
      this.fetchPayrollTimeoutId = null;
      this.$http.get('/payroll/ppp')
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
          else {
            this.pivotReport(response.data)
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchPayroll () {
      this.loading = true;
      this.fetchPayrollTimeoutId = null;
      this.$http.get('/payroll', {
        params: {
          start_date: this.payrollStartDate,
          end_date: this.payrollEndDate
        }
      })
      .then((response) => {
        if (response.data.result) {
          this.pivotPayroll(response.data.result)
        }
        else {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    pivotReport (reports) {

      const users = {};
  
      reports.before.report.forEach(item => {
        if (!users[item.user_id]) {
          users[item.user_id] = {
            beforeTotalWages: 0,
            afterTotalWages: 0,
            beforeTotalHours: 0,
            afterTotalHours: 0,
            first_name: item.first_name,
            last_name: item.last_name,
            rate: item.rate,
            beforeAverageRate: 0,
            afterAverageRate: 0
          };
        }

        users[item.user_id].beforeTotalWages = window.numeral(parseFloat(users[item.user_id].beforeTotalWages, 10) + parseFloat(item.total_wages, 10)).format('0.00');
        users[item.user_id].beforeTotalHours += parseFloat(item.regular_hours, 10) + parseFloat(item.holiday_hours, 10) + parseFloat(item.overtime_hours, 10);
        users[item.user_id].rate = item.rate;
        
        if (item.first_name == 'Ishita' || item.first_name == 'Aarav') {
          users[item.user_id].beforeTotalHours += 60;
        }
        else if (item.first_name == 'Jay' && parseInt(item.regular_hours, 10) == 0) {
          users[item.user_id].beforeTotalHours += 80;
        }

        if (users[item.user_id].beforeTotalHours > 0) {
          users[item.user_id].beforeAverageRate = window.numeral(users[item.user_id].beforeTotalWages/users[item.user_id].beforeTotalHours).format('0.00');
        }
      });

      reports.after.report.forEach(item => {
        if (!users[item.user_id]) {
          users[item.user_id] = {
            beforeTotalWages: 0,
            afterTotalWages: 0,
            beforeTotalHours: 0,
            afterTotalHours: 0,
            first_name: item.first_name,
            last_name: item.last_name,
            rate: item.rate
          };
        }

        users[item.user_id].afterTotalWages = window.numeral(parseFloat(users[item.user_id].afterTotalWages, 10) + parseFloat(item.total_wages, 10)).format('0.00');
        users[item.user_id].afterTotalHours += parseFloat(item.regular_hours, 10) + parseFloat(item.holiday_hours, 10) + parseFloat(item.overtime_hours, 10);

        if (item.first_name == 'Ishita' || item.first_name == 'Aarav') {
          users[item.user_id].afterTotalHours += 30;
        }
        else if (item.first_name == 'Jay' && parseInt(item.regular_hours, 10) == 0) {
          users[item.user_id].afterTotalHours += 40;
        }

        users[item.user_id].rate = item.rate
        if (users[item.user_id].afterTotalHours > 0) {
          users[item.user_id].afterAverageRate = window.numeral(users[item.user_id].afterTotalWages/users[item.user_id].afterTotalHours).format('0.00');
        }
      });

      let items = [];
      let totals = {
        beforeHours: 0,
        beforeWages: 0,
        afterHours: 0,
        afterWages: 0
      };

      Object.keys(users).forEach(userId => {
        const item = users[userId];
        items.push(item);

        totals.beforeHours = window.numeral(parseFloat(totals.beforeHours, 10) + parseFloat(item.beforeTotalHours, 10)).format('0.00');
        totals.afterHours = window.numeral(parseFloat(totals.afterHours, 10) + parseFloat(item.afterTotalHours, 10)).format('0.00');
        totals.afterWages = window.numeral(parseFloat(totals.afterWages, 10) + parseFloat(item.afterTotalWages, 10)).format('0.00');
        totals.beforeWages = window.numeral(parseFloat(totals.beforeWages, 10) + parseFloat(item.beforeTotalWages, 10)).format('0.00');
      }); 


      console.table(items);

      this.pppReports = {
        items,
        totals
      };
    },
    exportCsv () {
      const jsonData = [];
      const startDate = new Date(this.payroll.payroll_start_date);
      const endDate = new Date(this.payroll.payroll_end_date);
      const dateOption = {month: 'numeric', year:'numeric', day:'2-digit'};
      const fileName = startDate.toLocaleString('en-US', dateOption) + '-' + endDate.toLocaleString('en-US', dateOption) + '.csv';

      this.payroll.users.forEach((user) => {
        const data = {
          'First Name': user.first_name,
          'Last Name': user.last_name,
        };

        if (this.isDailyViewEnable) {
          user.punches.forEach((punch) => {
            const d = Object.assign({}, data, {
              'Punch In': punch.punch_in,
              'Punch Out': punch.punch_out,
              'Actual Hours': this.$options.filters.minutesToHours(punch.actual_time_minutes),
              'Rounded Hours': this.$options.filters.minutesToHours(punch.rounded_time_minutes),
            });
            jsonData.push(d);
          });
        }
        else {
          data.Hours = this.$options.filters.minutesToHours(user.rounded_time_minutes);
          jsonData.push(data);
        }
      });

      window.downloadCSV(jsonData, fileName);
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    console.log('AdminPayroll');
    this.fetchPPP();
  },
  template: `
    <div class="admin-payroll-component">
      <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
      <div class="component-header">
        <ul class="nav">
          <li>
            <router-link :to='{name: "home"}' class="item">Home</router-link>
          </li>
          <li>
            <span class="item">Payroll</span>
          </li>
        </ul>
          <ul class="component-options">
            <li><a href="#" v-on:click.prevent="toggleDailyView">
              {{isDailyViewEnable ? 'Disable': 'Enable'}} Daily View
            </a></li>
            <li><a href="#" v-on:click.prevent="toggleActualHours">
              {{isActualHoursVisible ? 'Show': 'Hide'}} Actual Hours
            </a></li>
            <li><a href="#" v-on:click.prevent="exportCsv">
              Export CSV
            </a></li>
          </ul>
      </div>
      <div class="sub-header">
        <h3>PPP</h3>
      </div>
      <div class="payroll" v-if="pppReports">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Before Hours</th>
              <th>Before Wages</th>
              <th>Before Average Rate</th>
              <th>After Hours</th>
              <th>After Wages</th>
              <th>After Average Rate</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in pppReports.items">
              <td>
                {{item.first_name}}
              </td>
              <admin-payroll-hours-td v-bind="{value: item.beforeTotalHours}"></admin-payroll-hours-td>
              <admin-payroll-currency-td v-bind="{value: item.beforeTotalWages}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: item.beforeAverageRate}"></admin-payroll-currency-td>
              <admin-payroll-hours-td v-bind="{value: item.afterTotalHours}"></admin-payroll-hours-td>
              <admin-payroll-currency-td v-bind="{value: item.afterTotalWages}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: item.afterAverageRate}"></admin-payroll-currency-td>
            </tr>
          </tbody>
          <tfoot v-if="pppReports.totals">
            <td></td>
            <admin-payroll-hours-td v-bind="{value: pppReports.totals.beforeHours}"></admin-payroll-hours-td>
            <admin-payroll-currency-td v-bind="{value: pppReports.totals.beforeWages}"></admin-payroll-currency-td>
            <td></td>
            <admin-payroll-hours-td v-bind="{value: pppReports.totals.afterHours}"></admin-payroll-hours-td>
            <admin-payroll-currency-td v-bind="{value: pppReports.totals.afterWages}"></admin-payroll-currency-td>
            <td></td>
          </tfoot>
        </table>
      </div>
    </div>
  `,
};