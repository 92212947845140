
export default {
  name: `ListDetails`,
  template: `
    <div class="list-details-component">
      <section class="section list-container">
      <slot name="list"></slot>
      </section>
      <section class="section details-container">
      <slot name="details"></slot>
      </section>
    </div>
  `,
};