import DateTime from './DateTime.js';
import Loading from './Loading.js';
import Helper from './Helper.js';
import AdminHotelConfigEditModal from './AdminHotelConfigEditModal.js';

export default {
  name: 'AdminHotel',
  components: {
    Loading,
    AdminHotelConfigEditModal
  },
  props: ['step'],
  data() {
    return {
      loading: false,
      errorMsg: null,
      hotelConfigItems: null,
      configItemToEdit: null,
      hotelConfigOptions: {},
      success: null
    };
  },
  computed: {
    hotel () {
      return this.$store.state.hotel;
    },
    publicUrl() {
      if (this.hotel.public_name) {
        return 'https://h.innblocks.com/' + this.hotel.public_name;
      }
    }
  },
  watch: {

  },
  methods: {
    canAdd(name) {
      if (!this.hotelConfigItems) return false;
      if (!this.hotelConfigItems[name] || this.hotelConfigItems[name].items.length < this.hotelConfigOptions[name].max_count) {
        return true;
      }
    },
    handleClickAdd (name) {
      this.configItemToEdit = {
        name: name,
        hotel_id: this.hotel.id,
        g_hotel_config_id: this.hotelConfigOptions[name].id,
        label: this.hotelConfigOptions[name].label,
        type: this.hotelConfigOptions[name].type,
        description: this.hotelConfigOptions[name].description
      };
    },  
    handleClickEdit (option) {
      console.log(JSON.stringify(option));
      this.configItemToEdit = {
        id: option.id,
        name: option.g_name,
        hotel_id: this.hotel.id,
        g_hotel_config_id: option.g_hotel_config_id,
        label: option.g_label,
        type: option.g_type,
        description: option.g_description,
        value: option.value
      };
    },
    handleEditSuccess(success) {
      this.$toast.info(success);
            
      window.setTimeout(this.clearSuccess, 2000);
      this.configItemToEdit = null;
      this.fetchHotelConfig();
    },
    pivotConfig (configItems) {
      const hotelConfigItems = {};
      configItems.forEach((item) => {
        console.log(item);
        if (!hotelConfigItems[item.g_name]) {
          hotelConfigItems[item.g_name] = {
            g_name: item.g_name,
            g_label: item.g_label,
            g_description: item.g_description,
            items: []
          };
        }
        hotelConfigItems[item.g_name].items.push(item);
      });
  
      this.hotelConfigItems = hotelConfigItems;
    },
    pivotConfigOptions (configOptions) {
      const hotelConfigOptions = {};
      configOptions.forEach((item) => {
        hotelConfigOptions[item.name] = item;
      });

      this.hotelConfigOptions = hotelConfigOptions;
    },
    fetchHotel () {
      this.loading = true;
      this.$http.get('/v0/hotel/')
        .then((response) => {
          if (response.data.hotel) {
            this.errorMsg = null;
            this.$store.dispatch('setHotel', response.data.hotel);
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchHotelConfig () {
      this.loading = true;
      this.$http.get('/hotel/config')
        .then((response) => {
          if (response.data.items) {
            this.errorMsg = null;
            this.pivotConfig(response.data.items);
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchHotelConfigOptions () {
      this.loading = true;
      this.$http.get('/hotel/config/options')
        .then((response) => {
          if (response.data.hotel_config_options) {
            this.errorMsg = null;
            this.pivotConfigOptions(response.data.hotel_config_options);
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {
    this.fetchHotel();
    this.fetchHotelConfig();
    this.fetchHotelConfigOptions();
  },
  filters: {
    ...DateTime,
    formatPhoneNumber: Helper.formatPhoneNumber,
  },
  template: `
    <div class="admin-hotel-component">
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li><span class="item">Hotel</span></li>
        </ul>

        <ul class="component-options">
          <li><router-link :to="{name: 'hotelSections'}">Sections</router-link></li>
          <li><router-link :to="{name: 'hotelRoomTypes'}">Room Types</router-link></li>
          <li><router-link :to="{name: 'hotelRooms'}">Rooms</router-link></li>
          </ul>
      </div>
      <admin-hotel-config-edit-modal v-bind="{configItem: configItemToEdit, onUpdate: handleEditSuccess}" v-if="configItemToEdit"></admin-hotel-config-edit-modal>
      
      <section class="component-body">
        <loading v-bind="{loading: loading}"></loading>
        <h3 class="component-title">Hotel Details</h3> 
        <router-link :to="{name: 'hotelEdit'}">Edit</router-link>

        <table class="table" v-if="hotelConfigOptions && !errorMsg" >
          <thead>
            <tr>
              <th>Config</th> 
              <th>Value</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Hotel Name</td>
              <td>{{hotel.name}}</td>
              <td><router-link :to="{name: 'hotelEdit'}">Edit</router-link></td>
            </tr>
            <tr>
              <td>Hotel Address</td>
              <td>{{hotel.address_street}}<br>{{hotel.address_city}}, {{hotel.address_state}} {{hotel.address_zip}}</td>
              <td><router-link :to="{name: 'hotelEdit'}">Edit</router-link></td>
            </tr>
            <tr>
              <td>Website</td>
              <td>{{hotel.public_name}}<br>
              <a :href="publicUrl" target="_blank" rel="noopener" v-if="publicUrl">{{publicUrl}}</a>
              <span v-else>Public Website is not configured</span>
              </td>
              <td></td>
            </tr>
            <tr v-for="(configOption, value) in hotelConfigOptions" v-if="configOption">
              <td>
                {{configOption.label || configOption.name}} ({{configOption.name}})
                <div><small class="muted">{{configOption.description}}</small></div>  
              </td>
              <td v-if="hotelConfigItems && hotelConfigItems[configOption.name]">
                  <div v-for="item in hotelConfigItems[configOption.name].items">
                    {{item.value}} <a href="#" class="button button-table button-medium" v-on:click.prevent="handleClickEdit(item)">edit</a>
                  </div>
                  <a href="#" class="button button-table button-medium" v-on:click.prevent="handleClickAdd(configOption.name)" v-if="canAdd(configOption.name)">+</a>
              </td>
              <td v-else><a href="#" class="button button-table button-medium" v-on:click.prevent="handleClickAdd(configOption.name)" v-if="canAdd(configOption.name)">+</a></td>
              <td>{{configOption.max_count}}</td>
            </tr>
          </tbody>
        </table>

        <ul>
          <li><router-link :to="{name: 'hotelEdit'}">Edit Hotel</router-link></li>
          <li><router-link :to="{name: 'hotelSections'}">Sections</router-link></li>
          <li><router-link :to="{name: 'hotelRoomTypes'}">Room Types</router-link></li>
          <li><router-link :to="{name: 'hotelRooms'}">Rooms</router-link></li>
        </ul>
      </section>
    </div>
  `
};