import DateTime from './DateTime.js';
import TableGrid from './TableGrid.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import Loading from './Loading.js';
import AdminProductNotes from './AdminProductNotes.js';

const NEW_PRODUCT = {};

export default {
  name: `AdminProduct`,
  components: {
    Loading,
    TableGrid,
    AdminPayrollCurrencyTd,
    AdminProductNotes
  },
  data () {
    return {
      product: {},
      cachedProduct: null,
      loading: false,
      errorMsg: null,
      success: null
    }
  },
  computed: {
    productId () {
      return this.$route.params.productId;
    }
  },
  watch: {
    productId () {
      if (this.product.id !== this.productId) {
        this.fetchProduct();
      }
    }
  },
  methods: {
    handleSave () {
      this.saveProduct();
    },
    handleCancel () {
      this.$router.push({
        name: 'products'
      });
    },
    fetchProduct () {
      this.success = null;
      this.errorMsg = null;
      
      if (this.productId) {
        if (this.productId === 'new') {
          this.product = Object.assign({}, NEW_PRODUCT);
          this.cachedProduct = Object.assign({}, NEW_PRODUCT);
        }
        else {
          this.loading = true;
          this.$http.get('/products/' + this.productId)
          .then((response) => {
            if (response.data.product) {
              this.product = response.data.product;
              this.cachedProduct = Object.assign({}, response.data.product);
            }
            else if (response.data.error) {
              this.errorMsg = response.data.error;
            }
            else {
              this.errorMsg = `Can't find the product (id ${this.productId})`;
            }
          })
          .catch((error) => {
            this.errorMsg = error.toString();
          })
          .finally(() => {
            this.loading = false;
          });
        }
      }
    },
    saveProduct () {
      this.loading = true;
      this.success = null;
      this.errorMsg = null;
      
      const product = {};
      Object.keys(this.product).forEach(key => {
        console.log(this.product[key], this.cachedProduct[key]);
        if (this.product[key] != this.cachedProduct[key]) {
          product[key] = this.product[key];
        }
      });

      if (Object.keys(product).length) {
        if (this.product.id) {
          product.id = this.product.id;
        }

        this.$http.post('/products/', product)
          .then((response) => {
            if (response.data.product) {
              if (!this.product.id && response.data.product.id) {
                this.$router.replace({
                  name: 'productDetails',
                  params: {
                    productId: response.data.product.id
                  }
                });
                this.success = "Added";
              }
              else {
                this.success = "Updated";
              }
              this.product = Object.assign(this.product, response.data.product);
              this.cachedProduct = Object.assign(this.cachedProduct, response.data.product);
            }
            else if (response.data.error) {
              this.errorMsg = response.data.error;
            }
            else {
              this.errorMsg = `Can't update the product (id ${this.productId})`;
            }
          })
          .catch((error) => {
            this.errorMsg = error.toString();
          })
          .finally(() => {
            this.loading = false;
          });
      }
      else {
        this.loading = false;
        this.success = "Updated";
      }
    },
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchProduct();
  },
  template: `
    <div class="admin-product-component component-fixed">
      <div class="component-header">
        <h2 class="component-header-title"><router-link :to='{name: "products"}'>Products</router-link> :: Edit</h2>
        <ul class="component-options" v-if="product.id">
          <li><router-link :to='{name: "productDetails", params: {productId: "new"}}'>New</router-link></li>
        </ul>
      </div>
      <loading v-bind="{loading: loading}"></loading>
      <div class="component-body">
        <div class="error" v-if="!product && errorMsg">
          {{errorMsg}}
          <div class="buttons">
            <button class="button" v-on:click="handleCancel">
              Back to Products
            </button>
          </div>  
        </div>
        <form class="form" v-on:submit.prevent="handleSave" v-if="product">
          <div class="grid-2">
            <div class="row">
              <label class="label">
                <span class="label-text">
                  Item
                </span>
                <span class="input-wrapper">
                  <input type="text" v-model="product.item"/>
                </span>
              </label>
            </div>
          </div>
          <div class="grid-2">
            <div class="row">
              <label class="label">
                <span class="label-text">
                  Make
                </span>
                <span class="input-wrapper">
                  <input type="text" v-model="product.make"/>
                </span>
              </label>
            </div>
            <div class="row">
              <label class="label">
                <span class="label-text">
                  Model
                </span>
                <span class="input-wrapper">
                  <input type="text" v-model="product.model"/>
                </span>
              </label>
            </div>
            <div class="row">
              <label class="label">
                <span class="label-text">
                  Quantity
                </span>
                <span class="input-wrapper">
                  <input type="text" v-model="product.qty"/>
                </span>
              </label>
            </div>
            <div class="row">
              <label class="label">
                <span class="label-text">
                  price
                </span>
                <span class="input-wrapper">
                  <input type="text" v-model="product.price"/>
                </span>
              </label>
            </div>
            <div class="row">
              <label class="label">
                <span class="label-text">
                  Vendor
                </span>
                <span class="input-wrapper">
                  <input type="text" v-model="product.vendor"/>
                </span>
              </label>
            </div>
            <div class="row">
              <label class="label">
                <span class="label-text">
                  Serial
                </span>
                <span class="input-wrapper">
                  <input type="text" v-model="product.serial_number"/>
                </span>
              </label>
            </div>
            <div class="row">
              <label class="label">
                <span class="label-text">
                  Purchased On
                </span>
                <span class="input-wrapper">
                  <input type="date" v-model="product.purchased_on"/>
                </span>
              </label>
            </div>
            <div class="row">
              <label class="label">
                <span class="label-text">
                  Warranty Ends On
                </span>
                <span class="input-wrapper">
                  <input type="date" v-model="product.warranty_ends_on"/>
                </span>
              </label>
            </div>
            <div class="row">
              <label class="label">
                <span class="label-text">
                  Extended Warranty Vendor
                </span>
                <span class="input-wrapper">
                  <input type="text" v-model="product.extended_warranty_by"/>
                </span>
              </label>
            </div>
            <div class="row">
              <label class="label">
                <span class="label-text">
                  Exentended Warranty Ends On
                </span>
                <span class="input-wrapper">
                  <input type="date" v-model="product.extended_warranty_ends_on"/>
                </span>
              </label>
            </div>
            <div class="row">
              <label class="label">
                <span class="label-text">
                  Location
                </span>
                <span class="input-wrapper">
                  <input type="text" v-model="product.location"/>
                </span>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="error" v-if="errorMsg">
              {{errorMsg}}
            </div>
            <div class="success" v-if="success">
              {{success}}
            </div>
          </div>
          <div class="buttons">
            <button class="button">
              {{productId=='new' ? 'Add' : 'Save'}} 
              <span class="button-loading" v-if="loading"></span>
            </button>
            <button class="button button-cancel" v-on:click="handleCancel">
              Cancel
            </button>
          </div>
        </form>
        
        <admin-product-notes v-bind="{
          productId: this.product.id,
          productNotes: this.product.notes
        }" v-if="this.product.id"></admin-product-notes>
      </div>
    </div>
  `,
};