import TableGrid from './TableGrid.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import Loading from './Loading.js';
import DateTime from './DateTime.js';
import AdminPurchaseOrderEditModal from './AdminPurchaseOrderEditModal.js';
import DropdownDateRange from './DropdownDateRange.js';

import DropdownDateConstant from './constants/DropdownDateConstant.js';
import DateRangeHelper from './helpers/DateRangeHelper.js';

const PO_TYPES = {
  vendor_id: 'Vendor',
  order_number: 'Order Number',
  product_item_id: 'Item'
};

export default {
  name: `AdminPurchaseOrdersGroupBy`,
  components: {
    Loading,
    TableGrid,
    AdminPayrollCurrencyTd,
    AdminPurchaseOrderEditModal,
    DropdownDateRange,
    Info: {
      template: `
        <div class="info">
          <span class="title">
            <slot name="title"></slot>
          </span>
          <span class="value"><slot></slot></span>
        </div>
      `
    }
  },
  data () {
    const gridColumns = [
      {key: '', value: ''},
      {key: 'name', value: 'Item'},
      {key: 'qty', value: 'Qty', type: Number},
      {key: 'total_cost', value: 'Total Cost', type: Number},
      {key: '', value: ''}
    ];

    return {
      purchaseOrders: null,
      loading: false,
      gridColumns,
      gridData: [],
      itemToEdit: null,
      errorMsg: null,
      totals: null,
      purchaseOrderToEdit: null,
      currentDateRange: DropdownDateConstant.LAST_12_MONTHS
    };
  },
  computed: {
    field () {
      return this.$route.params.field;
    },
    fieldValue () {
      return this.$route.params.fieldValue;
    },
    query () {
      return this.$route.query;
    },
    dateRange () {
      const dateRange = DateRangeHelper.getDateRange(this.currentDateRange);
      return dateRange;
    }
  },
  watch: {
    itemsToShow () {
      this.pivotData();
    },
    field () {
      this.fetchPurchaseOrders();
    },
    query () {
      this.fetchPurchaseOrders();
    },
    currentDateRange() {
      this.fetchPurchaseOrders();
    }
  },
  methods: {
    handleDateRangeSelect (startDate, endDate, range) {
      this.currentDateRange = range;
    },
    fetchPurchaseOrders () {
      this.loading = true;
      
      this.$http.get('/product_orders/group_by/', {
        params: {
          group_by: 'product_item_id',
          start_date: this.dateRange.startDate || null,
          end_date: this.dateRange.endDate || null
        }
      })
      .then((response) => {
        if (!response.data.error) {
          this.purchaseOrders = response.data.items;
          this.pivotData();
        }
        else {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    pivotData () {
      const purchaseOrders = this.purchaseOrders;
      const gridData = [];
      const totals = {
        itemCount: 0,
        totalCost: 0
      };

      purchaseOrders.forEach(item => {
        gridData.push(item);

        totals.totalCost += parseFloat(item.total_cost, 10); 
        totals.itemCount++;

      });

      totals.totalCost = parseInt(totals.totalCost, 10);
      this.totals = totals;
      this.gridData = gridData;
    }
  },
  filters: {
    fieldToHuman(str) {
      return PO_TYPES[str] || str;
    },
    ...DateTime
  },
  mounted () {
    this.fetchPurchaseOrders();
  },
  template: `
    <div class="admin-purchase-orders-component component-fixed">
      <div class="component-header">
        <ul class="nav">
          <li>
            <router-link :to='{name: "home"}' class="item">Home</router-link>
          </li>
          <li>
            <router-link :to='{name: "purchaseOrders"}' class="item">Purchase Orders</router-link>
          </li>
          <li>
            <span class="item">Group: By Item</span>
          </li>
        </ul>
        <ul class="component-options">
          <li><router-link :to='{name: "purchaseOrdersByLabel"}'>Group By Label</router-link></li>
          <li><router-link :to='{name: "vendors"}'>Vendors</router-link></li>
          <li><router-link :to='{name: "shop"}'>Shop Products</router-link></li>
        </ul>
      </div>
      <section class="component-sub-header" v-if="totals">
        <info>
          <template slot="title">Items</template>
          {{totals.itemCount}}
        </info>
        <info>
          <template slot="title">Total cost</template>
          $ {{totals.totalCost}}
        </info>
        <info>
          <template slot="title">Date Range</template>
          <dropdown-date-range v-bind='{onSelect: handleDateRangeSelect, selectedOptionName: currentDateRange}'></dropdown-date-range>
        </info>
      </section>
      <loading v-bind="{loading: loading}"></loading>
      <div class="error" v-if="errorMsg">{{errorMsg}}</div>
      <admin-purchase-order-edit-modal v-bind="{item: purchaseOrderToEdit, onUpdate: handlePurchaseOrderEditSuccess}" v-if="purchaseOrderToEdit"></admin-purchase-order-edit-modal>

      <table-grid
        class="table-block"
        :data="gridData"
        defaultSortColumn="purchased_on"
        defaultSortOrder="-1"
        :columns="gridColumns"
        v-if="gridData.length">
          <template slot="rows" slot-scope="row">
          <td></td>
            <td>
              <router-link :to="{name: 'purchaseOrders', params: {field: 'product_item_id', fieldValue: row.product_item_id}}">
                {{row.product}}
              </router-link>
            </td>
            <td>
              {{row.qty}}
            </td>
            <AdminPayrollCurrencyTd v-bind="{value: row.total_cost}"></AdminPayrollCurrencyTd>
            <td></td>
          </template>
      </table-grid>
    </div>
  `,
};