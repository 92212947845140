

export default {
  name: `Dropdown`,
  components: {
    vnode: {
      functional: true,
      render(h, context){
        return context.props.node
      }
    }
  },
  props: {
    options: {
        type: [Array, Object]
    },
    selectedOption: {
      type: [Object]
    },
    onSelect: {
      functional: true
    }
  },
  data () {
    return {
      isActive: false
    }
  },
  computed: {
    
  },
  methods: {
    handleClick() {
      console.log('handleClick');
      this.isActive = !this.isActive;
    },
    handleOptionClick(option) {
      this.onSelect(option);
      this.isActive = false;
    },
    handleOptionsClick() {
      this.isActive = false;
    }
  },
  mounted () {

  },
  template: `
    <div class="dropdown-component">
      <div class="dropdown-component-container">
        <a href="#" v-on:click.prevent="handleClick" :class="{active: isActive}">{{selectedOption.label}}</a>
        <ul :class="{active: isActive, options: true}" v-if="isActive" v-on:click.prevent="handleOptionsClick">
          <li v-for="option in options" :class="{selected: option.label == selectedOption.label}"><a href="#" v-on:click.prevent="handleOptionClick(option)">{{option.label}}</a></li>
        </ul>
      </div>
    </div>
  `,
};