import Toast from './../components/Toast.js';

export default {
  install (Vue, options) {
    console.log('install toast');
    
    const div = document.createElement('div');
    document.body.appendChild(div);

    const t = new (Vue.extend(Toast))({propsData: options});
    t.$mount(div);

    const _toast = {
      show: (message) => {
        t.show(message);
      },
      success: (message, options) => {
        t.show(message, Object.assign({type: 'success'}, options || {}));
      },
      error: (message, options) => {
        t.show(message, Object.assign({type: 'error'}, options || {}));
      },
      info: (message, options) => {
        t.show(message, Object.assign({type: 'info'}, options || {}));
      },
      warning: (message, options) => {
        t.show(message, Object.assign({type: 'warning'}, options || {}));
      }
    }

    Vue.prototype.$toast = _toast;

    // window.setTimeout(() => {
    //   _toast.show('first');
    // }, 1000);
    // window.setTimeout(() => {
    //   _toast.warning('first');
    // }, 2000);
    // window.setTimeout(() => {
    //   _toast.error('second');
    // }, 3000);
    // window.setTimeout(() => {
    //   _toast.info('second');
    // }, 4000);
    // window.setTimeout(() => {
    //   _toast.success('second');
    // }, 5000);
  }
}