import Modal from './Modal.js';
import Loading from './Loading.js';
import FormInput from './form/FormInput.js';
import FormSelect from './form/FormSelect.js';

export default {
  name: 'AdminHotelRoomEditModal',
  components: {
    FormInput,
    FormSelect,
    Modal,
    Loading
  },
  props: ['room', 'onUpdate'],
  data () {
    return {
      loading: false,
      errorMsg: null,
      success: null,
      updatedRoom: {}
    }
  },
  computed: {
    roomTypes () {
      return this.$store.state.roomTypes;
    },
    roomTypeOptions () {
      const roomTypes = [];

      this.$store.state.roomTypes.forEach(roomType => {
        let description = '';
        if (roomType.description) {
          description = ' - ' + roomType.description;
        }
        roomTypes.push({value: roomType.id, label: roomType.type + description + ' (' + roomType.usage + ')'});
      });

      return roomTypes;
    }
  },
  methods: {
    handleInputUpdate(value, key) {
      this.updatedRoom[key] = value.trim();
    },
    handleCancel() {
      this.onUpdate('Cancelled!');
    },
    handleRoomDelete() {
      this.updatedRoom.active = 0;
      this.handleRoomUpdate();
    },
    handleRoomUpdate () {
      if (Object.keys(this.updatedRoom).length) {
        if (this.room.id && this.room.id !== 'new') {
          this.updatedRoom.id = this.room.id;
        }
        this.saveRoom();
      }
      else {
        this.onUpdate('No Change!');
      }
    },
    saveRoom () {
      this.loading = true;
      this.errorMsg = null;
      this.updatedRoom.hotel_section_id = this.room.hotel_section_id;

      this.$http.post('/v0/hotel/rooms', this.updatedRoom)
        .then((response) => {
          if (response.data.room) {
            this.errorMsg = null;
            this.onUpdate('Updated Room ' + this.updatedRoom.room || this.room.room + '!');
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchRoomTypes () {
      this.loading = true;
      this.errorMsg = null;

      this.$http.get('/v0/hotel/room_types')
        .then((response) => {
          if (response.data.hotel_room_types) {
            this.errorMsg = null;
            this.$store.dispatch('setRoomTypes', response.data.hotel_room_types);
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {
    console.log(this.roomTypes);
    if (!this.roomTypes) {
      this.fetchRoomTypes();      
    }
  },
  template: ` 
    <modal :onClose="handleCancel">
      <div class="component-header">
        <h2 class="component-header-title">
        {{room.id == 'new' ? 'Add' : 'Edit'}} Room
        </h2>
      </div>
      <div class="component-body">
        <form class="form" v-on:submit.prevent="handleRoomUpdate">
          <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
          <form-input label="Room Number" :value="room.room" name="room" :onChange="handleInputUpdate" focus="true"></form-input>
          <form-input label="Square Feet" :value="room.sqft" name="sqft" :onChange="handleInputUpdate"></form-input>
          <form-select label="Room Type" :value="room.hotel_room_type_id" name="hotel_room_type_id" :onChange="handleInputUpdate" :options="roomTypeOptions" v-if="roomTypes"></form-select>
          <div class="error" v-if="errorMsg">{{errorMsg}}</div>
          <div class="buttons padding-top">
            <button class="button">
              {{room.id == 'new' ? 'Add' : 'Update'}}
            </button>
            <a href="#" v-on:click.prevent="handleCancel" class="button button-cancel">Cancel</a>
          </div>
        </form>
      </div>
    </modal>
  `
};