import Loading from './Loading.js';
import DateTime from './DateTime.js';
import AdminPurchaseOrderEditModal from './AdminPurchaseOrderEditModal.js';

// const PO_TYPES = {
//   vendor_id: 'Vendor',
//   order_number: 'Order Number',
//   product_item_id: 'Item'
// };

export default {
  name: `AdminPurchaseOrderAdd`,
  components: {
    Loading,
    AdminPurchaseOrderEditModal,
  },
  data () {
    return {
      loading: false,
      itemsToShow: null,
      itemToEdit: null,
      errorMsg: null,
      purchaseOrderToAdd: null,
    };
  },
  computed: {
    query () {
      return this.$route.query;
    }
  },
  watch: {

  },
  methods: {
    handleDateRangeSelect (startDate, endDate, range) {
      this.currentDateRange = range;
    },
    handleNew () {
      this.itemToEdit = {
        id: 'new'
      };
    },
    handlePurchaseOrderEditSuccess () {
      this.purchaseOrderToAdd = null;

      this.$router.replace({path: this.$router.currentPath, query: {}});
    },
    fetchProductDetails () {
      this.loading = true;
      
      console.log(this.query.qty * this.query.pricePerQty);
      console.log(this.query.orderDate);
      
      this.loading = false;
      console.log('fetchProductDetails', this.query);
      
      this.$http.get('/vendor_items/byNameAndProductId/', {
        params: {
            vendorName: this.query.vendor,
            vendorProductId: this.query.part
        }
      })
      .then((response) => {
        if (!response.data.error) {
          const item = response.data.item || {
            error: 'Missing vendor product id'
          };

          this.purchaseOrderToAdd = { 
            ...item,
            id: 'new',
            order_number: this.query.orderNumber,
            ordered_on: this.query.orderDate,
            order_qty: this.query.qty,
            pricePerQty: this.query.pricePerQty,
            total_cost: this.query.qty * this.query.pricePerQty,
            addingFromThirdParty: true
          };
        }
        else {
          alert(response.data.error);
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    pivotData () {
      
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchProductDetails();
  },
  template: `
    <div class="admin-purchase-order-add-component">  
      <loading v-bind="{loading: loading}"></loading>
      <admin-purchase-order-edit-modal v-bind="{item: purchaseOrderToAdd, onUpdate: handlePurchaseOrderEditSuccess}" v-if="purchaseOrderToAdd"></admin-purchase-order-edit-modal>
    </div>
  `,
};