import AdminPayrollCalculationInfo from './AdminPayrollCalculationInfo.js';
import AdminPayrollHoursTd from './AdminPayrollHoursTd.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import DateTime from './DateTime.js';
import Loading from './Loading.js';
import DownloadCSV from './helpers/DownloadCsv.js';

export default {
  name: `AdminPayrollReport`,
  components: {
    AdminPayrollCalculationInfo,
    AdminPayrollHoursTd,
    AdminPayrollCurrencyTd,
    Loading,
    Name: {
      props: ['detail'],
      methods: {
        removeEmployee () {
          this.detail.hidden = true;
        }
      },
      template: `
        <div class="name">
          <router-link
          :to="{name: 'employees', params: {employeeId: detail.user_id}}">
            {{detail.first_name}} {{detail.last_name}}
          </router-link>
          <a href="#" v-on:click.prevent="removeEmployee" class="hide">Hide</a>
        </div>
      `
    },
    Info: {
      template: `
        <div class="info">
          <span class="title">
            <slot name="title"></slot>
          </span>
          <span class="value"><slot></slot></span>
        </div>
      `
    }
  },
  data () {
    return {
      payrollData: null,
      payrollReport: null,
      loading: false,
      showNoteArea: false,
      note: '',
      startTime: 0,
      errorMsg: null
    }
  },
  computed: {
    payrollReportId () {
      return this.$route.params.reportId;
    },
    payrollReportType () {
      return this.$route.params.reportType;
    },
    totalsByPosition () {
      const data = this.payrollReport;
      if (!data) {
        return;
      }
      const totalsByPosition = {};

      data.details.forEach((item) => {
        const {position} = item;
        if (!item.hidden) {
          if (!totalsByPosition[position]) {
            totalsByPosition[position] = {
              regular_hours: 0,
              regular_wages: 0,
              overtime_hours: 0,
              overtime_wages: 0,
              holiday_hours: 0,
              holiday_wages: 0,
              vacation_hours: 0,
              vacation_wages: 0,
              hourly_bonus: 0,
              bonus: 0,
              total_wages: 0
            };
          }

          totalsByPosition[position].regular_hours = window.numeral(item.regular_hours).add(totalsByPosition[position].regular_hours).format('0.00');
          totalsByPosition[position].regular_wages = window.numeral(item.regular_wages).add(totalsByPosition[position].regular_wages).format('0.00');
          totalsByPosition[position].overtime_hours = window.numeral(item.overtime_hours).add(totalsByPosition[position].overtime_hours).format('0.00');
          totalsByPosition[position].overtime_wages = window.numeral(item.overtime_wages).add(totalsByPosition[position].overtime_wages).format('0.00');
          totalsByPosition[position].holiday_hours = window.numeral(item.holiday_hours).add(totalsByPosition[position].holiday_hours).format('0.00');
          totalsByPosition[position].holiday_wages = window.numeral(item.holiday_wages).add(totalsByPosition[position].holiday_wages).format('0.00');
          totalsByPosition[position].vacation_hours = window.numeral(item.vacation_hours).add(totalsByPosition[position].vacation_hours).format('0.00');
          totalsByPosition[position].vacation_wages = window.numeral(item.vacation_wages).add(totalsByPosition[position].vacation_wages).format('0.00');
          totalsByPosition[position].hourly_bonus = window.numeral(item.hourly_bonus).add(totalsByPosition[position].hourly_bonus || 0).format('0.00');
          totalsByPosition[position].bonus = window.numeral(item.bonus).add(totalsByPosition[position].bonus).format('0.00');
          totalsByPosition[position].total_wages = window.numeral(item.total_wages).add(totalsByPosition[position].total_wages).format('0.00');
        }
      });

      return totalsByPosition;
    },
    totals () {
      const data = this.payrollReport;
      if (!data) {
        return;
      }

      const totalsByPosition = {
        regular_hours: 0,
        regular_wages: 0,
        overtime_hours: 0,
        overtime_wages: 0,
        holiday_hours: 0,
        holiday_wages: 0,
        vacation_hours: 0,
        vacation_wages: 0,
        hourly_bonus: 0,
        bonus: 0,
        total_wages: 0,
        employee_count: 0
      };

      data.details.forEach((item) => {
        if (!item.hidden) {
          totalsByPosition.regular_hours = window.numeral(item.regular_hours).add(totalsByPosition.regular_hours).format('0.00');
          totalsByPosition.regular_wages = window.numeral(item.regular_wages).add(totalsByPosition.regular_wages).format('0.00');
          totalsByPosition.overtime_hours = window.numeral(item.overtime_hours).add(totalsByPosition.overtime_hours).format('0.00');
          totalsByPosition.overtime_wages = window.numeral(item.overtime_wages).add(totalsByPosition.overtime_wages).format('0.00');
          totalsByPosition.holiday_hours = window.numeral(item.holiday_hours).add(totalsByPosition.holiday_hours).format('0.00');
          totalsByPosition.holiday_wages = window.numeral(item.holiday_wages).add(totalsByPosition.holiday_wages).format('0.00');
          totalsByPosition.vacation_hours = window.numeral(item.vacation_hours).add(totalsByPosition.vacation_hours).format('0.00');
          totalsByPosition.vacation_wages = window.numeral(item.vacation_wages).add(totalsByPosition.vacation_wages).format('0.00');
          totalsByPosition.hourly_bonus = window.numeral(item.hourly_bonus).add(totalsByPosition.hourly_bonus || 0).format('0.00');
          totalsByPosition.bonus = window.numeral(item.bonus).add(totalsByPosition.bonus).format('0.00');
          totalsByPosition.total_wages = window.numeral(item.total_wages).add(totalsByPosition.total_wages).format('0.00');
          totalsByPosition.employee_count++;
        }
      });

      return totalsByPosition;
    }
  },
  watch: {
    payrollReportId () {
      this.fetchPayrollReport();
    }
  },
  methods: {
    handleDeletePayroll() {
      const confirmResponse = confirm('Do you want to delete this payroll?\n\nYou will not be able to recover this!');
      if (confirmResponse) {
        console.log('delete');
        this.deletePayroll();
      }
    },
    handleAddNote () {
      this.showNoteArea = true;
    },
    handleNoteSave() {
      if (this.note.trim() !== '') {
        this.$http.post('/payroll/report/note', {
          note: this.note,
          payroll_report_id: this.payrollReport.id
        })
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.error;
          } else {
            if (response.data.payroll_notes) {
              this.payrollReport.notes = response.data.payroll_notes;
            }
            this.note = '';
            this.showNoteArea = false;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
      }
    },
    handleNoteCancel() {
      this.note = '';
      this.showNoteArea = false;
    },
    pivoteData (data) {
      if (!data) {
        return;
      }
      const totalsByPosition = {};
      console.log(data.pay_date);

      data.details.forEach((item) => {
        if (typeof item.hidden === 'undefined') {
          item.hidden = false;
        }
      });

      data.totals_by_position = totalsByPosition;

      this.payrollReport = data;
    },
    fetchPayrollReport () {
      this.loading = true;
      this.errorMsg = null;

      this.$http.get('/payroll/reports/' + this.payrollReportType +'/' + this.payrollReportId)
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        } else {
          if (response.data.payroll_report) {
            this.pivoteData(response.data.payroll_report);
          }
          else {
            this.errorMsg = 'Payroll does not exist!';
          }
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    deletePayroll () {
      this.loading = true;
      const data = {payroll_report_id: this.payrollReportId};
      this.$http.post('/payroll/delete/', data)
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        }
        else {
          if (response.data.payroll_report && response.data.payroll_report.deleted) {
            this.$router.replace({
              name: 'payrollReports'
            });
          }
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    removeEmployee (detail) {
      this.payrollData.details.forEach((item) => {
        if (item.id === detail.id) {
          item.hidden = true;
        }
      });
    },
    exportCsv () {
      const data = this.payrollReport;
      if (!data) {
        return;
      }

      const jsonData = [];
      const startDate = new Date(data.start_date);
      const endDate = new Date(data.end_date);
      const fileName = DateTime.numericDate(startDate) + '-' + DateTime.numericDate(endDate) + '.csv';

      data.details.forEach((item) => {
        if (!item.hidden) {
          const data = {
            'First Name': item.first_name,
            'Last Name': item.last_name,
          };

          data['Hourly Wage ($)'] = '$ ' + (window.numeral(item.is_hourly == 1 ? item.rate : 0).format('0.00'));
          data['Salary ($)'] = '$ ' + (window.numeral(item.is_hourly == 1 ? 0 : item.rate).format('0.00'));
          data['Reg Hours'] = item.regular_hours != 0 && item.is_hourly == 1 ? window.numeral(item.regular_hours).format('0.00') : '';
          data['Overtime Hours'] = item.overtime_hours != 0 ? window.numeral(item.overtime_hours).format('0.00') : '';
          data['Holiday Hours'] = item.holiday_hours != 0 ? window.numeral(item.holiday_hours).format('0.00') : '';
          data['Vacation Hours'] = item.vacation_hours != 0 ? window.numeral(item.vacation_hours).format('0.00') : '';
          data['Hourly Bonus ($)'] = '$ ' + (item.hourly_bonus != 0 ? window.numeral(item.hourly_bonus).format('0.00') : '');
          data['Bonus ($)'] = '$ ' + (item.bonus != 0 ? window.numeral(item.bonus).format('0.00') : '');
          data['Total Wages ($)'] = '$ ' + (item.total_wages != 0 ? window.numeral(item.total_wages).format('0.00') : '');

          jsonData.push(data);
        }
      });

      jsonData.push({
        'First Name': ''
      });

      jsonData.push({
        'First Name': 'First Day',
        'Last Name': DateTime.numericDate(startDate)
      });

      jsonData.push({
        'First Name': 'Last Day',
        'Last Name': DateTime.numericDate(endDate)
      });

      DownloadCSV(jsonData, fileName);
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchPayrollReport();
  },
  template: `
    <div class="admin-payroll-report">
      <div class="component-header">

        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li><router-link :to="{name: 'payrollReports'}" class="item">Payroll Reports</router-link></li>
          <li><span class="item">{{payrollReportId}}</span></li>
        </ul>
        <ul class="component-options">
          <li><a href="#" v-on:click.prevent="handleDeletePayroll">
            Delete Payroll
          </a></li>
          <li><a href="#" v-on:click.prevent="handleAddNote">
            Add Note
          </a></li>
          <li><a href="#" v-on:click.prevent="exportCsv">
            Export CSV
          </a></li>
          <li>
            <router-link :to="{name: 'emailPayrollReport', payrollReportId: payrollReportId}" class="item">Email Report</router-link>
          </li>
        </ul>
      </div>
      <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
      <div class="error" v-if="errorMsg">{{errorMsg}}</div>
      <div v-if="payrollReport" class="grid">
        <info>
          <template slot="title">Start Date</template>
          {{payrollReport.start_date | humanDate}}
        </info>
        <info>
          <template slot="title">End Date</template>
          {{payrollReport.end_date | humanDate}}
        </info>
        <info>
          <template slot="title">Check Date</template>
          {{payrollReport.pay_date | humanDate}}
        </info>
        <info>
          <template slot="title">Report ID</template>
          {{payrollReport.id}}
        </info>
        <info>
          <template slot="title">Created By</template>
          {{payrollReport.first_name}} {{payrollReport.last_name}}
        </info>
        <info>
          <template slot="title">Created Date</template>
          {{payrollReport.updated_on | humanDate}}
        </info>
      </div>
      <div class="payroll-report-notes" v-if="payrollReport">
        <ul>
          <li v-for="note in payrollReport.notes">
            {{note.created_on | numericDate}} - {{note.note}}
          </li>
          <li v-if="showNoteArea">
            <form class="form" v-on:submit.prevent>
              <textarea v-model="note"></textarea>
              <button class="button" v-on:click="handleNoteSave">Save</button>
              <button class="button button-cancel" v-on:click="handleNoteCancel">cancel</button>
            </form>
          </li>
        </ul>
      </div>
      <div class="payroll-report" v-if="payrollReport">
        <h3 class="sub-header">By Name</h3>
        <table class="table">
          <thead>
            <tr>
              <th rowspan=2>Name</th>
              <th colspan=2>Regular</th>
              <th colspan=2>Overtime</th>
              <th colspan=2>Holiday</th>
              <th colspan=2>Vacation</th>
              <th rowspan=2>Hourly Bonus</th>
              <th rowspan=2>Bonus</th>
              <th rowspan=2>$ Total<br>Wages</th>
            </tr>
            <tr>
              <th>Hours</th>
              <th>Wages</th>
              <th>Hours</th>
              <th>Wages</th>
              <th>Hours</th>
              <th>Wages</th>
              <th>Hours</th>
              <th>Wages</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="detail in payrollReport.details" v-if="!detail.hidden">
              <td>
                <name v-bind="{detail}"></name>
              </td>
              <admin-payroll-hours-td v-bind="{value: detail.regular_hours}"></admin-payroll-hours-td>
              <admin-payroll-currency-td v-bind="{value: detail.regular_wages}"></admin-payroll-currency-td>
              <admin-payroll-hours-td v-bind="{value: detail.overtime_hours}"></admin-payroll-hours-td>
              <admin-payroll-currency-td v-bind="{value: detail.overtime_wages}"></admin-payroll-currency-td>
              <admin-payroll-hours-td v-bind="{value: detail.holiday_hours}"></admin-payroll-hours-td>
              <admin-payroll-currency-td v-bind="{value: detail.holiday_wages}"></admin-payroll-currency-td>
              <admin-payroll-hours-td v-bind="{value: detail.vacation_hours}"></admin-payroll-hours-td>
              <admin-payroll-currency-td v-bind="{value: detail.vacation_wages}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: detail.hourly_bonus}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: detail.bonus}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: detail.total_wages}"></admin-payroll-currency-td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>count {{totals.employee_count}}</th>
              <th class="hours">{{totals.regular_hours}}</th>
              <admin-payroll-currency-td v-bind="{value: totals.regular_wages}"></admin-payroll-currency-td>
              <th class="hours">{{totals.overtime_hours}}</th>
              <admin-payroll-currency-td v-bind="{value: totals.overtime_wages}"></admin-payroll-currency-td>
              <th class="hours">{{totals.holiday_hours}}</th>
              <admin-payroll-currency-td v-bind="{value: totals.holiday_wages}"></admin-payroll-currency-td>
              <th class="hours">{{totals.vacation_hours}}</th>
              <admin-payroll-currency-td v-bind="{value: totals.vacation_wages}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: totals.hourly_bonus}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: totals.bonus}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: totals.total_wages}"></admin-payroll-currency-td>
            </tr>
          </tfoot>
        </table>

        <h3 class="sub-header">By Position</h3>
        <table class="table" v-if="payrollReport">
            <thead>
              <tr>
                <th rowspan=2>Position</th>
                <th colspan=2>Regular</th>
                <th colspan=2>Overtime</th>
                <th colspan=2>Holiday</th>
                <th colspan=2>Vacation</th>
                <th rowspan=2>Hourly Bonus</th>
                <th rowspan=2>Bonus</th>
                <th rowspan=2>$ Total<br>Wages</th>
              </tr>
              <tr>
                <th>Hours</th>
                <th>Wages</th>
                <th>Hours</th>
                <th>Wages</th>
                <th>Hours</th>
                <th>Wages</th>
                <th>Hours</th>
                <th>Wages</th>
              </tr>
          </thead>
          <tbody>
            <tr v-for="(detail, position) in totalsByPosition">
              <td class="position">
                <router-link :to='{name: "payrollReports", query: {position: position}}'>{{position}}</router-link>
              </td>
              <admin-payroll-hours-td v-bind="{value: detail.regular_hours}"></admin-payroll-hours-td>
              <admin-payroll-currency-td v-bind="{value: detail.regular_wages}"></admin-payroll-currency-td>
              <admin-payroll-hours-td v-bind="{value: detail.overtime_hours}"></admin-payroll-hours-td>
              <admin-payroll-currency-td v-bind="{value: detail.overtime_wages}"></admin-payroll-currency-td>
              <admin-payroll-hours-td v-bind="{value: detail.holiday_hours}"></admin-payroll-hours-td>
              <admin-payroll-currency-td v-bind="{value: detail.holiday_wages}"></admin-payroll-currency-td>
              <admin-payroll-hours-td v-bind="{value: detail.vacation_hours}"></admin-payroll-hours-td>
              <admin-payroll-currency-td v-bind="{value: detail.vacation_wages}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: detail.hourly_bonus}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: detail.bonus}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: detail.total_wages}"></admin-payroll-currency-td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th class="hours">{{totals.regular_hours}}</th>
              <admin-payroll-currency-td v-bind="{value: totals.regular_wages}"></admin-payroll-currency-td>
              <th class="hours">{{totals.overtime_hours}}</th>
              <admin-payroll-currency-td v-bind="{value: totals.overtime_wages}"></admin-payroll-currency-td>
              <th class="hours">{{totals.holiday_hours}}</th>
              <admin-payroll-currency-td v-bind="{value: totals.holiday_wages}"></admin-payroll-currency-td>
              <th class="hours">{{totals.vacation_hours}}</th>
              <admin-payroll-currency-td v-bind="{value: totals.vacation_wages}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: totals.hourly_bonus}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: totals.bonus}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: totals.total_wages}"></admin-payroll-currency-td>
            </tr>
          </tfoot>
        </table>
      </div>
      <AdminPayrollCalculationInfo></AdminPayrollCalculationInfo>
    </div>
  `,
};