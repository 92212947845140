import Loading from './Loading.js';
import FormInput from './form/FormInput.js';
import AdminHotelSectionEditModal from './AdminHotelSectionEditModal.js';

export default {
  name: 'AdminHotelSections',
  components: {
    Loading,
    FormInput,
    AdminHotelSectionEditModal
  },
  data() {
    return {
      success: null,
      loading: false,
      sections: [],
      sectionToEdit: null
    }
  },
  computed: {
    
  },
  watch: {

  },
  methods: {
    handleCancel () {
      this.$router.push({
        name: 'hotel'
      });
    },
    handleHotelChange () {
      
    },
    handleAddSection () {
      this.sectionToEdit = {
        id: 'new'
      };
    },
    handleEditSection (section) {
      this.sectionToEdit = section;
    },
    clearSuccess () {
      this.success = null;
    },
    handleEditSuccess(success) {
      this.$toast.info(success);
      this.sectionToEdit = null;
      this.fetchHotelSections();
    },
    fetchHotelSections () {
      this.loading = true;
      this.$http.get('/v0/hotel/sections')
        .then((response) => {
          if (response.data.sections) {
            this.errorMsg = null;
            this.sections = response.data.sections;
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {
    this.fetchHotelSections();
  },
  filters: {
    
  },
  template: `
    <div class="admin-hotel-sections-component">
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li><router-link :to="{name: 'hotel'}" class="item">Hotel</router-link></li>
          <li><span class="item">Hotel Sections</span></li>
        </ul>
      </div>
      <section class="component-body">
        <loading v-bind="{loading: loading}"></loading>
        <ul>
          <li v-for="section in sections">{{section.section}} <a href="#" v-on:click.prevent="handleEditSection(section)" class="button button-small">Edit</a></li>
        </ul>
        <a href="#" v-on:click.prevent="handleAddSection('add')" class="button">Add New Section</a>
        <admin-hotel-section-edit-modal v-bind="{section: sectionToEdit, onUpdate: handleEditSuccess}" v-if="sectionToEdit"></admin-hotel-section-edit-modal>
      </section>
    </div>
  `
};