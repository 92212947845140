
import Loading from './../Loading.js';
import FormSelect from './FormSelect.js';

let globalTimezones = null;
export default {
  name: 'FormSelectTimezone',
  components: {
    Loading,
    FormSelect
  },
  props: ['value', 'label', 'name', 'onChange'],
  data() {
    return {
      loading: false,
      errorMsg: null,
      timezones: globalTimezones
    };
  },
  computed: {
  },
  watch: {

  },
  methods: {
    fetchTimezone () {
      this.loading = true;
      this.$http.get('/tools/timezones/')
        .then((response) => {
          if (response.data.timezones) {
            const timezones = {};
            response.data.timezones.forEach(timezone => {
              timezones[timezone] = timezone;
            });

            this.timezones = globalTimezones = timezones;
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {
    if (!this.timezones) {
      this.fetchTimezone();
    }
  },
  template: `
    <div>
      <loading :loading="loading" v-if="loading"></loading>
      <div class="error error-block" v-else-if="errorMsg"></div>
      <form-select :label="label" :name="name" :value="value" :onChange="onChange" :options="timezones" v-else value="America/New_York"></form-select>
    </div>
  `
};