import Issue from "./Issue.js";
import IssueDetails from "./IssueDetails.js";
import IssueEdit from "./IssueEdit.js";
import ListDetails from "./ListDetails.js";
import Loading from './Loading.js';
import DateTime from "./DateTime.js";

export default {
  name: `Issues`,
  components: {
    Loading,
    ListDetails,
    IssueEdit,
    Issue,
    IssueDetails
  },
  data () {
    return {
      loading: false,
      issues: [],
      issuesStatus: 'unresolved'
    }
  },
  computed: {
    layout () {
      if (this.action == 'edit') {
        return IssueEdit;
      }
      return IssueDetails;
    },
    action () {
      return this.$route.params.action;
    },
    selectedIssueId () {
      return this.$route.params.issueId;
    },
    token () {
      return this.$store.state.businessToken;
    },
    issueDetails () {
      if (this.selectedIssueId === 'new') {
        return {};
      }
      else {
        const issue = this.issues.find((issue) => {
          return issue.id == this.selectedIssueId;
        });
        return issue;
      }
    },
    query () {
      return this.$route.query;
    }
  },
  watch: {
    issuesStatus () {
      this.fetchIssues();
    },
    query () {
      this.fetchIssues();
    }
  },
  methods: {
    handleEditComplete () {
      this.fetchIssues();
    },
    handleChangeStatus () {
      this.issuesStatus = this.issuesStatus == 'unresolved' ? 'resolved' : 'unresolved';
    },
    fetchIssues () {
      this.loading = true;
      const requestUrl = ['issues', this.issuesStatus];
      const params = {
        tag: this.query.tag,
        room: this.query.room
      };

      if (this.selectedIssueId) {
        requestUrl.push(this.selectedIssueId);
      }

    window.api.get(requestUrl.join('/'), {
        params
      })
      .then((response) => {
        if (response.data.issues) {
          this.issues = response.data.issues;
          this.errorMsg = null;
        }
        else {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        console.log(error);
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    queryForRoom(room) {
      return Object.assign({}, this.query, {room});
    }
  },
  mounted () {
    this.fetchIssues();
  },
  filters: {
    ...DateTime
  },
  template: `
    <div class="issues-component">
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'dashboard', params: {token: token}}" class="item">Dashboard</router-link></li>
          <li><span class="item">{{issuesStatus === 'unresolved' ? 'Open' : 'Closed'}} Tickets</span></li>
        </ul>
        <ul class="component-options">
          <li><a href="#" v-on:click.prevent="handleChangeStatus">{{issuesStatus === 'unresolved' ? 'Closed' : 'Open'}} Tickets</a></li>
        </ul>
      </div>
      <list-details :class="{'pane-open': issueDetails}">
        <template slot="list">
          <loading v-bind="{loading: loading}"></loading>
          <div class="list-header component-header">
            <ul class="component-options">
              <li><router-link
              :to="{name: 'hotelIssues', params: {issueId: 'new', action: 'edit'}}">
              New
            </router-link></li>
            </ul>
          </div>
          <div class="issues-empty" v-if="!issues.length">No Open Tickets</div>
          <ul class="issues-list list">
            <issue v-for="issue in issues" :key="issue.id">
              <router-link
                class="issue"
                :to="{name: 'hotelIssues', params: {issueId: issue.id}, query: query}">
                <span class="description">{{issue.description}}</span>
                <span class="resolved" v-if="issue.resolved_on">Resolved</span>
                <span class="created-on">{{issue.created_on | numericDate}}</span>
                <span class="created-user">{{issue.created_user.first_name}}</span>
              </router-link>
              <router-link 
                :to="{name: 'hotelIssues', query: queryForRoom(issue.room)}" 
                class="room"
                v-if="issue.room"
                >
                {{issue.room}}
              </router-link>
            </issue>
          </ul>
        </template>
        <template slot="details" v-if="issueDetails">
          <component v-bind:is="layout" v-bind="{issueDetails, onEditComplete: handleEditComplete}"></component>
        </template>
        <template slot="details" v-if="!issueDetails">
          <div class="none-selected">
            <span class="text">Select Issue</span>
            <br><br>
            <router-link
              :to="{name: 'hotelIssues', params: {issueId: 'new', action: 'edit'}}" class="button">
              Crate a new Ticket!
            </router-link>
            </div>
        </template>
      </list-details>
    </div>
  `,
};