import AdminEmployeesApi from '../apis/AdminEmployeesApi.js';
import Loading from './Loading.js';
import FormInput from './form/FormInput.js';
import FormTextarea from './form/FormTextarea.js';

export default {
  name: 'AdminEmployeeEdit',
  mixins: [AdminEmployeesApi],
  components: {
    Loading,
    FormInput,
    FormTextarea
  },
  props: ['onUpdateEmployees', 'employeeId'],
  data () {
    return {
      employee: null,
      loading: false,
      errorMsg: null
    };
  },
  computed: {
  },
  watch: {
    employeeId () {
      this.employee = null;
      this.fetchEmployee();
    }
  },
  methods: {
    handleInputUpdate(value, key) {
      this.employee[key] = value.trim();
    },
    cancelEdit () {
      const params = {};

      if (this.employeeId !== 'new') {
        params.employeeId = this.employeeId;
      }

      this.$router.push({
        name: 'employees',
        params
      });
    },
    saveEmployee () {
      this.loading = true;
      this.updateUser(this.employee)
        .then((response) => {
          if (!response.data.error) {
            this.employee = response.data.user;
//            Object.assign(this.employeeDetails, this.employee);
            this.$emit('onUpdateEmployees');
            this.$router.push({
              name: 'employees',
              params: {
                employeeId: this.employee.id
              }
            });
          } else {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.response;
        })
        .finally(() => {
          this.loading = false;
        });
    }, 
    fetchEmployee () {
      if (this.employeeId === 'new') {
        this.employee = {
          active: 1
        };
      }
      else {
        this.loading = true;
        this.fetchUser(this.employeeId)
          .then((response) => {
            if (response.data.user) {
              this.employee = response.data.user;
            } else {
              this.errorMsg = response.data.error;
            }
          })
          .catch((error) => {
            this.errorMsg = error.response;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  },
  mounted () {
    this.fetchEmployee();
  },
  template: `
    <div class="admin-employee-edit-component">
      <loading v-bind="{loading: loading}"></loading>
      <section class="component-header">
      </section>
      <section class="component-content">
      
        <h3 class="name">{{employeeId == 'new' ? 'New' : 'Edit'}} <mark v-if="employee && !employee.active">Inactive</mark> Employee</h3>
        
        <form class="form employee-form" v-on:submit.prevent v-if="employee">
          <section class="input-fields">
            <form-input v-bind="{label: 'First Name', value: employee.first_name, onChange: handleInputUpdate, name: 'first_name'}"></form-input>
            <form-input v-bind="{label: 'Last Name', value: employee.last_name, onChange: handleInputUpdate, name: 'last_name'}"></form-input>
            <form-input v-bind="{label: 'Pin', value: employee.pin, onChange: handleInputUpdate, name: 'pin'}"></form-input>
            <div class="row"></div>
            <form-input v-bind="{label: 'Hire Date', type: 'date', value: employee.hire_date, onChange: handleInputUpdate, name: 'hire_date'}"></form-input>
            <form-input v-bind="{label: 'End Date', type: 'date', value: employee.end_date, onChange: handleInputUpdate, name: 'end_date'}" v-if="employee.id"></form-input>
            <form-input v-bind="{label: 'Active', type: 'number', min: 0, max:1, value: employee.active, onChange: handleInputUpdate, name: 'active'}" v-if="employee.id"></form-input>

            <div class="row"></div>
            <form-textarea v-bind="{label: 'End Reason', value: employee.end_reason, onChange: handleInputUpdate, name: 'end_reason'}" v-if="employee.id"></form-textarea>
          </section>
          <div class="row errors" v-if="errorMsg">
            {{errorMsg}}
          </div>
          <div class="row buttons">
            <button class="button" v-on:click="saveEmployee">
              {{employee.id ? 'Update' : 'Create'}} 
              <span class="button-loading" v-if="loading"></span>
            </button>
            <button class="button button-cancel" v-on:click="cancelEdit">
              Cancel
            </button>
          </div>
        </form>
      </section>
    </div>
  `
};