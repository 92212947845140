
import FormField from './FormField.js';
let unique = 0;

export default {
  name: 'FormSelect',
  components: {
    FormField
  },
  props: ['label', 'type', 'name', 'value', 'onChange', 'options', 'focus', 'disabled'],
  data () {
    return {
      ref: 'select-' + unique++
    };
  },
  computed: {
    selectOptions () {
      if (Array.isArray(this.options)) {
        return this.options;
      }

      let options = [];
      Object.keys(this.options).forEach(optionValue => {
        options.push({value: optionValue, label: this.options[optionValue]});
      });

      options.sort((a, b) => (a.label > b.label) ? 1 : -1);

      return options;
    }
  },
  methods: {
    handleChange (event) {
      this.onChange(event.target.value, this.name);
    }
  },
  mounted () { 
    if (this.focus) {
      this.$refs[this.ref].focus();
    }
  },
  template: `
    <form-field :label="label">
      <select :value="value" class="select" @change="handleChange" v-if="options" :ref="ref" :disabled="disabled">
        <option :value="option.value" v-for="option in selectOptions">{{option.label}}</option>
      </select>
    </form-field>
  `
};