import Modal from './Modal.js';
import Loading from './Loading.js';
import FormInput from './form/FormInput.js';

export default {
  name: 'AdminHotelSectionEditModal',
  components: {
    FormInput,
    Modal,
    Loading
  },
  props: ['section', 'onUpdate'],
  data () {
    return {
      loading: false,
      errorMsg: null,
      success: null,
      updatedSection: {}
    }
  },
  methods: {
    handleInputChange (value, key) {
      this.updatedSection[key] = value.trim();
    },
    handleCancel() {
      this.onUpdate('Cancelled!');
    },
    handleSectionDelete() {
      this.updatedSection.active = 0;
      this.handleSectionUpdate();
    },
    handleSectionUpdate () {
      if (Object.keys(this.updatedSection).length) {
        if (this.section.id && this.section.id !== 'new') {
          this.updatedSection.id = this.section.id;
        }
        this.saveSection();
      }
      else {
        this.onUpdate('No Change!');
      }
    },
    saveSection () {
      this.loading = true;
      this.errorMsg = null;
      this.$http.post('/v0/hotel/sections', this.updatedSection)
        .then((response) => {
          if (response.data.section) {
            this.errorMsg = null;
            this.onUpdate('Updated Section ' + this.updatedSection.section + '!');
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  template: ` 
    <modal :onClose="handleCancel">
      <div class="component-header">
        <h2 class="component-header-title">
        {{section.id == 'new' ? 'Add' : 'Edit'}} Section
        </h2>
      </div>
      <div class="component-body">
        <form class="form" v-on:submit.prevent="handleSectionUpdate">
          <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
          <form-input label="Section Name" :value="section.section" name="section" :onChange="handleInputChange" placeholder="Name"></form-input>
          <div class="error" v-if="errorMsg">{{errorMsg}}</div>
          <div class="buttons padding-top">
            <button class="button">
              {{section.id == 'new' ? 'Add' : 'Update'}}
            </button>
            <a href="#" v-on:click.prevent="handleCancel" class="button button-cancel">Cancel</a>
          </div>
          <p>
            <small>1 Building with 3 floors: Add Floor 1, Floor 2, Floor 3</small> 
          <br><small>2 Buildings (A, B) with 2 floors: Add Building 1 - Floor 1, Building 1 - Floor 2, Building 2 - Floor 1, Building 2 - Floor 2 OR A1, A2, B1, B2</small></p>
        </form>
      </div>
    </modal>
  `
};