import DateTime from './DateTime.js';
import Loading from './Loading.js';
import AdminEmployeesApi from '../apis/AdminEmployeesApi.js';
import AdminEmployeeAttributesEditModal from './AdminEmployeeAttributesEditModal.js';

export default {
  name: 'AdminEmployeeAttributes',
  mixins: [AdminEmployeesApi],
  components: {
    AdminEmployeeAttributesEditModal,
    Loading
  },
  props: ['employeeId'],
  data() {
    return {
      loading: false,
      userAttributes: [],
      attributeToEdit: null
    }
  },
  computed: {
    
  },
  watch: {
    employeeId () {
      console.log(this.employeeId);
      this.userAttributes = [];
      this.fetchAttributes();
    }
  },
  methods: {
    handleAddAttribute (name, type, label) {
      this.attributeToEdit = {
        name,
        user_id: this.employeeId,
        id: null,
        type,
        label
      };
    },
    handleEditAttribute (name, type, label, item) {
      this.attributeToEdit = {
        name,
        type,
        label,
        ...item
      };
    },
    handleAttributeUpdate (updateMsg) {
      this.attributeToEdit = null;
      this.$toast.show(updateMsg);
      this.fetchAttributes();
    },
    fetchAttributes() {
      this.loading = true;
      this.errorMsg = null;

      this.$http.get('/user_attributes/' + this.employeeId)
          .then((response) => {
            if (response.data.items) {
              this.errorMsg = null;
              this.userAttributes = response.data.items;
              console.log(response.data.items)
            }
            if (response.data.error) {
              this.errorMsg = response.data.error;
            }
          })
          .catch((error) => {
            this.errorMsg = error.toString();
          })
          .finally(() => {
            this.loading = false;
          });
    }
  },
  mounted() {
    console.log(this.employeeId);
    this.fetchAttributes();
  },
  filters: {
    ...DateTime,
  },
  template: `
    <div class="admin-employee-attributes-component">
      <admin-employee-attributes-edit-modal v-bind="{attribute: attributeToEdit, onUpdate: handleAttributeUpdate}" v-if="attributeToEdit"></admin-employee-attributes-edit-modal>
      <loading v-bind="{loading: loading}"></loading>
      
      <section class="grid-2">
      <div class="info" v-for="(attribute, attributeName) in userAttributes">
        <span class="title">
          {{attribute.label || attributeName}} 
          <a href="#" class=" button-small" v-on:click.prevent="handleAddAttribute(attributeName, attribute.type, attribute.label)">Add</a>
        </span>
        <div class="value" v-for="item in attribute.items">
          <span v-if="attribute.type == 'date'">{{item.value | numericDate}}</span>
          <span v-else>{{item.value}}</span>
          <a href="#" class=" button-small" v-on:click.prevent="handleEditAttribute(attributeName, attribute.type, attribute.label, item)">Edit</a>
        </div>
      </div>
      </section>
    </div>
  `
};