import DateTime from './DateTime.js';
import TableGrid from './TableGrid.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import Loading from './Loading.js';
import AdminShopVendorProductEditModal from './AdminShopVendorProductEditModal.js';
import AdminPurchaseOrderEditModal from './AdminPurchaseOrderEditModal.js';

export default {
  name: `AdminShop`,
  components: {
    Loading,
    TableGrid,
    AdminPayrollCurrencyTd,
    AdminShopVendorProductEditModal,
    AdminPurchaseOrderEditModal,
    Info: {
      template: `
        <div class="info">
          <span class="title">
            <slot name="title"></slot>
          </span>
          <span class="value"><slot></slot></span>
        </div>
      `
    }
  },
  data () {
    const gridColumns = [
      {key: '', value: ''},
      {key: 'product', value: 'Product'},
      {key: 'vendor_product_id', value: 'Product ID'},
      {key: 'brand', value: 'Brand'},
      {key: 'description', value: 'Description'},
      {key: 'qty', value: 'Qty', type: Number},
      {key: 'price', value: 'Price', type: Number},
      {key: 'price_per_item', value: 'Price per item', type: Number},
      {key: '', value: ''}
    ];

    return {
      products: null,
      vendor: null,
      loading: false,
      gridColumns,
      gridData: [],
      errorMsg: null,
      productToEdit: null,
      purchaseOrderToEdit: null
    }
  },
  computed: {
    vendorId () {
      return this.$route.params.vendorId;
    }
  },
  watch: {
    itemsToShow () {
      this.pivotData();
    }
  },
  methods: {
    
    handleEdit (item) {
      this.productToEdit = item;
    },
    handleEditSuccess () {
      this.productToEdit = null;
      this.fetchProducts();
    },
    handlePurchaseOrderEdit (item) {
      const purchaseOrder = Object.assign({}, item);
      purchaseOrder.vendor_product_item_id = item.id;
      purchaseOrder.total_cost = item.price;
      purchaseOrder.id = 'new';
      this.purchaseOrderToEdit = purchaseOrder;
    },
    handlePurchaseOrderEditSuccess () {
      this.purchaseOrderToEdit = null;
    },
    fetchProducts () {
      this.loading = true;
      this.$http.get('/vendor_items/vendor/' + this.vendorId + '/')
      .then((response) => {
        if (response.data.items) {
          this.products = response.data.items;
          this.pivotData();
        }
        if (response.data.vendor) {
          this.vendor = response.data.vendor;
        }
        if (response.data.error) {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    pivotData () {
      const products = this.products;
      const gridData = [];

      products.forEach(item => {
        if (!this.itemsToShow || item.item === this.itemsToShow) {
          item.price_per_item = window.numeral(parseFloat(item.price || 0, 10) / parseInt(item.qty, 10)).format('0.0000');
          item.purchase_url = item.url.replace('%s', item.vendor_product_id);
          gridData.push(item);
        }
      });

      this.gridData = gridData;
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchProducts();
  },
  template: `
    <div class="admin-products-component component-fixed">
      <div class="component-header">
        <ul class="nav">
          <li>
            <router-link :to='{name: "home"}' class="item">Home</router-link>
          </li>
          <li>
            <router-link :to='{name: "vendors"}' class="item">Vendors</router-link>
          </li>
          <li>
            <span class="item">Products <span v-if="vendor">@ {{vendor.name}}</span></span>
          </li>
        </ul>
        <ul class="component-options">
          <li>
            <router-link v-if="vendor" :to="{name: 'purchaseOrders', params: {field: 'vendor_id', fieldValue: vendor.id}}">
              Purchase Orders for {{vendor.name}}
            </router-link>
          </li>
        </ul>
      </div>
      <section class="component-sub-header" v-if="vendor">
        <info>
          <template slot="title">Website</template>
          <a :href="vendor.website">{{vendor.website}}</a>
        </info>
      </section>
      <loading v-bind="{loading: loading}"></loading>
      <admin-shop-vendor-product-edit-modal v-bind="{item: productToEdit, onUpdate: handleEditSuccess}" v-if="productToEdit"></admin-shop-vendor-product-edit-modal>
      <admin-purchase-order-edit-modal v-bind="{item: purchaseOrderToEdit, onUpdate: handlePurchaseOrderEditSuccess}" v-if="purchaseOrderToEdit"></admin-purchase-order-edit-modal>
      <div class="error" v-if="errorMsg">{{errorMsg}}</div>
      <div class="" v-if="!errorMsg">
      </div>
      <table-grid
        class="table-block"
        :data="gridData"
        defaultSortColumn="purchased_on"
        defaultSortOrder="-1"
        :columns="gridColumns"
        v-if="gridData.length">
          <template slot="rows" slot-scope="row">
            <td></td>
            <td>
              <router-link :to='{name: "shopProductDetails", params: {productId: row.product_item_id}}'>{{row.product}}</router-link>
            </td>
            <td>{{row.vendor_product_id}}</td>
            <td>{{row.brand}}</td>
            <td>{{row.description}}</td>
            <td>{{row.qty}}</td>
            <AdminPayrollCurrencyTd v-bind="{value: row.price}"></AdminPayrollCurrencyTd>
            <AdminPayrollCurrencyTd v-bind="{value: row.price_per_item}"></AdminPayrollCurrencyTd>
            <td class="nowrap">
              <ul class="options-buttons">
                <li><a href="#" v-on:click.prevent="handleEdit(row)">Edit</a></li>
                <li><a :href="row.purchase_url" target="_blank">Buy</a></li>
                <li><a href="#" v-on:click.prevent="handlePurchaseOrderEdit(row)">+ PO</a></li>
              </ul>  
            </td>
          </template>
      </table-grid>
    </div>
  `,
}