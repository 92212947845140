import DateTime from './DateTime.js';
import TableGrid from './TableGrid.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import AdminPayrollHoursTd from './AdminPayrollHoursTd.js';
import Loading from './Loading.js';
import DropdownDateRange from './DropdownDateRange.js';
import Dropdown from './Dropdown.js';
import AdminError from './AdminError.js';

import DropdownDateConstant from './constants/DropdownDateConstant.js';
import DateRangeHelper from './helpers/DateRangeHelper.js';

export default {
  name: `AdminPayrollReports`,
  components: {
    Loading,
    TableGrid,
    AdminPayrollCurrencyTd,
    AdminPayrollHoursTd,
    DropdownDateRange,
    Dropdown,
    AdminError,
    Info: {
      template: `
        <div class="info">
          <span class="title">
            <slot name="title"></slot>
          </span>
          <span class="value"><slot></slot></span>
        </div>
      `
    }
  },
  data () {
    const gridColumns = [
      {key: 'pay_date', value: 'Pay Date'},      
      {key: 'users_count', value: 'Employees', type: Number},
      {key: 'total_hours', value: 'Total Hrs', type: Number},
      {key: 'regular_hours', value: 'Reg HRs', type: Number},
      {key: 'overtime_hours', value: 'OT HRs', type: Number},
      {key: 'holiday_hours', value: 'Holiday HRs', type: Number},
      {key: 'vacation_hours', value: 'Vacation HRs', type: Number},
      {key: 'hourly_bonus', value: '$ Hourly Bonus', type: Number},
      {key: 'bonus', value: '$ Bonus', type: Number},
      {key: 'total_wages', value: '$ Total Wages', type: Number}
    ];

    const views = {
      TABLE_VIEW: 'TABLE_VIEW',
      CHART_VIEW: 'CHART_VIEW'
    };

    return {
      payrollReports: null,
      loading: false,
      gridColumns,
      gridData: [],
      positions: [],
      views: views,
      view: views.TABLE_VIEW,
      overall: null,
      errorMsg: null,
      currentDateRange: DropdownDateConstant.LAST_12_MONTHS,
      datacollection: null
    };
  },
  computed: {
    position () {
      return this.$route.query.position;
    },
    dateRange () {
      const dateRange = DateRangeHelper.getDateRange(this.currentDateRange);
      return dateRange;
    },
    dropdownSelectedPosition () {
      if (this.positions.length) {
        let option = this.dropdownPositionOptions[0];
        if (this.position) {
          option = this.dropdownPositionOptions.find(item => item.name == this.position);
        }
  
        return option;
      }
    },
    dropdownPositionOptions () {
      let positions = [{
        name: null,
        label: 'All Positions'
      }];

      this.positions.forEach(position => {
        positions.push({
          name: position.position,
          label: position.position
        });
      });

      return positions;
    }
  },
  watch: {
    position () {
      this.fetchPayrollReports();
    },
    currentDateRange () {
      this.fetchPayrollReports();
    }
  },
  methods: {
    handleDropdownSelectPosition (selectedOption) {
      const query = Object.assign({}, this.$route.query);
      if (selectedOption.name) {
        query.position = selectedOption.name;
      }
      else {
        delete query.position;
      }

      this.$router.replace({
        name: this.$route.name,
        params: this.$route.params,
        query
      });
    },
    handleDateRangeSelect (startDate, endDate, range) {
      this.currentDateRange = range;
    },
    fetchPositions() {
      this.$http.get('/positions/')
      .then((response) => {
        if (response.data.positions) {
          this.positions = response.data.positions;
        }
        else {
          this.positions = [];
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    fetchPayrollReports () {
      this.loading = true;
      this.errorMsg = null;
      const requestUrl = '/payroll/reports';
      
      const requestOptions = {
        position: this.position,
        start_date: this.dateRange.startDate || null,
        end_date: this.dateRange.endDate || null
      };

      this.$http.get(requestUrl, {
        params: requestOptions
      })
      .then((response) => {
        if (response.data.payroll_reports) {
          this.payrollReports = response.data.payroll_reports;
          this.pivotData();
        }
        else {
          this.payrollReports = [];
          this.errorMsg = response.data.error || 'unknow error';
        }
        this.pivotData();
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    pivotData () {
      const payrollReports = this.payrollReports;
      const gridData = [];
      const overall = {
        totalHours: 0,
        totalWages: 0,
        count: 0
      };

      payrollReports.forEach(report => {
        report.total_hours = window.numeral(parseFloat(report.regular_hours, 10) + parseFloat(report.overtime_hours, 10) + parseFloat(report.holiday_hours, 10)).format('0.00');
        gridData.push(report);

        overall.totalHours += report.total_hours * 1;
        overall.totalWages += report.total_wages * 1;
        overall.count++;
      });

      overall.averageHourlyWage = window.numeral(overall.totalWages / overall.totalHours).format('0.00');
      overall.totalWages = window.numeral(overall.totalWages).format('0');
      this.gridData = gridData;
      this.overall = overall;

      this.fillData();
    }, 
    fillData () {
      let labels = [];
      let datasetData = [];
      
      this.payrollReports.forEach(report => {
        labels.push(report.pay_date);
        datasetData.push(report.total_wages);
      });
      
      this.datacollection = {
          labels,
          datasets: [
            {
              label: 'Wages',
              backgroundColor: 'rgba(255, 56, 89, 0.93)',
              data: datasetData,
              borderWidth: 1,
              borderColor: 'rgba(255, 56, 89, 0.29)'
            }
        ]
      };
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchPayrollReports();
    this.fetchPositions();
  },
  template: `
    <div class="admin-payroll-reports-component">
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li>
            <router-link :to='{name: "payrollReports"}' class="item" v-if="position">Payroll Reports</router-link>
            <span class="item" v-else>Payroll Reports</span>
          </li>
          <li>
            <span class="item">
              <dropdown v-bind='{options: dropdownPositionOptions, selectedOption: dropdownSelectedPosition, onSelect: handleDropdownSelectPosition}'></dropdown>
            </span>
          </li>
        </ul>
        <ul class="component-options">
        </ul>
      </div>
      <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
      <div class="component-sub-header">
        <info v-if="overall">
          <template slot="title">Total Wages</template>
          $ {{overall.totalWages}}
        </info>
        <info v-if="overall">
          <template slot="title">Total Hours</template>
          {{overall.totalHours}}
        </info>
        <info v-if="overall">
          <template slot="title">Number of Payrolls</template>
          {{overall.count}}
        </info>
        <info v-if="overall">
          <template slot="title">Average Hourly Wage</template>
          $ {{overall.averageHourlyWage}}
        </info>
        <info>
          <template slot="title">Filter</template>
          <dropdown-date-range v-bind='{onSelect: handleDateRangeSelect, selectedOptionName: currentDateRange}'></dropdown-date-range>
        </info>
      </div>
      <admin-error :error="errorMsg"></admin-error>
      <h3 class="error" v-if="!gridData.length && !loading">No Data</h3>
      <div class="chart-component">
      </div>
      <table-grid
        v-if="gridData.length"
        class="table-block"
        :data="gridData"
        defaultSortColumn="pay_date"
        defaultSortOrder="-1"
        :columns="gridColumns">
          <template slot="rows" slot-scope="row">
            <td>
              <router-link :to="{name: 'payrollReport', params: {reportType: 'id', reportId: row.id}}">
                {{row.pay_date | numericDate}}
              </router-link> &nbsp; <small>({{row.start_date | numericDate}} - {{row.end_date | numericDate}})</small>
            </td>
            <td>{{row.users_count}}</td>
            <AdminPayrollHoursTd :value="row.total_hours"></AdminPayrollHoursTd>
            <AdminPayrollHoursTd :value="row.regular_hours"></AdminPayrollHoursTd>
            <AdminPayrollHoursTd :value="row.overtime_hours"></AdminPayrollHoursTd>
            <AdminPayrollHoursTd :value="row.holiday_hours"></AdminPayrollHoursTd>
            <AdminPayrollHoursTd :value="row.vacation_hours"></AdminPayrollHoursTd>
            <AdminPayrollCurrencyTd v-bind="{value: row.hourly_bonus}"></AdminPayrollCurrencyTd>
            <AdminPayrollCurrencyTd v-bind="{value: row.bonus}"></AdminPayrollCurrencyTd>
            <AdminPayrollCurrencyTd>{{row.total_wages}}</AdminPayrollCurrencyTd>
            <td></td>
          </template>
      </table-grid>
    </div>
  `,
};