import DateTime from './DateTime.js';
import Modal from './Modal.js';
import FormInput from './form/FormInput.js';

let lastStoredPunchIn = '';
let lastStoredPunchOut = '';

export default {
  name: `AdminEditPunchModal`,
  props: ['punch', 'onComplete'],
  components: {
    Modal,
    FormInput
  },
  data: function() {
    let punchIn = '', 
      punchOut = '';

    if (this.punch.punch_in) {
      punchIn = DateTime.inputDateTime(this.punch.punch_in);
    }
    if (this.punch.punch_out) {
      punchOut = DateTime.inputDateTime(this.punch.punch_out);
    }

    if (!punchIn && !punchOut) {
      if (lastStoredPunchIn) {
        punchIn = lastStoredPunchIn;
      }
      if (lastStoredPunchOut) {
        punchOut = lastStoredPunchOut;
      }
    }

    return {
      loading: false,
      errorMsg: null,
      punchIn,
      punchOut
    }
  },
  computed: {
    punchDetails () {
      
    },
    total_hours () {
      if (this.punchIn && this.punchOut) {
        const punchIn = new Date(this.punchIn);
        const punchOut = new Date(this.punchOut);
        const minutes = (punchOut - punchIn) / 1000 / 60;
        return DateTime.minutesToHours(minutes);
      }
      return '';
    }
  },
  methods: {
    handleClose() {
      this.onComplete();
    },
    handleSave() {
      this.savePunch();
    },
    handlePunchIn (value) {
      this.punchIn = value;
      lastStoredPunchIn = this.punchIn;
    },
    handlePunchOut (value) {
      this.punchOut = value;
      lastStoredPunchOut = this.punchOut;
    },
    savePunch () {
      if (!this.punchIn) {
        this.errorMsg = 'Must enter start time!';
        return;
      }
      
      this.loading = true;
      this.errorMsg = null;

      const punch = {
        in: this.punchIn,
        user_id: this.punch.user_id
      };

      if (this.punch.punch_id) {
        punch.punch_id = this.punch.punch_id;
      }

      if (this.punchOut) {
        punch.out = this.punchOut
      }

      this.$http.post('/punches/update', punch)
      .then(response => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        } else {
          this.errorMsg = null;
          this.onComplete();
        }

        this.loading = false;
      })
      .catch(error => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    }
  },
  filters: {
    ...DateTime
  },
    mounted () {
  },
  template: `
    <modal :onClose="handleClose">
      <div class="component-header">
        <h2 class="component-header-title">{{punch.punch_id ? 'Edit' : 'New'}} Punch</h2>
      </div>
      <div class="component-body">
        <form class="form form-punch" v-on:submit.prevent="handleSave">
          <h3 class="padding-bottom">{{punch.name}}</h3>

          <form-input label="Punch In" type="datetime-local" :value="punchIn" :onChange="handlePunchIn"></form-input>
          <form-input label="Punch Out" type="datetime-local" :value="punchOut" :onChange="handlePunchOut"></form-input>
          <form-input label="Total Hours" type="text" :value="total_hours" disabled="true"></form-input>
          
          <div class="error" v-if="errorMsg">{{errorMsg}}</div>
          <div class="buttons padding-top padding-bottom">
            <button class="button">{{loading ? 'Saving' : 'Save'}}</button> &nbsp;
            <a href="#" @click.prevent="handleClose" class="button button-cancel">Cancel</a>
          </div>
        </form>
      </div>
    </modal>
  `,
};