import AdminPayrollCalculationInfo from './AdminPayrollCalculationInfo.js';
import AdminPayrollHoursTd from './AdminPayrollHoursTd.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import DateTime from './DateTime.js';
import Loading from './Loading.js';
import DownloadCSV from './helpers/DownloadCsv.js';

import Dropdown from './Dropdown.js';
import DropdownDateRange from './DropdownDateRange.js';
import DropdownDateConstant from './constants/DropdownDateConstant.js';
import DateRangeHelper from './helpers/DateRangeHelper.js';

export default {
  name: `AdminPayrollSummaryEmployees`,
  components: {
    AdminPayrollCalculationInfo,
    AdminPayrollHoursTd,
    AdminPayrollCurrencyTd,
    Loading,
    Dropdown,
    DropdownDateRange,
    Name: {
      props: ['detail'],
      methods: {
        removeEmployee () {
          this.detail.hidden = true;
        }
      },
      template: `
        <div class="name">
          <router-link
          :to="{name: 'employees', params: {employeeId: detail.user_id}}">
            {{detail.first_name}} {{detail.last_name}}
          </router-link>
          <a href="#" v-on:click.prevent="removeEmployee" class="hide">Hide</a>
        </div>
      `
    },
    Info: {
      template: `
        <div class="info">
          <span class="title">
            <slot name="title"></slot>
          </span>
          <span class="value"><slot></slot></span>
        </div>
      `
    }
  },
  data () {
    return {
      currentDateRange: DropdownDateConstant.LAST_12_MONTHS,
      payrollData: null,
      payrollSummary: null,
      positions: [],
      loading: false,
      showNoteArea: false,
      note: '',
      startTime: 0,
      errorMsg: null
    }
  },
  computed: {
    position () {
      return this.$route.query.position;
    },
    dateRange () {
      const dateRange = DateRangeHelper.getDateRange(this.currentDateRange);
      return dateRange;
    },
    dropdownSelectedPosition () {
      if (this.positions.length) {
        let option = this.dropdownPositionOptions[0];
        if (this.position) {
          option = this.dropdownPositionOptions.find(item => item.name == this.position);
        }
  
        return option;
      }
    },
    dropdownPositionOptions () {
      let positions = [{
        name: null,
        label: 'All Positions'
      }];

      this.positions.forEach(position => {
        positions.push({
          name: position.position,
          label: position.position
        });
      });

      return positions;
    },
    totals () {
      const data = this.payrollSummary;
      if (!data) {
        return;
      }

      const totalsByPosition = {
        regular_hours: 0,
        regular_wages: 0,
        overtime_hours: 0,
        overtime_wages: 0,
        holiday_hours: 0,
        holiday_wages: 0,
        vacation_hours: 0,
        vacation_wages: 0,
        bonus: 0,
        total_wages: 0,
        employee_count: 0
      };

      data.details.forEach((item) => {
        if (!item.hidden) {
          totalsByPosition.regular_hours = window.numeral(item.regular_hours).add(totalsByPosition.regular_hours).format('0.00');
          totalsByPosition.regular_wages = window.numeral(item.regular_wages).add(totalsByPosition.regular_wages).format('0.00');
          totalsByPosition.overtime_hours = window.numeral(item.overtime_hours).add(totalsByPosition.overtime_hours).format('0.00');
          totalsByPosition.overtime_wages = window.numeral(item.overtime_wages).add(totalsByPosition.overtime_wages).format('0.00');
          totalsByPosition.holiday_hours = window.numeral(item.holiday_hours).add(totalsByPosition.holiday_hours).format('0.00');
          totalsByPosition.holiday_wages = window.numeral(item.holiday_wages).add(totalsByPosition.holiday_wages).format('0.00');
          totalsByPosition.vacation_hours = window.numeral(item.vacation_hours).add(totalsByPosition.vacation_hours).format('0.00');
          totalsByPosition.vacation_wages = window.numeral(item.vacation_wages).add(totalsByPosition.vacation_wages).format('0.00');
          totalsByPosition.bonus = window.numeral(item.bonus).add(totalsByPosition.bonus).format('0.00');
          totalsByPosition.total_wages = window.numeral(item.total_wages).add(totalsByPosition.total_wages).format('0.00');
          totalsByPosition.employee_count++;
        }
      });

      return totalsByPosition;
    }
  },
  watch: {
    position () {
      this.fetchPayrollSummary();
    },
    currentDateRange () {
      this.fetchPayrollSummary();
    }
  },
  methods: {
    handleDropdownSelectPosition (selectedOption) {
      const query = Object.assign({}, this.$route.query);
      if (selectedOption.name) {
        query.position = selectedOption.name;
      }
      else {
        delete query.position;
      }

      this.$router.replace({
        name: this.$route.name,
        params: this.$route.params,
        query
      });
    },
    handleDateRangeSelect (startDate, endDate, range) {
      this.currentDateRange = range;
    },
    pivotData (data) {
      if (!data) {
        return;
      }
      const totalsByPosition = {};
      console.log(data.pay_date);

      data.details.forEach((item) => {
        if (typeof item.hidden === 'undefined') {
          item.hidden = false;
        }
      });

      data.totals_by_position = totalsByPosition;

      this.payrollSummary = data;
    },
    fetchPositions() {
      this.$http.get('/positions/')
      .then((response) => {
        if (response.data.positions) {
          this.positions = response.data.positions;
        }
        else {
          this.positions = [];
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    fetchPayrollSummary () {
      this.loading = true;
      this.errorMsg = null;

      const requestOptions = {
        position: this.position,
        start_date: this.dateRange.startDate || null,
        end_date: this.dateRange.endDate || null
      };

      this.$http.get('/payroll/summary/employees', {
        params: requestOptions
      })
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        } else {
          if (response.data.payroll_summary) {
            this.pivotData(response.data.payroll_summary);
          }
          else {
            this.errorMsg = 'Payroll does not exist!';
          }
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },

    removeEmployee (detail) {
      this.payrollData.details.forEach((item) => {
        if (item.id === detail.id) {
          item.hidden = true;
        }
      });
    },
    exportCsv () {
      const data = this.payrollSummary;
      if (!data) {
        return;
      }

      const jsonData = [];
      const startDate = new Date(this.dateRange.startDate);
      const endDate = new Date(this.dateRange.endDate);
      const fileName = 'payroll_summary_employees_' + DateTime.inputDate(startDate) + '_' + DateTime.inputDate(endDate) + '.csv';

      data.details.forEach((item) => {
        if (!item.hidden) {
          const data = {
            'First Name': item.first_name,
            'Last Name': item.last_name,
          };

          data['Regular Hours'] = item.regular_hours != 0 ? window.numeral(item.regular_hours).format('0.00') : '';
          data['Regular Wages ($)'] = '$ ' + (item.regular_wages != 0 ? window.numeral(item.regular_wages).format('0.00') : '');
          data['Overtime Hours'] = item.overtime_hours != 0 ? window.numeral(item.overtime_hours).format('0.00') : '';
          data['Overtime Wages ($)'] = '$ ' + (item.overtime_wages != 0 ? window.numeral(item.overtime_wages).format('0.00') : '');
          data['Holiday Hours'] = item.holiday_hours != 0 ? window.numeral(item.holiday_hours).format('0.00') : '';
          data['Holiday Wages ($)'] = '$ ' + (item.holiday_wages != 0 ? window.numeral(item.holiday_wages).format('0.00') : '');
          data['Vacation Hours'] = item.vacation_hours != 0 ? window.numeral(item.vacation_hours).format('0.00') : '';
          data['Vacation Wages ($)'] = '$ ' + (item.vacation_wages != 0 ? window.numeral(item.vacation_wages).format('0.00') : '');
          data['Bonus ($)'] = '$ ' + (item.bonus != 0 ? window.numeral(item.bonus).format('0.00') : '');
          data['Total Wages ($)'] = '$ ' + (item.total_wages != 0 ? window.numeral(item.total_wages).format('0.00') : '');

          jsonData.push(data);
        }
      });

      jsonData.push({
        'First Name': ''
      });

      jsonData.push({
        'First Name': 'Start Date',
        'Last Name': DateTime.numericDate(startDate)
      });

      jsonData.push({
        'First Name': 'End Date',
        'Last Name': DateTime.numericDate(endDate)
      });

      DownloadCSV(jsonData, fileName);
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchPositions();
    this.fetchPayrollSummary();
  },
  template: `
    <div class="admin-payroll-report">
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li><span class="item">Payroll Summary By Employees</span></li>
        </ul>
        <ul class="component-options">            
          <li><router-link :to="{name: 'payrollSummaryPositions'}">By Positions</router-link></li>
          <li><router-link :to="{name: 'payrollSummaryMonths'}">By Months</router-link></li>
          <li><a href="#" v-on:click.prevent="exportCsv">
            Export CSV
          </a></li>
        </ul>
      </div>
      <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
      <div class="error" v-if="errorMsg">{{errorMsg}}</div>
      <div class="component-sub-header">
        <info>
          <template slot="title">Filter Date</template>
          <dropdown-date-range v-bind='{onSelect: handleDateRangeSelect, selectedOptionName: currentDateRange}'></dropdown-date-range>
        </info>
        <info>
          <template slot="title">Start Date</template>
          {{dateRange.startDate | humanDate}}
        </info>
        <info>
          <template slot="title">End Date</template>
          {{dateRange.endDate | humanDate}}
        </info>
        <info>
          <template slot="title">Filter Position</template>          
          <dropdown v-bind='{options: dropdownPositionOptions, selectedOption: dropdownSelectedPosition, onSelect: handleDropdownSelectPosition}' v-if="positions.length"></dropdown>
        </info>
      </div>
      <div class="payroll-report" v-if="payrollSummary">
        <h3 class="sub-header">By Name</h3>
        <table class="table">
          <thead>
            <tr>
              <th rowspan=2>Name</th>
              <th colspan=2>Regular</th>
              <th colspan=2>Overtime</th>
              <th colspan=2>Holiday</th>
              <th colspan=2>Vacation</th>
              <th rowspan=2>$ Bonus</th>
              <th rowspan=2>$ Total<br>Wages</th>
            </tr>
            <tr>
              <th>Hours</th>
              <th>Wages</th>
              <th>Hours</th>
              <th>Wages</th>
              <th>Hours</th>
              <th>Wages</th>
              <th>Hours</th>
              <th>Wages</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="detail in payrollSummary.details" v-if="!detail.hidden">
              <td>
                <name v-bind="{detail}"></name>
              </td>
              <admin-payroll-hours-td v-bind="{value: detail.regular_hours}"></admin-payroll-hours-td>
              <admin-payroll-currency-td v-bind="{value: detail.regular_wages}"></admin-payroll-currency-td>
              <admin-payroll-hours-td v-bind="{value: detail.overtime_hours}"></admin-payroll-hours-td>
              <admin-payroll-currency-td v-bind="{value: detail.overtime_wages}"></admin-payroll-currency-td>
              <admin-payroll-hours-td v-bind="{value: detail.holiday_hours}"></admin-payroll-hours-td>
              <admin-payroll-currency-td v-bind="{value: detail.holiday_wages}"></admin-payroll-currency-td>
              <admin-payroll-hours-td v-bind="{value: detail.vacation_hours}"></admin-payroll-hours-td>
              <admin-payroll-currency-td v-bind="{value: detail.vacation_wages}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: detail.bonus}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: detail.total_wages}"></admin-payroll-currency-td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>count {{totals.employee_count}}</th>
              <th class="hours">{{totals.regular_hours}}</th>
              <admin-payroll-currency-td v-bind="{value: totals.regular_wages}"></admin-payroll-currency-td>
              <th class="hours">{{totals.overtime_hours}}</th>
              <admin-payroll-currency-td v-bind="{value: totals.overtime_wages}"></admin-payroll-currency-td>
              <th class="hours">{{totals.holiday_hours}}</th>
              <admin-payroll-currency-td v-bind="{value: totals.holiday_wages}"></admin-payroll-currency-td>
              <th class="hours">{{totals.vacation_hours}}</th>
              <admin-payroll-currency-td v-bind="{value: totals.vacation_wages}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: totals.bonus}"></admin-payroll-currency-td>
              <admin-payroll-currency-td v-bind="{value: totals.total_wages}"></admin-payroll-currency-td>
            </tr>
          </tfoot>
        </table>
      </div>
      <AdminPayrollCalculationInfo></AdminPayrollCalculationInfo>
    </div>
  `,
};