import Modal from './Modal.js';
import Loading from './Loading.js';
import FormInput from './form/FormInput.js';

export default {
  name: 'AdminVendorEditModal',
  components: {
    FormInput,
    Modal,
    Loading
  },
  props: ['item', 'onUpdate'],
  data () {
    return {
      loading: false,
      errorMsg: null,
      success: null,
      updatedItem: {}
    }
  },
  methods: {
    handleChangeVendor (value) {
      this.updatedItem.name = value.trim();
    },
    handleChangeWebsite (value) {
      this.updatedItem.website = value.trim();
    },
    handleChangePhoneNumber (value) {
      this.updatedItem.phone_number = value.trim();
    },
    handleChangeUrl (value) {
      this.updatedItem.url = value.trim();
    },
    handleChangeInvoiceUrl (value) {
      this.updatedItem.invoice_url = value.trim();
    },
    handleCancel() {
      this.onUpdate('Cancelled!');
    },
    handleItemDelete() {
      this.updatedItem.active = 0;
      this.handleItemUpdate();
    },
    handleItemUpdate () {
      if (Object.keys(this.updatedItem).length) {
        if (this.item.id && this.item.id !== 'new') {
          this.updatedItem.id = this.item.id;
        }
        else if (this.item.id === 'new') {
          this.updatedItem.product_item_id = this.item.product_item_id;
        }
        this.saveItem();
      }
      else {
        this.onUpdate('No Change!');
      }
    },
    saveItem () {
      this.loading = true;
      this.errorMsg = null;
      this.$http.post('/vendors/', this.updatedItem)
        .then((response) => {
          if (response.data.item) {
            this.errorMsg = null;
            this.$toast.info((this.updatedItem.id ? 'Updated' : 'Added') + ': ' + this.item.name);
            this.onUpdate('Updated');
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  template: ` 
    <modal>
      <div class="component-header">
        <h2 class="component-header-title">
        {{item.id == 'new' ? 'Add' : 'Edit'}} Item
        </h2>
      </div>
      <div class="component-body">
        <form class="form" v-on:submit.prevent="handleItemUpdate">
          <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
          <form-input label="Vendor" :value="item.name" :onChange="handleChangeVendor" placeholder="Vendor"></form-input>
          <form-input label="Phone Number" :value="item.phone_number" :onChange="handleChangePhoneNumber"></form-input>
          <form-input label="Website" :value="item.website" :onChange="handleChangeWebsite"></form-input>
          <form-input label="Item url" :value="item.url" :onChange="handleChangeUrl"></form-input>
          <form-input label="Invoice url" :value="item.invoice_url" :onChange="handleChangeInvoiceUrl"></form-input>
          <div class="error" v-if="errorMsg">{{errorMsg}}</div>
          <button class="button">
            {{item.id == 'new' ? 'Add' : 'Update'}}
          </button>
          <a href="#" v-on:click.prevent="handleCancel">Cancel</a>
        </form>
      </div>
    </modal>
  `
};