import TableGrid from './TableGrid.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import Loading from './Loading.js';
import DateTime from './DateTime.js';
import AdminPurchaseOrderEditModal from './AdminPurchaseOrderEditModal.js';
import AdminPurchaseOrderAdd from './AdminPurchaseOrderAdd.js';
import DropdownDateRange from './DropdownDateRange.js';

import DropdownDateConstant from './constants/DropdownDateConstant.js';
import DateRangeHelper from './helpers/DateRangeHelper.js';

const PO_TYPES = {
  vendor_id: 'Vendor',
  order_number: 'Order Number',
  product_item_id: 'Item'
};

export default {
  name: `AdminPurchaseOrders`,
  components: {
    Loading,
    TableGrid,
    AdminPayrollCurrencyTd,
    AdminPurchaseOrderEditModal,
    AdminPurchaseOrderAdd,
    DropdownDateRange,
    Tag: {
      props: ['tags'],
      computed: {
        t () {
          return this.tags ? this.tags.split(',') : [];
        }
      },
      template: `
        <ul class="tags">
          <li v-for="tag in t"><router-link :to='{name: "purchaseOrders", query: {label: tag}}' class="tag small">{{tag}}</router-link></li>
        </ul>
      `
    },
    Info: {
      template: `
        <div class="info">
          <span class="title">
            <slot name="title"></slot>
          </span>
          <span class="value"><slot></slot></span>
        </div>
      `
    }
  },
  data () {
    const gridColumns = [
      {key: 'product', value: 'Item'},
      {key: 'qty', value: 'Pkg Qty', type: Number},
      {key: 'order_qty', value: 'Order Qty', type: Number},
      {key: 'total_cost', value: 'Total Cost', type: Number},
      {key: 'ordered_on', value: 'Purchase Date'},
      {key: 'order_number', value: 'Order Number'},
      {key: 'vendor', value: 'Vendor'},
      {key: '', value: ''},
    ];

    return {
      purchaseOrders: null,
      loading: false,
      gridColumns,
      gridData: [],
      itemsToShow: null,
      itemToEdit: null,
      errorMsg: null,
      totals: null,
      purchaseOrderToEdit: null,
      currentDateRange: DropdownDateConstant.LAST_12_MONTHS,
    };
  },
  computed: {
    field () {
      return this.$route.params.field;
    },
    fieldValue () {
      return this.$route.params.fieldValue;
    },
    query () {
      return this.$route.query;
    },
    dateRange () {
      const dateRange = DateRangeHelper.getDateRange(this.currentDateRange);
      return dateRange;
    },
    isAddNewPO() {
      return this.query.action === 'addNewPO';
    }
  },
  watch: {
    itemsToShow () {
      this.pivotData();
    },
    field () {
      this.fetchPurchaseOrders();
    },
    query () {
      //@todo get calls multiple times (onMounted & this when the component loads);
      this.fetchPurchaseOrders();
    },
    currentDateRange() {
      this.fetchPurchaseOrders();
    }
  },
  methods: {
    handleDateRangeSelect (startDate, endDate, range) {
      this.currentDateRange = range;
    },
    handleNew () {
      this.itemToEdit = {
        id: 'new'
      };
    },
    handlePurchaseOrderEdit (item) {
      this.purchaseOrderToEdit = item;
    },
    handlePurchaseOrderNew (item) {
      console.log(item);
      const purchaseOrder = Object.assign({}, item);
      purchaseOrder.id = 'new';
      delete purchaseOrder.order_number;
      delete purchaseOrder.ordered_on;
      this.purchaseOrderToEdit = purchaseOrder;
    },
    handlePurchaseOrderEditSuccess () {
      this.purchaseOrderToEdit = null;
      this.fetchPurchaseOrders();
    },
    fetchPurchaseOrders () {
      this.loading = true;
      
      this.$http.get('/product_orders/', {
        params: {
          search: {
            [this.field]: this.fieldValue
          },
          start_date: this.dateRange.startDate || null,
          end_date: this.dateRange.endDate || null,
          tag: this.query.label
        }
      })
      .then((response) => {
        if (!response.data.error) {
          this.purchaseOrders = response.data.items;
          this.pivotData();
        }
        else {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    pivotData () {
      const purchaseOrders = this.purchaseOrders;
      const gridData = [];
      const totals = {
        itemCount: 0,
        totalCost: 0,
        orderCount: 0
      };

      const orderNumbers = {};

      purchaseOrders.forEach(item => {
        if (!this.itemsToShow || item.item === this.itemsToShow) {
          item.buy_url = item.url.replace('%s', item.vendor_product_id);

          if (item.invoice_url) {
            const date = DateTime.isoDate(item.ordered_on);
            let month = date.getMonth() + 1;
            month = (month < 10 ? '0' : '') + month;
            item.invoice_url = item.invoice_url.replace('%s', item.order_number).replace('%yyyy', date.getFullYear()).replace('%mm', month).replace('%dd', date.getDate());
          }
          gridData.push(item);

          totals.totalCost += parseFloat(item.total_cost, 10); 
          totals.itemCount++;

          if (!orderNumbers[item.order_number]) {
            orderNumbers[item.order_number] = true;
            totals.orderCount++;
          }
        }
      });

      totals.totalCost = parseInt(totals.totalCost, 10);
      this.totals = totals;
      this.gridData = gridData;
    }
  },
  filters: {
    fieldToHuman(str) {
      return PO_TYPES[str] || str;
    },
    ...DateTime
  },
  mounted () {
    this.fetchPurchaseOrders();
    console.log(this.$route.query);
  },
  template: `
    <div class="admin-purchase-orders-component component-fixed">
      <div class="component-header">
        <ul class="nav">
          <li>
            <router-link :to='{name: "home"}' class="item">Home</router-link>
          </li>
          <li>
            <router-link :to='{name: "purchaseOrders"}' v-if="field || this.query.label" class="item">Purchase Orders</router-link>
            <span class="item" v-else>Purchase Orders</span>
          </li>
          <li v-if="field">
            <span class="item">By {{field | fieldToHuman}}</span>
          </li>
          <li v-if="this.query.label">
            <span class="item">Filter By Label {{this.query.label}}</span>
          </li>
        </ul>
        <ul class="component-options">
          <li><router-link :to='{name: "purchaseOrdersByLabel"}'>Group By Label</router-link></li>
          <li><router-link :to='{name: "purchaseOrdersGroupBy"}'>Group By Items</router-link></li>
          <li><router-link :to='{name: "shopProductDetails", params: {productId: fieldValue}}' v-if="field == 'product_item_id' && fieldValue">Item Details</router-link></li>
          <li><router-link :to='{name: "vendors"}'>Vendors</router-link></li>
          <li><router-link :to='{name: "shop"}'>Shop Products</router-link></li>
        </ul>
      </div>
      <section class="component-sub-header" v-if="totals">
        <info>
          <template slot="title">Items Ordered</template>
          {{totals.itemCount}}
        </info>
        <info>
          <template slot="title">Total cost</template>
          $ {{totals.totalCost}}
        </info>
        <info>
          <template slot="title"># of Orders</template>
          {{totals.orderCount}}
        </info>
        <info>
          <template slot="title">Filter</template>
          <dropdown-date-range v-bind='{onSelect: handleDateRangeSelect, selectedOptionName: currentDateRange}'></dropdown-date-range>
        </info>
      </section>
      <loading v-bind="{loading: loading}"></loading>
      <div class="error" v-if="errorMsg">{{errorMsg}}</div>
      <admin-purchase-order-edit-modal v-bind="{item: purchaseOrderToEdit, onUpdate: handlePurchaseOrderEditSuccess}" v-if="purchaseOrderToEdit"></admin-purchase-order-edit-modal>

      <table-grid
        class="table-block"
        :data="gridData"
        defaultSortColumn="purchased_on"
        defaultSortOrder="-1"
        :columns="gridColumns"
        v-if="gridData.length">
          <template slot="rows" slot-scope="row">
            <td>
              <router-link :to="{name: 'purchaseOrders', params: {field: 'product_item_id', fieldValue: row.product_item_id}}">
                {{row.product}}
              </router-link>
              <tag :tags="row.tags"></tag>
            </td>
            <td>
              {{row.qty}}
            </td>
            <td>
              {{row.order_qty}} <small class="muted">({{row.old_qty}})</small>
            </td>
            <AdminPayrollCurrencyTd v-bind="{value: row.total_cost}"></AdminPayrollCurrencyTd>
            <td>
              {{row.ordered_on | numericDate}}
            </td>
            <td>
              <ul class="options">
                <li>
                  <router-link :to="{name: 'purchaseOrders', params: {field: 'order_number', fieldValue: row.order_number}}">
                    {{row.order_number}}
                  </router-link>
                </li>
                <li v-if="row.invoice_url"><a :href="row.invoice_url" target="_blank">&#10532;</a></li>
              </ul>  
            </td>
            <td>
              <router-link :to="{name: 'purchaseOrders', params: {field: 'vendor_id', fieldValue: row.vendor_id}}">
                Only
              </router-link>
              <router-link :to="{name: 'vendorProducts', params: {vendorId: row.vendor_id}}">
                {{row.vendor}}
              </router-link>
            </td>
            <td>
              <ul class="options-buttons">
                <li><a :href="row.buy_url" class="link" target="_blank">Buy</a></li>
                <li><a href="#" v-on:click.prevent="handlePurchaseOrderEdit(row)" class="link">Edit PO</a></li>
                <li><a href="#" v-on:click.prevent="handlePurchaseOrderNew(row)">Add PO</a></li>
              </ul>  
            </td>
          </template>
      </table-grid>
      <AdminPurchaseOrderAdd v-if="isAddNewPO"></AdminPurchaseOrderAdd>
    </div>
  `,
};