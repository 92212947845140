
import Loading from './Loading.js';
import FormInput from './form/FormInput.js';

export default {
  name: `AdminMe`,
  components: {
    Loading,
    FormInput
  },
  props: ['onBoarding'],
  data () {
    return {
      loading: false,
      errorMsg: null,
      updatedUser: {}
    }
  },
  computed: {
    user () {
      return this.$store.state.user;
    }
  },
  watch: {
    
  },
  methods: {
    handleChangeUser (value, name) {
      this.updatedUser[name] = value.trim();
    },
    handleUserUpdate () {
      if (this.updatedUser.first_name || this.updatedUser.last_name) {
        this.updateUser();        
      }
      else {
        this.errorMsg = 'Nothing to Update!';
      }
    },
    updateUser() {
      this.loading = true;
    
      this.$http.post('/users/me', this.updatedUser)
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        } else {
          this.$store.dispatch('fetchConfig');
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    }
  },
  mounted () {
    window.setTimeout(() => {this.$refs.form.querySelector('input').focus()}, 1000);
  },
  template: `
    <div class="admin-me">
      <div class="component-header">
        <h2 class="component-header-title">My Profile</h2>
      </div>
      <loading v-bind="{loading}"></loading>
      <div class="component-body onboarding-component">
        <div>
          <div v-if="onBoarding">
            <h2>Welcome, </h2>
            <p>Thank you for signing up with us! We are excited to have you on board. Please take a moment to update your profile.</p>
          </div>
          <h3 class="component-title" v-else>My Profile:</h3>
          <form v-on:submit.prevent="handleUserUpdate" ref="form">
            <form-input label="First Name" :value="user.first_name" name="first_name" placeholder="Enter First Name"  :onChange="handleChangeUser" />
            <form-input label="Last Name" class="padding-top" :value="user.last_name" name="last_name" placeholder="Enter Last Name" :onChange="handleChangeUser" />
            <div class="error error-block" v-if="errorMsg">{{errorMsg}}</div>
            <div class="buttons padding-top">
              <button class="button">
                Update
                <span class="button-loading" v-if="loading"></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  `,
};