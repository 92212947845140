
import AdminEditPunchModal from './AdminEditPunchModal.js';
import AdminEmployeesApi from '../apis/AdminEmployeesApi.js';
import DateTime from './DateTime.js';
import Loading from './Loading.js';
import TableGrid from './TableGrid.js';

export default {
  name: 'AdminEmployeePunches',
  mixins: [AdminEmployeesApi],
  components: {
    TableGrid,
    Loading,
    AdminEditPunchModal
  },
  data() {
    const gridColumns = [
      {key: 'action', value: ''},
      {key: 'action2', value: ''},
      {key: 'date', value: 'Date', type: Number},
      {key: 'day', value: 'Day'},
      {key: 'inTime', value: 'Punch In'},
      {key: 'outTime', value: 'Punch Out'},
      {key: 'actualHours', value: 'Actual Hours'},
      {key: 'roundedHours', value: 'Rounded Hours'},
    ];

    return {
      punch: null,
      loading: false,
      pivotedPunches: [],
      gridColumns,
      employee: null,
      errorMsg: null
    };
  },
  props: ['employeeId'],
  computed: {},
  watch: {
    employeeId () {
      this.fetchEmployee();
      this.fetchPunches();
    }
  },
  methods: {
    handlePunchUpdate() {
      this.fetchPunches();
      this.punch = null;
    },
    handleEditPunch(punch) {
      this.punch = {
        name: this.employee.first_name + ' ' + this.employee.last_name,
        punch_id: punch.punch.id,
        punch_in: punch.punch.punch_in,
        punch_out: punch.punch.punch_out,
        user_id: punch.punch.user_id
      };
    },
    handleAddPunch() {
      this.punch = {
        name: this.employee.first_name + ' ' + this.employee.last_name,
        user_id: this.employee.id
      };
    },
    handleApprovePunch(punch) {
      console.log(punch);
      this.postApprovePunch(punch.punch.id);
    },
    postApprovePunch(punchId) {
      this.loading = true;

      this.$http.post('/punches/approve', {
          punch_id: punchId
        })
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.error;
          } else {
            this.$toast.info('Punch approved.');
            this.fetchPunches();
          }
        })
        .catch((error) => {
          this.errorMsg = error.response;
        })
        .finally(() => {
          this.loading = false;
          if (this.errorMsg) {
            this.$toast.error(this.errorMsg);
          }
        });
    },
    fetchPunches() {
      this.loading = true;

      this
        .fetchEmployeePunches(this.employeeId)
        .then((response) => {
          if (response.data.error) {
            this.errorMsg = response.data.error;
          } else {
            this.pivotData(response.data.punches);
          }  
        })
        .catch((error) => {
          this.errorMsg = error.response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pivotData(punches) {
      const pivotedPunches = [];
      punches.forEach((punch) => {
        const inDate = new Date(punch.punch_in);
        const outDate = new Date(punch.punch_out);

        pivotedPunches.push({
          punchId: punch.id,
          date: DateTime.numericDate(inDate),
          dateForLink: DateTime.inputDate(inDate),
          day: DateTime.humanWeekDay(inDate),
          inTime: DateTime.humanTime(inDate),
          outTime: punch.punch_out ? DateTime.humanTime(outDate) : null,
          actualHours: punch.actual_time_minutes > 0 ? DateTime.minutesToHours(punch.actual_time_minutes) : '',
          roundedHours: punch.rounded_time_minutes > 0 ? DateTime.minutesToHours(punch.rounded_time_minutes) : '',
          rounded_time_minutes: punch.rounded_time_minutes,
          nextDay: punch.punch_out ? inDate.getDate() != outDate.getDate() : false,
          punch: punch
        });
      });
      this.pivotedPunches = pivotedPunches;
    },
    fetchEmployee () {
      this.loading = true;
      this
        .fetchUser(this.employeeId)
        .then((response) => {
          if (response.data.user) {
            this.employee = response.data.user;
          } else if (response.data.error) {
            this.errorMsg = response.data.error;
          } 
        })
        .catch((error) => {
          this.errorMsg = error.response;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    console.log('mounted', 'fetchPunches');
    this.fetchEmployee();
    this.fetchPunches();
  },
  filters: {
    ...DateTime,
    integerCurrency(num) {
      return window.numeral(num).format('0,000');
    }
  },
  template: `
    <div class="admin-employee-punches-component">
      <section class="component-header">
        <ul class="component-options">
          <li>
            <a href="#" v-on:click.prevent="handleAddPunch" v-if="employee && employee.active">Add a Punch</a>
          </li>
          <li>
            <router-link
              :to="{name: 'employees', params: {employeeId: employeeId}}">
              Profile
            </router-link>
          </li>
          <li>
            <router-link
            :to="{name: 'employees', params: {employeeId: employeeId, action: 'paychecks'}}">
              Pay History
            </router-link>
          </li>
        </ul>
      </section>

      <loading v-bind="{loading: loading}"></loading>
      <div class="error error-block" v-if="errorMsg">{{errorMsg}}</div>
      <section class="component-content" v-if="employee">
        <admin-edit-punch-modal v-bind="{punch: punch, onComplete: handlePunchUpdate}" v-if="punch"></admin-edit-punch-modal>
        <h3 class="name">
          Punches for {{employee.first_name}} {{employee.last_name}}
          <span class="inactive" v-if="!employee.active">Inactive</span>
        </h3>
        <section class="grid-2">
          <div class="info">
            <span class="title">Hire Date</span>
            <span class="value" v-if="employee.hire_date">{{employee.hire_date | numericDate}}</span>
          </div>
          <div class="info end-date">
            <span class="title">End Date</span>
            <span class="value" v-if="employee.end_date">{{employee.end_date | numericDate}}</span>
          </div>
        </section>
        <h3>Last 30 days</h3>

        <table-grid
          class="table-block"
          :data="pivotedPunches"
          defaultSortColumn="date"
          defaultSortOrder="1"
          :columns="gridColumns">
          <template slot="rows" slot-scope="row">
            <td>
              <a href="#" v-on:click.prevent="handleEditPunch(row)" class="button button-table button-small" v-if="row.punch.is_approved != 1">Edit</a>
            </td>
            <td :data-is-approved="row.is_approved">
              <span v-if="!row.attendanceType">
                <span v-if="row.punch.is_approved == 1">&checkmark;</span>
                <a href="#" v-on:click.prevent="handleApprovePunch(row)" class="button button-table button-small" v-else>Approve</a>
              </span>
            </td>
            <td>
              <router-link
                :to="{name: 'dailyPunches', params: {date: row.dateForLink}}">
                {{row.date}}
              </router-link>
            </td>
            <td>{{row.day}}</td>
            <td>{{row.inTime}}</td>
            <td>{{row.outTime}} <span class="next-day" v-if="row.nextDay">(Next Day)</span><span class="still-here" v-if="!row.outTime">Working</span></td>
            <td>{{row.actualHours}}</td>
            <td :class="{highlight: row.rounded_time_minutes > 480}">{{row.roundedHours}}</td>
          </template>
        </table-grid>
      </section>
    </div>
  `
};