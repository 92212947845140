import Loading from './Loading.js';
import AdminHotelSectionEditModal from './AdminHotelSectionEditModal.js';
import AdminHotelRoomEditModal from './AdminHotelRoomEditModal.js';

export default {
  name: 'AdminHotelRooms',
  components: {
    Loading,
    AdminHotelRoomEditModal,
    AdminHotelSectionEditModal
  },
  data() {
    return {
      success: null,
      loading: false,
      sections: null,
      updatedSections: [],
      sectionToEdit: null,
      roomToEdit: null,
      updatedSectionsCount: 0
    };
  },
  computed: {
    
  },
  watch: {

  },
  methods: {
    handleAddRoom (sectionId) {
      this.roomToEdit = {
        id: 'new',
        hotel_section_id: sectionId
      };
    },
    handleEditRoom (room) {
      this.roomToEdit = room;
    },
    handleAddSection () {
      this.sectionToEdit = {
        id: 'new'
      };
    },
    handleEditSection (section) {
      this.sectionToEdit = section;
    },
    clearSuccess () {
      this.success = null;
    },
    handleEditSuccess(success) {
      this.$toast.info(success);
      this.sectionToEdit = null;
      this.roomToEdit = null;
      this.fetchHotelSections();
    },
    pivotData (sections) {
      sections.forEach(section => {
        const roomsCountByUsage = {};
        section.rooms.forEach(room => {
          if (!roomsCountByUsage[room.hotel_room_usage]) {
            roomsCountByUsage[room.hotel_room_usage] = 0;
          }

          roomsCountByUsage[room.hotel_room_usage]++;
        });
        section.roomsCountByUsage = roomsCountByUsage;
      });

      this.sections = sections;
    },
    fetchHotelSections () {
      this.loading = true;
      this.$http.get('/v0/hotel/sections')
        .then((response) => {
          if (response.data.sections) {
            this.errorMsg = null;
            this.pivotData(response.data.sections);
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {
    this.fetchHotelSections();
  },
  filters: {
    
  },
  template: `
    <div class="admin-hotel-rooms-component component-fixed">
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li><router-link :to="{name: 'hotel'}" class="item">Hotel</router-link></li>
          <li><span class="item">Hotel Sections/Rooms Layout</span></li>
        </ul>
        <ul class="component-options">
          <li>
            <a href="#" v-on:click.prevent="handleAddSection('add')">New Section</a>
          </li>
        </ul>
      </div>
      <loading v-bind="{loading: loading}"></loading>
      <admin-hotel-section-edit-modal v-bind="{section: sectionToEdit, onUpdate: handleEditSuccess}" v-if="sectionToEdit"></admin-hotel-section-edit-modal>
      <admin-hotel-room-edit-modal v-bind="{room: roomToEdit, onUpdate: handleEditSuccess}" v-if="roomToEdit"></admin-hotel-room-edit-modal>
      <section class="component-body">
        <div v-for="hotelSection in sections"  v-if="sections && sections.length">
          <div class="grid-2">
            <h4>
              {{hotelSection.section}} 
              <a href="#" v-on:click.prevent="handleEditSection(hotelSection)" class="button button-small">Edit</a>
            </h4>
            <span class="muted">{{hotelSection.roomsCountByUsage.guest}} Guest Rooms, {{hotelSection.roomsCountByUsage.other}}  Other Rooms</span>
          </div>
          <div class="rooms-list">
            <div v-for="room in hotelSection.rooms" :class="{'item': true}">
              <a href="#" v-on:click.prevent="handleEditRoom(room)" class="link">{{room.room}} <span class="type">{{room.hotel_room_type}}</span></a>
            </div>
            <div class="item">
              <a href="#" v-on:click.prevent="handleAddRoom(hotelSection.id)" class="link add-room-link">+ <span class="text">ROOM</span></a>
            </div>
          </div>
        </div>
        
        <div class="no-sections" v-if="sections && !sections.length">
          <h4>You don't have any rooms or buildings!</h4>
          <p>Please, add a building/floor and then add rooms to the floor</p>
        </div>

        <details>
          <summary>Hints</summary>
          <ul>
            <li>1 Building with 1 floor. Add a section with a name of the building.</li>
            <li>1 building with 3 floors. Add sections: 1st Floor, 2nd Floor, 3rd Floor</li>
            <li>2 buildings with 1 floor. Add sections: Building A, Building B</li>
            <li>2 buildings with 2 floor. Add sections: A: 1st Floor, A: 2nd Floor, B: 1st Floor, B: 2nd Floor</li>
          </ul>
        </details>

      </section>
    </div>
  `
};