import DateTime from './DateTime.js';
import Loading from './Loading.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import AdminEmployeesApi from '../apis/AdminEmployeesApi.js';
import AdminEmployeeSalaryEditModal from './AdminEmployeeSalaryEditModal.js';

export default {
  name: 'AdminEmployeeSalary',
  mixins: [AdminEmployeesApi],
  components: {
    AdminEmployeeSalaryEditModal,
    AdminPayrollCurrencyTd,
    Loading
  },
  props: ['employee', 'onEmployeeSalaryUpdate'],
  data() {
    return {
      loading: false,
      salaryUpdateMsg: null,
      salaryToEdit: null
    };
  },
  computed: {
    
  },
  watch: {
  },
  methods: {
    handleAddNewPosition () {
      let position = this.employee.positions.length ? this.employee.positions[0] : null;

      this.salaryToEdit = {
        user_id: this.employee.id,
        global_position_id: position ? position.global_position_id : null,
        rate: '',
        started_on: DateTime.inputDate(+new Date()),
        is_hourly: 1
      };
    },
    handleEditPosition (position) {
      console.log(position);
      this.salaryToEdit = position;
    },
    handleSalaryUpdate (salaryUpdateMsg) {
      this.salaryToEdit = null;
      this.$toast.show(salaryUpdateMsg);
      this.onEmployeeSalaryUpdate();
    }
  },
  mounted() {

  },
  filters: {
    ...DateTime,
  },
  template: `
    <div class="admin-employee-salary-component">
      <admin-employee-salary-edit-modal v-bind="{salaryDetails: salaryToEdit, onUpdate: handleSalaryUpdate}" v-if="salaryToEdit"></admin-employee-salary-edit-modal>
      <loading v-bind="{loading: loading}"></loading>
      <h3 class="component-sticky-title">Positions & Wages &nbsp; <a href="#" v-on:click.prevent="handleAddNewPosition" class="button button-medium">Add</a></h3>
      <div class="error" v-if="employee.positions && !employee.positions.length">Missing Salary</div>
      <table class="table table-block">
        <thead>
          <tr>
            <th rowspan=2>Start Date</th>
            <th rowspan=2>Position</th>
            <th colspan=2>Wages</th>
            <th rowspan=2>Hourly<br>Attendance<br>Bonus</th>
            <th colspan=3>Weekend Premium</th>
            <th></th>
          </tr>
          <tr>
            <th>Hourly</th>
            <th>Salary</th>
            <th>Fri</th>
            <th>Sat</th>
            <th>Sun</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in employee.positions">
            <td>
            {{row.started_on | numericDate}}
            </td>
            <td>
              {{row.position}}
            </td>
            <td class="right"><span class="auto-currency" data-currency="$" v-if="row.is_hourly == 1">{{row.rate}}</span></td>
            <td class="right"><span class="auto-currency" data-currency="$" v-if="row.is_hourly == 0">{{row.rate}}</span></td>

            <td class="right"><span class="auto-currency" data-currency="$">{{row.bonus_rate}}</span></td>
            <td class="right"><span class="auto-currency" data-currency="$">{{row.premium_friday}}</span></td>
            <td class="right"><span class="auto-currency" data-currency="$">{{row.premium_saturday}}</span></td>
            <td class="right"><span class="auto-currency" data-currency="$">{{row.premium_sunday}}</span></td>
            <td><a href="#" v-on:click.prevent="handleEditPosition(row)" class="button button-table button-small">View</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  `
};