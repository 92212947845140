import DateTime from './DateTime.js';
import Helper from './Helper.js';
import Loading from './Loading.js';
import FormInput from './form/FormInput.js';
import FormSelect from './form/FormSelect.js';


export default {
  name: 'AdminHotelEdit',
  components: {
    Loading,
    FormInput,
    FormSelect
  },
  props: ['onBoarding'],
  data() {
    return {
      loading: false,
      errorMsg: null,
      success: null,
      updatedHotel: {},
    };
  },
  computed: {
    hotel () {
      return this.$store.state.hotel;
    },
    user () {
      return this.$store.state.user;
    },
    activeEmployees () {
      return this.$store.state.activeEmployees;
    },
  },
  watch: {

  },
  methods: {
    handleHotelChange (value, name) {
      this.updatedHotel[name] = value.trim();
    },
    handleUpdate () {
      if (!this.hotel.name && (!this.updatedHotel.name || this.updatedHotel.name === '')) {
        this.errorMsg = 'Hotel Name is required';
      }
      else if (Object.keys(this.updatedHotel).length) {
        this.updatedHotel.id = this.hotel.id;
        this.saveHotel();
      }
    },
    clearSuccess () {
      this.success = null;
    },
    saveHotel () {
      this.loading = true;
      this.$http.post('/v0/hotel/update', this.updatedHotel)
        .then((response) => {
          if (response.data.hotel) {
            this.errorMsg = null;
            this.updatedHotel = {};
            this.$store.dispatch('setHotel', response.data.hotel);
            this.$toast.info('Updated!');
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchHotel () {
      this.loading = true;
      this.$http.get('/v0/hotel/')
        .then((response) => {
          if (response.data.hotel) {
            this.errorMsg = null;
            this.$store.dispatch('setHotel', response.data.hotel);
          }
          if (response.data.timezones) {
            const timezones = {};
            response.data.timezones.forEach(timezone => {
              timezones[timezone] = timezone;
            });

            this.timezones = timezones;
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {
    this.fetchHotel();
    
    window.setTimeout(() => {this.$refs.form.querySelector('input').focus()}, 500);
  },
  filters: {
    ...DateTime,
    formatPhoneNumber: Helper.formatPhoneNumber,
  },
  template: `
    <div class="admin-edit-org-component">
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li><span class="item">Hotel Edit</span></li>
        </ul>
      </div>
      <section :class="['component-body', onBoarding ? 'onboarding-component' : '']">
        <loading v-bind="{loading: loading}"></loading>
        <div v-if="onBoarding" class="padding-bottom">
          <h2>{{user.first_name}},</h2>
          <p>Let's get started by adding your hotel information.</p>
        </div>
        <h3 class="component-title" v-else>Hotel:</h3>
        <form class="form" v-on:submit.prevent="handleUpdate" v-if="hotel" ref="form">
          <form-input label="Hotel Name" type="text" :value="hotel.name" name="name" :onChange="handleHotelChange"></form-input>
          <form-input label="Street" type="text" :value="hotel.address_street" name="address_street" :onChange="handleHotelChange"></form-input>
          <div class="grid-3">
            <form-input label="City" type="text" :value="hotel.address_city" name="address_city" :onChange="handleHotelChange"></form-input>
            <form-input label="State" type="text" :value="hotel.address_state" name="address_state" :onChange="handleHotelChange"></form-input>
            <form-input label="Zip" type="number" :value="hotel.address_zip" name="address_zip"  :onChange="handleHotelChange"></form-input>
          </div>
          <div class="success" v-if="success">{{success}}</div>
          <div class="error" v-if="errorMsg">{{errorMsg}}</div>
          <div class="row buttons padding-top">
            <button :class="{button: 1, 'button-loading': loading}">
              Update
            </button>
          </div>
        </form>
      </section>
    </div>
  `
};