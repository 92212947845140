import DropdownDateConstant from '../constants/DropdownDateConstant.js';
import DateTime from '../DateTime.js';

const getDateRange = (range) => {
  let startDate = new Date();
  let endDate = new Date();

  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);
  endDate.setHours(0);
  endDate.setMinutes(0);
  endDate.setSeconds(0);
  endDate.setMilliseconds(0);

  switch (range) {
    case DropdownDateConstant.LAST_12_MONTHS:
      startDate.setYear(startDate.getFullYear() - 1);
      break;
    case DropdownDateConstant.YEAR_TO_DATE:
      startDate.setMonth(0);
      startDate.setDate(1);
      //endDate = null;
      break;
    case DropdownDateConstant.LAST_YEAR:
      startDate.setMonth(0);
      startDate.setDate(1);
      startDate.setYear(startDate.getFullYear() - 1);
      endDate.setMonth(0);
      endDate.setDate(0);
      console.log('last_year');
      break;
    case DropdownDateConstant.YEAR_2018:
      startDate.setMonth(0);
      startDate.setDate(1);
      startDate.setYear(2018);
      endDate.setMonth(0);
      endDate.setDate(0);
      endDate.setYear(2018);
      console.log('last_year');
      break;
    case DropdownDateConstant.YEAR_2019:
      startDate.setMonth(0);
      startDate.setDate(1);
      startDate.setYear(2019);
      endDate.setMonth(0);
      endDate.setDate(0);
      endDate.setYear(2019);
      console.log('last_year');
      break;
    case DropdownDateConstant.YEAR_2020:
      startDate.setMonth(0);
      startDate.setDate(1);
      startDate.setYear(2020);
      endDate.setMonth(0);
      endDate.setDate(0);
      endDate.setYear(2020);
      console.log('last_year');
      break;
    case DropdownDateConstant.YEAR_2021:
      startDate.setMonth(0);
      startDate.setDate(1);
      startDate.setYear(2021);
      endDate.setMonth(0);
      endDate.setDate(0);
      endDate.setYear(2021);
      console.log('last_year');
      break;
    case DropdownDateConstant.YEAR_2022:
      startDate.setMonth(0);
      startDate.setDate(1);
      startDate.setYear(2022);
      endDate.setMonth(0);
      endDate.setDate(0);
      endDate.setYear(2022);
      console.log('last_year');
      break;
    case DropdownDateConstant.YEAR_2023:
      startDate.setMonth(0);
      startDate.setDate(1);
      startDate.setYear(2023);
      endDate.setMonth(0);
      endDate.setDate(0);
      endDate.setYear(2023);
      console.log('last_year');
      break;
    case DropdownDateConstant.LAST_30_DAYS:
        startDate.setMonth(startDate.getMonth() - 1);
        break;
    default:
        startDate = null;
        endDate = null;
      break;
  }

  return {
    startDate: startDate && DateTime.inputDate(startDate),
    endDate: endDate && DateTime.inputDate(endDate),
    name: range
  };
};

export default {
  getDateRange
};
