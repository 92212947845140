import AdminCreatePayrollReportEmployee from './AdminCreatePayrollReportEmployee.js';
import AdminPayrollCalculationInfo from './AdminPayrollCalculationInfo.js';
import AdminPayrollHoursTd from './AdminPayrollHoursTd.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import DateTime from './DateTime.js';

export default {
  name: `AdminCreatePayrollReport`,
  components: {
    AdminCreatePayrollReportEmployee,
    AdminPayrollCalculationInfo,
    AdminPayrollHoursTd,
    AdminPayrollCurrencyTd
  },
  data () {
    return {
      loading: false,
      payroll: null,
      errorMsg: null,
      overTimeMultiplier: 1.5,
      vacationMultiplier: 1,
      holidayMultiplier: 1.5,
      maxRegularHours: 80,
      payDate: DateTime.inputDate(+new Date())
    };
  },
  computed: {
    date () {
      return this.$route.params.date;
    },
    notes () {
      let notes = [];
      this.payroll.users.forEach(user => {
        if (user.attendances) {
          user.attendances.forEach(attendance => {
            notes.push(
              `[attendance]: ${user.first_name} ${user.last_name} ${attendance.attendance_type} on ${DateTime.numericDate(attendance.date)}`
            );
          });
        }
        if (user.notes) {
          user.notes.forEach(note => {
            notes.push(
              `[note]: ${user.first_name} ${user.last_name} ${note.note} on ${DateTime.numericDate(note.created_on)}`
            );
          });
        }
      });

      this.payroll.inactive_users.forEach(user => {
        notes.push(
          `${user.first_name} ${user.last_name}'s last day was on ${DateTime.numericDate(user.end_date)}`
        );
      });

      return notes;
    },
    totals () {
      const totals = {
        regular_minutes: 0,
        regular_wages: 0,
        overtime_minutes: 0,
        overtime_wages: 0,
        holiday_minutes: 0,
        holiday_wages: 0,
        vacation_minutes: 0,
        vacation_wages: 0,
        hourly_bonus: 0,
        bonus: 0,
        total_wages_calculated: 0
      };

        this.payroll.users.forEach((user) => {
          totals.regular_minutes += user.regular_minutes;
          totals.regular_wages += parseFloat(user.regular_wages);
          totals.overtime_minutes += user.overtime_minutes;
          totals.overtime_wages += parseFloat(user.overtime_wages);
          totals.holiday_minutes += user.holiday_minutes;
          totals.holiday_wages += parseFloat(user.holiday_wages);
          totals.vacation_minutes += user.vacation_minutes;
          totals.vacation_wages += parseFloat(user.vacation_wages);
          totals.hourly_bonus += parseFloat(user.hourly_bonus);
          totals.bonus += parseFloat(user.bonus);
          totals.total_wages_calculated += parseFloat(user.total_wages_calculated);
        });

        totals.total_minutes = totals.regular_minutes + totals.overtime_minutes + totals.holiday_minutes;
        const total_wages_calculated_from_total = totals.regular_wages + totals.overtime_wages + totals.holiday_wages + totals.vacation_wages + totals.hourly_bonus + totals.bonus;
        totals.total_wages_calculated_from_total = window.numeral(total_wages_calculated_from_total).format('0.00');
        totals.total_wages_calculated = window.numeral(totals.total_wages_calculated).format('0.00');
        totals.regular_wages = window.numeral(totals.regular_wages).format('0.00');
        totals.hourly_bonus = window.numeral(totals.hourly_bonus).format('0.00');

      return totals;
    }
  },
  methods: {
    handleCancel() {
      this.$router.push({
        name: 'home'
      });
    },
    handlePayDateChange (event) {
      this.payDate = event.target.value;
    },
    preparePayrollReport () {
      const payroll = {};
      payroll.pay_date = this.payDate;
      payroll.start_date = this.payroll.payroll_start_date;
      payroll.end_date = this.payroll.payroll_end_date;
      payroll.date = this.date;
      payroll.details = [];

      this.payroll.users.forEach((user) => {
        const userDetail = {
          user_id: user.id,
          hourly_rate: user.hourly_rate,
          salary: user.salary,
          regular_minutes: user.regular_minutes,
          overtime_minutes: user.overtime_minutes,
          holiday_minutes: user.holiday_minutes,
          vacation_minutes: user.vacation_minutes,
          bonus: user.bonus,
          hourly_bonus: user.hourly_bonus,
          total_wages_calculated: user.total_wages_calculated
        };
        if (user.total_wages_calculated > 0) {
          payroll.details.push(userDetail);
        }
      });

      payroll.notes = this.notes;
      return payroll;
    },
    savePayrollReport () {
      this.errorMsg = null;

      this.$http.post('/payroll/create', this.preparePayrollReport())
      .then((response) => {
        if (response.data.payroll) {
          this.$router.push({
            name: 'payrollReport',
            params: {
              reportType: 'id',
              reportId: response.data.payroll.id
            }
          });
        }
        else {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    fetchPayroll  () {
      this.$http.get('/payroll/prepare/' + this.date)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.errro);
          } else {
            const payroll = response.data.result;

            payroll.users.forEach((user) => {
              const otMinutes = parseInt(user.rounded_time_minutes) - (60 * this.maxRegularHours);
              user.regular_minutes = parseInt(user.rounded_time_minutes);
              user.regular_wages = 0;
              user.overtime_minutes = otMinutes > 0 ? otMinutes : 0;
              user.overtime_wages = 0;
              user.vacation_minutes = 0;
              user.vacation_wages = 0;
              user.holiday_minutes = 0;
              user.holiday_wages = 0;
              user.hourly_bonus = 0;
              user.bonus = 0;
              user.total_wages_calculated = 0;
            });
            this.payroll = payroll;

            if (this.payroll.payroll_end_date) {
              const date = new Date(this.payroll.payroll_end_date);
              date.setDate(date.getDate()  + 6);
              this.payDate = DateTime.inputDate(+date);
            }
          }
        });
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchPayroll();
  },
  template: `
    <div class="admin-create-payroll-report" v-if="payroll">
      <div class="component-header">
        <ul class="nav">
          <li>
            <router-link :to='{name: "home"}' class="item">Home</router-link>
          </li>
          <li>
            <router-link :to='{name: "payrollReports"}' class="item">Payroll Reports</router-link>
          </li>
          <li>
            <span class="item">Create Report</span>
          </li>
        </ul>
      </div>
      <section class="component-sub-header">
        <div class="payroll-report-meta">
          <span class="title">Start Date</span>
          <span>{{payroll.payroll_start_date | numericDate}}</span>
        </div>
        <div class="payroll-report-meta">
          <span class="title">End Date</span>
          <span>{{payroll.payroll_end_date | numericDate}}</span>
        </div>  
        <div class="payroll-report-meta">
          <span class="title">Check Date</span>
          <span>{{this.payDate | numericDate}}</span>
        </div>  
      </section>
      <div class="payroll-notes">
        <h3 class="component-title">Auto generated Notes</h3>
        <ul>
          <li v-for="note in notes">
            {{note}}
          </li>
        </ul>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>$ Hourly<br>Rate</th>
            <th>Reg + OT + Holiday<br>Hours</th>
            <th>Reg<br>Hours<br>$ Wages</th>
            <th>OT<br>Hours<br>$ Wages</th>
            <th>Holiday<br>Hours<br>$ Wages</th>
            <th>Vacation<br>Hours<br>$ Wages</th>
            <th>$ Hourly Bonus</th>
            <th>$ Additional Bonus</th>
            <th>Total<br>$ Wages</th>
          </tr>
        </thead>
        <tbody>
          <admin-create-payroll-report-employee v-for="employee in payroll.users" v-bind="{employee}" :key="employee.id">{{employee}}</admin-create-payroll-report-employee>
        </tbody>
        <tfoot>
          <tr>
            <th></th>
            <th></th>
            <th class="hours">
              {{totals.total_minutes | minutesToHours}}<br>&nbsp;
            </th>
            <th class="overtime currency">
              {{totals.regular_minutes | minutesToHours}}<br>
              <span class="symbol">$</span><span class="value">{{totals.regular_wages}}</span>
            </th>
            <th class="overtime currency">
              {{totals.overtime_minutes | minutesToHours}}<br>
              <span class="symbol">$</span><span class="value">{{totals.overtime_wages}}</span>
            </th>
            <th class="holiday currency">
              {{totals.holiday_minutes | minutesToHours}}<br>
              <span class="symbol">$</span><span class="value">{{totals.holiday_wages}}</span>
            </th>
            <th class="vacation currency">
              {{totals.vacation_minutes | minutesToHours}}<br>
              <span class="symbol">$</span><span class="value">{{totals.vacation_wages}}</span>
            </th>
            <th class="bonus currency">
              <br>
              <span class="symbol">$</span><span class="value">{{totals.hourly_bonus}}</span>
            </th>
            <th class="bonus currency">
              <br>
              <span class="symbol">$</span><span class="value">{{totals.bonus}}</span>
            </th>
            <th class="bonus currency">
              <span class="symbol">$</span><span class="value">{{totals.total_wages_calculated}}</span><br>
              <span class="symbol">$</span><span class="value">{{totals.total_wages_calculated_from_total}}</span>
            </th>
          </tr>
        </tfoot>
      </table>

      <div class="continue-buttons">
      <div class="row">
        <label class="label">
          Check Date: <input type="date" :value="payDate" v-on:change="handlePayDateChange">
        </label>
      </div>
      <div class="row error" v-if="errorMsg">{{errorMsg}}</div>
        <a href="#" v-on:click.prevent="savePayrollReport" class="button">Create & Certify Payroll Report</a>
        <a href="#" v-on:click.prevent="handleCancel" class="button button-cancel">Cancel</a>
      </div>

      <AdminPayrollCalculationInfo></AdminPayrollCalculationInfo>
      <div class="loading" v-if="loading">Loading</div>
    </div>
  `,
};