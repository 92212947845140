import DateTime from './DateTime.js';
import Loading from './Loading.js';
import TableGrid from './TableGrid.js';

const Cell = {
  props: ['value', 'classNames', 'prefix', 'postfix'],
  template: `
    <td :class="{currency: 1, zero: value == 0, [classNames]: !!classNames}">
      <span class="muted" v-if="prefix">{{prefix}}</span>
      <span class="v">{{value}}<slot></slot></span>
      <span class="muted" v-if="postfix">{{postfix}}</span>
    </td>
  `
};
export default {
  name: `AdminStrWeekly`,
  components: {
    Loading,
    TableGrid,
    Cell
  },
  data () {
    const gridColumns = [
      {key: 'start_date', value: 'Star Date', type: String},
      {key: 'end_date', value: 'End Date', type: String},
      {key: 'week_number', value: 'Week', type: Number},
      {key: 'my_occupancy_rounded', value: 'My ', type: Number},
      {key: 'comp_set_occupancy_rounded', value: 'Comp', type: Number},
      {key: 'index_occupancy_rounded', value: 'Index %', type: Number},
      {key: 'rank_occupancy', value: 'Rank', type: Number},
      {key: 'my_adr_rounded', value: 'My $', type: Number},
      {key: 'comp_set_adr_rounded', value: 'Comp $', type: Number},
      {key: 'index_adr_rounded', value: 'Index %', type: Number},
      {key: 'rank_adr', value: 'Rank', type: Number},
      {key: 'my_revpar_rounded', value: 'My $', type: Number},
      {key: 'comp_set_revpar_rounded', value: 'Comp $', type: Number},
      {key: 'index_revpar_rounded', value: 'Index %', type: Number},
      {key: 'rank_revpar', value: 'Rank', type: Number},
    ];
    return {
      gridColumns,
      strItems: [],
      loading: false,
      errorMsg: null,
    };
  },
  computed: {
    business () {
      return this.$store.state.business;
    }
  },
  watch: {
    
  },
  methods: {
    pivoteData (data) {
      if (!data) {
        return;
      }

      data.forEach(item => {
        item.week_number = DateTime.getWeekNumber(item.end_date);

        let classNames = [];
        if (item.week_number == 1) {
          classNames.push('border-bottom');
        }

        item.classNames = classNames.join(' ');
      });

      this.strItems = data;
    },
    fetchStrReport () {
      this.loading = true;
      this.errorMsg = null;

      this.$http.get('/str/weekly')
      .then((response) => {
        if (response.data.error) {
          this.errorMsg = response.data.error;
        } else {
          if (response.data.items) {
            this.pivoteData(response.data.items);
          }
          else {
            this.errorMsg = 'STR does not exist!';
          }
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    }
  },
  filters: {
    ...DateTime
  },
  mounted () {
    this.fetchStrReport();
  },
  template: `
    <div class="admin-str-monthly">
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li><router-link :to="{name: 'strWeekly'}" class="item">Str Weekly</router-link></li>
        </ul>
        <ul class="component-options">
          <li><router-link :to="{name: 'strDaily'}" class="item">Daily</router-link></li>
          <li><router-link :to="{name: 'strMonthly', params: {monthlyOrT12: 'monthly'}}" class="item">Monthly</router-link></li>
          <li><router-link :to="{name: 'strMonthly', params: {monthlyOrT12: 't12'}}" class="item">T12</router-link></li>
          <li><router-link :to="{name: 'strMonthlyRevenue'}" class="item">Monthly Revenue</router-link></li>
        </ul>
      </div>
      <loading v-bind="{loading: loading, position: 'fixed'}"></loading>
      <div class="error" v-if="errorMsg">{{errorMsg}}</div>
      <div class="component-body">
        <h3 class="component-title">STR Weekly</h3>
        <table-grid
          v-if="strItems.length"
          class="table-block"
          :data="strItems"
          defaultSortColumn="end_date"
          defaultSortOrder="-1"
          :columns="gridColumns">

          <template slot="top-thead">
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th colspan="4">Occupancy %</th>
              <th colspan="4">ADR $</th>
              <th colspan="4">RevPar $</th>
            </tr>
          </template>
          <template slot="rows" slot-scope="row">
            <td :class="row.classNames">{{row.start_date}}</td>
            <td :class="row.classNames">{{row.end_date}}</td>
            <td :class="row.classNames">{{row.week_number}}</td>
            <cell :class="[row.classNames, 'border-left']"  postfix="%" :value="row.my_occupancy_rounded"></cell>
            <cell :class="row.classNames" postfix="%" :value="row.comp_set_occupancy_rounded"></cell>
            <cell :class="row.classNames" postfix="%" :value="row.index_occupancy_rounded"></cell>
            <cell :class="row.classNames" :value="row.rank_occupancy"></cell>
            <cell :class="[row.classNames, 'border-left']" :value="row.my_adr_rounded"></cell>
            <cell :class="row.classNames" :value="row.comp_set_adr_rounded"></cell>
            <cell :class="row.classNames" postfix="%" :value="row.index_adr_rounded"></cell>
            <cell :class="row.classNames" :value="row.rank_adr"></cell>
            <cell :class="[row.classNames, 'border-left']" :value="row.my_revpar_rounded"></cell>
            <cell :class="row.classNames" :value="row.comp_set_revpar_rounded"></cell>
            <cell :class="row.classNames" postfix="%" :value="row.index_revpar_rounded"></cell>
            <cell :class="row.classNames" :value="row.rank_revpar"></cell>
          </template>
        </table-grid>

        {{strItems[0]}}
      </div>
    </div>
  `,
};