
import AdminPayrollHoursTd from './AdminPayrollHoursTd.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import DateTime from './DateTime.js';

export default {
  name: `AdminCreatePayrollReportEmployee`,
  components: {
    AdminPayrollHoursTd,
    AdminPayrollCurrencyTd
  },
  props: ['employee'],
  data () {
    return {
      loading: false,
      overTimeMultiplier: 1.5,
      vacationMultiplier: 1,
      holidayMultiplier: 1.5,
      isHourlyBonusUpdated: false,
    };
  },
  computed: {
    name () {
      return this.employee.first_name + ' ' + this.employee.last_name;
    },
    potentialBonus() {
      return this.employee.regular_minutes / 60 * 4;
    }
  },
  watch: {

  },
  methods: {
    updateTotalWages () {
      let calculatedBonusRateForHourly = this.employee.bonus_rate / 60;
      if (this.employee.attendances && this.employee.attendances.length > 0) {
        calculatedBonusRateForHourly = 0;
      }
      if (this.employee.active == 0) {
        calculatedBonusRateForHourly = 0;
      }

      const hourlyRate = this.employee.is_hourly == 1 ? parseFloat(this.employee.hourly_rate, 10) : parseFloat((parseFloat(this.employee.salary, 10) / 80).toFixed(2), 10),
        overTimeHourlyRate = parseFloat((hourlyRate * this.overTimeMultiplier).toFixed(2), 10),
        holidayHourlyRate = parseFloat((hourlyRate * this.holidayMultiplier).toFixed(2), 10),
        vacationHourlyRate = parseFloat((hourlyRate * this.vacationMultiplier).toFixed(2), 10),
        minuteRate = hourlyRate / 60,
        totalMinutesWorked = parseInt(this.employee.rounded_time_minutes || 0, 10), 
        overtimeMinutes = parseInt(this.employee.overtime_minutes || 0, 10),
        holidayMinutes = parseInt(this.employee.holiday_minutes || 0, 10),
        vacationMinutes = parseInt(this.employee.vacation_minutes || 0, 10),
        regularMinutes =  this.employee.is_hourly == 1 ? totalMinutesWorked - overtimeMinutes - holidayMinutes : 0,
        overTimeWages = overtimeMinutes * overTimeHourlyRate / 60,
        holidayWages = holidayMinutes * holidayHourlyRate / 60,
        vacationWages = vacationMinutes * vacationHourlyRate / 60,
        regularWages = this.employee.is_hourly == 1 ? regularMinutes * minuteRate : parseInt(this.employee.salary || 0, 10) - (overtimeMinutes * minuteRate) - (holidayMinutes * minuteRate),
        hourlyBonusOriginal = this.employee.is_hourly == 1 ? regularMinutes * calculatedBonusRateForHourly : parseInt(this.employee.bonus_rate || 0, 10),
        hourlyBonus = this.isHourlyBonusUpdated ? this.employee.hourly_bonus : hourlyBonusOriginal,
        bonus = parseInt(this.employee.bonus || 0, 10),
        totalWages = regularWages + overTimeWages + parseFloat(hourlyBonus, 10) + bonus + holidayWages + vacationWages;
      //  console.log(parseFloat(this.employee.salary, 10) / 80, hourlyRate, regularWages , overTimeWages , bonus , holidayWages , vacationWages)

      this.employee.calculatedHourlyRate = hourlyRate;
      this.employee.regular_minutes = regularMinutes;
      this.employee.regular_wages = window.numeral(regularWages).format('0.00') ;
      this.employee.overtime_wages = window.numeral(overTimeWages).format('0.00') ;
      this.employee.holiday_wages = window.numeral(holidayWages).format('0.00') ;
      this.employee.vacation_wages = window.numeral(vacationWages).format('0.00');
      this.employee.hourly_bonus_original = window.numeral(hourlyBonusOriginal).format('0.00');
      this.employee.hourly_bonus = window.numeral(hourlyBonus).format('0.00');
      this.employee.bonus = window.numeral(bonus).format('0.00');
      this.employee.total_wages_calculated = window.numeral(totalWages).format('0.00');
    },
    handleChangeOverTimeHours (event) {
      this.employee.overtime_minutes = this.$options.filters.humanTimeToMinutes(event.target.value);
      this.updateTotalWages();
    },
    handleChangeVacationHours (event) {
      this.employee.vacation_minutes = this.$options.filters.humanTimeToMinutes(event.target.value);
      this.updateTotalWages();
    },
    handleChangeHolidayHours (event) {
      this.employee.holiday_minutes = this.$options.filters.humanTimeToMinutes(event.target.value);
      this.updateTotalWages();
    },
    handleChangeHourlyBonus (event) {
      this.isHourlyBonusUpdated = true;
      this.employee.hourly_bonus = event.target.value > 0 ? event.target.value : 0;
      this.updateTotalWages();
    },
    handleChangeBonus (event) {
      this.employee.bonus = event.target.value > 0 ? event.target.value : 0;
      this.updateTotalWages();
    }
  },
  filters: {
    ...DateTime,
    humanDate: (dateStr) => {
      const date = new Date(dateStr);
      return date.toLocaleString('en-US', {month: 'short', year:'numeric', day:'2-digit'});
    },
    humanTime: (dateStr) => {
      if (!dateStr) {
        return '------';
      }
      const date = new Date(dateStr);
      return date.toLocaleTimeString('en-US', {hour: '2-digit', minute: 'numeric', hour12: true});
    },
    humanWeekDay: (dateStr) => {
      const date = new Date(dateStr);
      return date.toLocaleDateString('en-US', {weekday: 'short'});
    },
    minutesToHumanTime: (timeInMinutes) => {
      if (timeInMinutes) {
        return DateTime.secondsToHours(timeInMinutes * 60);
      }
      else {
        return '';
      }
    },
    humanTimeToMinutes: (timeStr) => {
      timeStr = timeStr.trim().split(':');
      const minutes = parseInt(timeStr[1] || 0, 10);
      const hours = parseInt(timeStr[0] || 0, 10);
      const totalMinutes = minutes + (hours * 60);
      return totalMinutes;
    },
    bonusFormat: (numberStr) => {
      return numberStr > 0 ? parseInt(numberStr, 10) : '';
    }
  },
  mounted () {
    this.updateTotalWages();
  },
  template: `
    <tr>
      <td class="hover-tooltip">
        {{name}} 
        <span class="attendance-count" v-if="employee.attendances">{{employee.attendances.length}}</span>
        <mark class="end-date" v-if="employee.active == 0 && employee.end_date">{{employee.end_date | numericDate}}</mark>
        <ul class="hover-content">
          <li><strong>Hire Date:</strong> {{employee.hire_date | numericDate}}</li>
          <li v-if="employee.end_date"><strong>End Date:</strong> {{employee.end_date | numericDate}}</li>
          <li><strong>Active:</strong> {{employee.active}}</li>
          <li v-for="attendance in employee.attendances">
            <strong>{{attendance.date | numericDate}}:</strong> <mark>{{attendance.attendance_type}}</mark>
          </li>
        </ul>
      </td>
      <td class="currency">
        <span class="symbol">$</span><span class="value">{{employee.calculatedHourlyRate}} <span class="muted" v-if="employee.bonus_rate">({{employee.bonus_rate}})</span></span>
      </td>
      <td class="total-hours hours">{{employee.rounded_time_minutes | minutesToHumanTime}}<br>&nbsp;</td>
      <td class="regular hours currency">
        {{employee.regular_minutes | minutesToHumanTime}}<br>
        <span class="symbol">$</span><span class="value">{{employee.regular_wages}}</span>
      </td>
      <td :class="{'overtime':1, 'hours':1, currency: 1, zero: !employee.overtime_minutes }">
        <input :value="employee.overtime_minutes | minutesToHumanTime" v-on:change="handleChangeOverTimeHours" placeholder="00:00"><br>
        <span class="symbol">$</span><span class="value">{{employee.overtime_wages}}</span>
      </td>
      <td :class="{'holiday':1, 'hours':1, currency: 1, zero: !employee.holiday_minutes }">
        <input :value="employee.holiday_minutes | minutesToHumanTime" v-on:change="handleChangeHolidayHours" placeholder="00:00"><br>
        <span class="symbol">$</span><span class="value">{{employee.holiday_wages}}</span>
      </td>
      <td :class="{'vacation':1, 'hours':1, currency: 1, zero: !employee.vacation_minutes }">
        <input :value="employee.vacation_minutes | minutesToHumanTime" v-on:change="handleChangeVacationHours" placeholder="00:00"><br>
        <span class="symbol">$</span><span class="value">{{employee.vacation_wages}}</span>
      </td>
      <td :class="{'bonus':1, 'currency':1, zero: !employee.hourly_bonus }">
        <span class="muted">{{employee.regular_minutes | minutesToHumanTime}} / $ {{employee.hourly_bonus_original}}</span>
        <br><span class="symbol">$</span><input type="number" step="0.01" :value="employee.hourly_bonus | bonusFormat" v-on:change="handleChangeHourlyBonus" placeholder="0">
      </td>
      <td :class="{'bonus':1, 'currency':1, zero: !employee.bonus }">&nbsp;
        <br><span class="symbol">$</span><input type="number" step="1" pattern="[0-9]{10}" :value="employee.bonus | bonusFormat" v-on:change="handleChangeBonus" placeholder="0">
      </td>
      <admin-payroll-currency-td v-bind="{value: employee.total_wages_calculated }"></admin-payroll-currency-td>
    </tr>
  `,
};