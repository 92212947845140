import TableGrid from './TableGrid.js';
import AdminPayrollCurrencyTd from './AdminPayrollCurrencyTd.js';
import Loading from './Loading.js';
import AdminVendorEditModal from './AdminVendorEditModal.js';

export default {
  name: `AdminVendors`,
  components: {
    Loading,
    TableGrid,
    AdminPayrollCurrencyTd,
    AdminVendorEditModal
  },
  data () {
    const gridColumns = [
      {key: '', value: ''},
      {key: 'name', value: 'Name'},
      {key: 'website', value: 'Website'},
      {key: '', value: ''}
    ];

    return {
      vendors: null,
      loading: false,
      gridColumns,
      gridData: [],
      itemsToShow: null,
      itemToEdit: null,
      errorMsg: null
    }
  },
  computed: {
  },
  watch: {
    itemsToShow () {
      this.pivotData();
    }
  },
  methods: {
    handleNew () {
      this.itemToEdit = {
        id: 'new'
      };
    },
    handleEdit (item) {
      this.itemToEdit = item;
    },
    handleEditSuccess () {
      this.itemToEdit = null;
      this.fetchVendors();
    },
    fetchVendors () {
      this.loading = true;
      this.$http.get('/vendors/')
      .then((response) => {
        if (!response.data.error) {
          this.vendors = response.data.items;
          this.pivotData();
        }
        else {
          this.errorMsg = response.data.error;
        }
      })
      .catch((error) => {
        this.errorMsg = error.toString();
      })
      .finally(() => {
        this.loading = false;
      });
    },
    pivotData () {
      const vendors = this.vendors;
      const gridData = [];

      vendors.forEach(item => {
        if (!this.itemsToShow || item.item === this.itemsToShow) {
          gridData.push(item);
        }
      });

      this.gridData = gridData;
    }
  },
  filters: {
  },
  mounted () {
    this.fetchVendors();
  },
  template: `
    <div class="admin-vendors-component component-fixed">
      <div class="component-header">
        <ul class="nav">
          <li>
            <router-link :to='{name: "home"}' class="item">Home</router-link>
          </li>
          <li>
            <span class="item">Vendors</span>
          </li>
        </ul>
        <ul class="component-options">
          <li><router-link :to='{name: "purchaseOrders"}'>Purchase Orders</router-link></li>
          <li><router-link :to='{name: "shop"}'>Shop Products</router-link></li>
          <li><a href="#" v-on:click.prevent="handleNew">New Vendor</a></li>
        </ul>
      </div>
      <loading v-bind="{loading: loading}"></loading>
      <div class="error" v-if="errorMsg">{{errorMsg}}</div>
      <admin-vendor-edit-modal v-bind="{item: itemToEdit, onUpdate: handleEditSuccess}" v-if="itemToEdit"></admin-vendor-edit-modal>
      <table-grid
        class="table-block"
        :data="gridData"
        defaultSortColumn="purchased_on"
        defaultSortOrder="-1"
        :columns="gridColumns"
        v-if="gridData.length">
          <template slot="rows" slot-scope="row">
          <td></td>
            <td>
              <router-link :to="{name: 'vendorProducts', params: {vendorId: row.id}}">
                {{row.name}}
              </router-link>
            </td>
            <td>
              <a :href="row.website" target="_blank" class="link">{{row.website}}</a>
            </td>
            <td>
              <ul class="options">
                <li><a href="#" v-on:click.prevent="handleEdit(row)" class="link">Edit</a></li>
                <li>
                  <router-link :to="{name: 'purchaseOrders', params: {field: 'vendor_id', fieldValue: row.id}}">
                    Purchase History
                  </router-link>
                </li>
              </ul> 
            </td>
          </template>
      </table-grid>
      <section class="empty-table text-center" v-if="!gridData.length && !loading">

        <a href="#" v-on:click.prevent="handleNew">New Vendor</a>
      </section>
    </div>
  `,
};