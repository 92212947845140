
import Loading from './Loading.js';
import AdminConfig from './AdminConfig.js';
import FormInput from './form/FormInput.js';
import FormSelect from './form/FormSelect.js';
import FormSelectTimezone from './form/FormSelectTimezone.js';

export default {
  name: `AdminOnBoardingPayroll`,
  components: {
    Loading,
    FormInput,
    FormSelect,
    FormSelectTimezone,
    AdminConfig
  },
  props: ['onBoarding'],
  data () {
    return {
      loading: false,
      errorMsg: null,
      configItems: ['timezone', 'payroll_days', 'payroll_start_date'],
      updatedConfig: {}
    };
  },
  computed: {
    payroll_days () {
      return this.$store.state.config.payroll_days;
    },
    timezone () {
      return this.$store.state.config.timezone;
    },
    payroll_start_date () {
      return this.$store.state.config.payroll_start_date;
    }
  },
  watch: {
    
  },
  methods: {
    handleInputUpdate (value, name) {
        this.updatedConfig.name = name;
        this.updatedConfig.value = value;
    },
    handleUpdateConfig () {
      if (typeof this.updatedConfig.name == 'undefined')  {
        this.errorMsg = 'Select Value!';
      } else {
        this.updateConfig();
      }
    },
    handleEditSuccess(success) {
      this.$toast.info(success);
            
      window.setTimeout(this.clearSuccess, 2000);
      this.configItemToEdit = null;
      this.fetchConfig();
    },
    updateConfig () {
      this.loading = true;

      this.$http.post('/config/update/', this.updatedConfig)
        .then((response) => {
          if (response.data.item) {
            this.errorMsg = null;
            this.$toast.info('Config Updated!');
            this.$store.dispatch('fetchConfig');
            this.updatedConfig = {};
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {

  },
  template: `
    <div class="admin-complete-signup-component">
      <div class="component-body onboarding-component">
        <h2>Payroll Setup</h2>
        <form @submit.prevent="handleUpdateConfig">
          <div v-if="payroll_days.values.length == 0">
            <p>
              <div>If you pay your employees every week, enter 7.</div>
              <div>If you pay your employees every other week, enter 14.</div>
            </p>
            <form-select name="payroll_days" label="Payroll Days" :options="{'7': 7, '14': 14}" :onChange="handleInputUpdate" ></form-select>
          </div>
          <div v-else-if="timezone.values.length == 0">
            <div>Enter your timezone in the format of America/New_York.</div>
            <form-select-timezone label="Timezone" name="timezone" :onChange="handleInputUpdate"></form-select-timezone>
          </div>
          <div v-else-if="payroll_start_date.values.length == 0" >
            <p>
            <div>Enter the start date of your last payroll.</div>
            <div>Typically, week starts on Sunday or Monday</div></p>
            <form-input name="payroll_start_date" label="Payroll Start Date" type="date" :onChange="handleInputUpdate" ></form-input>
          </div>
          <div class="error error-block" v-if="errorMsg">{{errorMsg}}</div>
          <div class="padding-top">
            <button type="submit" >Set</button>
          </div>
        </form>
      </div>
    </div>
  `,
};