export default {
  name: `Loading`,
  props:  ['loading', 'position'],
  template: `
  <div :class="{loading: 1, 'loading-relative': position === 'relative', 'loading-fixed': position === 'fixed'}" v-if="loading">
    <div class="loading-container">
      <span class="icon"></span>
      <div>Loading</div>
    </div>
  </div>`
}