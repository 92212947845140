import Loading from './Loading.js';
import FormInput from './form/FormInput.js';
import Modal from './Modal.js';
export default {
  name: 'AdminBusinessEditModal',
  components: {
    Loading,
    FormInput,
    Modal
  },
  props: ['onUpdate'], 
  data() {
    return {
      loading: false,
      errorMsg: null,
      success: null,
      updatedBusiness: {}
    };
  },
  computed: {
    business () {
      return this.$store.state.business;
    }
  },
  methods: {
    handleCancel () {
      this.onUpdate('Cancelled Edit!');
    },
    handleBusinessChange (value, name) {
        this.updatedBusiness[name] = value.trim();
    },
    handleUpdateBusiness () {
      if (Object.keys(this.updatedBusiness).length) {
        this.saveBusiness();
      }
    },
    clearSuccess () {
      this.success = null;
    },
    saveBusiness () {
      this.loading = true;
      this.$http.post('/business/update', this.updatedBusiness)
        .then((response) => {
          if (response.data.business) {
            this.errorMsg = null;
            this.updatedBusiness = {};
            this.$store.dispatch('setBusiness', response.data.business);
            
            this.onUpdate('Updatd Business Name!');
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchBusiness () {
      this.loading = true;
      this.$http.get('/business/')
        .then((response) => {
          if (response.data.business) {
            this.errorMsg = null;
            this.$store.dispatch('setBusiness', response.data.business);
          }
          if (response.data.error) {
            this.errorMsg = response.data.error;
          }
        })
        .catch((error) => {
          this.errorMsg = error.toString();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {
    this.fetchBusiness();
  },
  template: `
    <modal>
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li><span class="item">Business Details</span></li>
        </ul>
      </div>
      <section class="component-body">
        <loading v-bind="{loading: loading}"></loading>
        <form class="form" v-on:submit.prevent="handleUpdateBusiness" v-if="business">
          <form-input label="Name" type="text" name="name" :value="business.name" :onChange="handleBusinessChange"></form-input>
          <div class="success success-block" v-if="success">{{success}}</div>
          <div class="error error-block" v-if="errorMsg">{{errorMsg}}</div>
          <div class="row buttons">
            <button class="button">Update</button>
            <a href="#" v-on:click.prevent="handleCancel">Cancel</a>
          </div>
        </form>
      </section>
    </modal>
  `
};