import AdminEmployeesApi from '../apis/AdminEmployeesApi.js';
import AdminEmployee from './AdminEmployee.js';
import AdminEmployeeEdit from './AdminEmployeeEdit.js';
import AdminEmployeePunches from './AdminEmployeePunches.js';
import AdminEmployeeDetails from './AdminEmployeeDetails.js';
import AdminEmployeePaychecks from './AdminEmployeePaychecks.js';
import ListDetails from './ListDetails.js';
import Loading from './Loading.js';
import HeaderDropdown from './HeaderDropdown.js';

export default {
  name: `AdminEmployees`,
  mixins: [AdminEmployeesApi],
  components: {
    AdminEmployee,
    AdminEmployeeDetails,
    AdminEmployeeEdit,
    AdminEmployeePunches,
    AdminEmployeePaychecks,
    ListDetails,
    Loading,
    HeaderDropdown
  },
  data () {
    return {
      editEmployee: false,
      employeesType: 'active',
      isEmployeeTypeActive: true,
      employees: [],
      loading: false,
      headerDropdownText: 'Active Employees'
    }
  },
  computed: {
    business () {
      return this.$store.state.business;
    },
    selectedEmployeeId () {
      return this.$route.params.employeeId;
    },
    component () {
      const actionClass = {
        edit: AdminEmployeeEdit,
        punches: AdminEmployeePunches,
        paychecks: AdminEmployeePaychecks
      };

      return actionClass[this.action] || AdminEmployeeDetails;
    },
    action () {
      return this.$route.params.action;
    },
    employeeDetails () {
      if (this.selectedEmployeeId === 'new') {
        return {};
      }
      else {
        const employee = this.employees.find((employee) => {
          return employee.id == this.selectedEmployeeId;
        });
        return employee;
      } 
    }
  },
  watch: {
    action () {
      
    }
  },
  methods: {
    handleUpdateEmployees () {
      this.fetchEmployees();
    },
    toggleEmployeesType() {
      this.isEmployeeTypeActive = !this.isEmployeeTypeActive;
      this.fetchEmployees();
    },
    fetchEmployees () {
      this.loading = true;
      this
        .fetchUsers(this.isEmployeeTypeActive ? 'active' : 'inactive')
        .then((response) => {
          this.employees = response.data.in_active_users || response.data.active_users;
        })
        .catch(() => {
          
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted () {
    
  },
  created () {
    this.fetchEmployees();
  },
  template: `
    <div class="admin-employees-component" v-if="business">
      <div class="component-header">
        <ul class="nav">
          <li><router-link :to="{name: 'home'}" class="item">Home</router-link></li>
          <li><span class="item">{{isEmployeeTypeActive ? 'Active' : 'In-Active'}} Employees</span></li>
        </ul>
        <ul class="component-options">
          <li><a href="#" v-on:click.prevent="toggleEmployeesType">Show {{!isEmployeeTypeActive ? 'Active' : 'In-Active'}} Employees</a></li>
        </ul>
      </div>
      <list-details :class="{'pane-open':employeeDetails}">
        <template slot="list">
          <div class="employees-list-container">
            <div class="component-header">
              <ul class="component-options">
                <li>
                  <router-link
                    :to="{name: 'employees', params: {employeeId: 'new', action: 'edit'}}" class="new">
                    + New
                  </router-link>
                </li>
              </ul>
            </div>
            <loading v-bind="{loading: loading}"></loading>
            <ul class="employees-list list">
              <admin-employee v-for="employee in employees" :key="employee.id">
                <router-link
                  :to="{name: 'employees', params: {employeeId: employee.id}}">
                  <span class="first-name">{{employee.first_name}}</span>
                  <span class="last-name">{{employee.last_name}}</span>
                  <span class="position">{{employee.position}}</span>
                </router-link>
                <div class="more">
                  <router-link
                    :to="{name: 'employees', params: {employeeId: employee.id, action: 'edit'}}" class="edit-employee">
                    Edit
                  </router-link>
                  <router-link
                    :to="{name: 'employees', params: {employeeId: employee.id, action: 'punches'}}" class="employee-punches">
                    Punches
                  </router-link>
                </div>
              </admin-employee>
            </ul>
          </div>
        </template>
        <template slot="details" v-if="employeeDetails || selectedEmployeeId">
          <component v-bind:is="component" v-bind="{employeeDetails, employeeId: selectedEmployeeId}" @onUpdateEmployees="handleUpdateEmployees"></component>
        </template>
        <template slot="details" v-else>
          <span class="none-selected">Select Employee</span>
        </template>
      </list-details>
    </div>
  `,
};